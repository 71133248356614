import {
  assemblyLogo,
  closeIcon,
  closeIconCircle,
  filterIcon,
  filterSelectedIcon,
  filterTemplateIcon,
  leftArrowIcon,
  templatesIcon,
} from '@assembly-web/assets';
import {
  config,
  getFeatureFlagTreatmentAsync,
  getUserDetailsQuery,
  logger,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import { Button, TextField, useAssemblyNavigate } from '@assembly-web/ui';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import type { ChangeEvent, FormEvent, UIEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { HeaderBar } from '../../../components/HeaderBar';
import { TextButton } from '../../../components/TextButton';
import { trackTemplatesAction } from '../../../services/analytics';
import { useCreateWorkflow } from '../../discover/hooks/flowsEditor/useCreateWorkflow';
import { useLegacyPathStore } from '../../discover/stores/legacyPathStore';
import { FilterAccordion } from '../components/FilterAccordion';
import { QuickFilters } from '../components/QuickFilters';
import { TemplateCard } from '../components/TemplateCard';
import { TemplateCardLoad } from '../components/TemplateCardLoad';
import { TemplateList } from '../components/TemplateList';
import { TemplatesEmptyView } from '../components/TemplatesEmptyView';
import { TemplatesErrorView } from '../components/TemplatesErrorView';
import { getCategoriesQuery } from '../queries/getCategoriesQuery';
import { getTemplateFiltersQuery } from '../queries/getTemplateFiltersQuery';
import { useGetTemplatesQuery } from '../queries/getTemplatesQuery';
import { createQueryParam } from '../services/filter';
import type {
  categoryLists,
  EventMessage,
  OnFilterClickProps,
  Template,
} from '../types';
import { getEventSource, getParsedFilterOptions } from './utils';

const templatesText = defineMessages({
  pageTitle: {
    defaultMessage: 'Choose your use case',
    id: 'dLxnyl',
  },
  pageTitleForShopTemplate: {
    defaultMessage: 'What workflow do you want to supercharge with Assembly?',
    id: 'dJJDtF',
  },
  clearAll: {
    defaultMessage: 'Clear all',
    id: 'QW+Q5N',
  },
  filterTemplates: {
    defaultMessage: 'Filter Template',
    id: 'O5dbTK',
  },
  startFromScratch: {
    defaultMessage: '+ Start from scratch',
    id: 'KduLMo',
  },
  result: {
    defaultMessage: 'Result',
    id: 'ZpQ6us',
  },
  categoriesErrorText: {
    defaultMessage:
      'Trouble loading filters, please refresh the page to try again.',
    id: 'HfHbP2',
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'TJo5E6',
  },
  tryTemplate: {
    defaultMessage: 'Try template',
    id: '7xZKQk',
  },
  tryThisFlow: {
    defaultMessage: 'Try this flow',
    id: 'wV6aIH',
  },
  flowTemplatesTitle: {
    defaultMessage: 'Assembly Flow Templates',
    id: 'D6RJfk',
  },
  searchInputLabel: {
    defaultMessage: 'search template',
    id: 'KbdZ2G',
  },
  searchInputPlaceholder: {
    defaultMessage: 'Search templates...',
    id: '2BM55e',
  },
  categoryFilter: {
    defaultMessage: 'Category',
    id: 'ccXLVi',
  },
  jobTypeFilter: {
    defaultMessage: 'Job Type',
    id: '5i95fi',
  },
  useCaseFilter: {
    defaultMessage: 'Use Case',
    id: 'eJKtsa',
  },
  workflowTypeFilter: {
    defaultMessage: 'Workflow Type',
    id: 'RQfEKW',
  },
  flowTemplatesLabel: {
    defaultMessage: 'Flow Templates',
    id: 'oKj7i7',
  },
  flowTemplatesDescription: {
    defaultMessage:
      'Browse our templates and create workflows to increase productivity and simplify your workday',
    id: 'I+fr1m',
  },
});

export function templatesRouteLoader(queryClient: QueryClient) {
  return async function loader() {
    const { assembly } = await queryClient.fetchQuery(getUserDetailsQuery());
    const templateLibraryTreatment = await getFeatureFlagTreatmentAsync(
      SplitNames.TemplateLibrary,
      assembly.assemblyId
    );
    if (templateLibraryTreatment === 'on') {
      const categoriesQuery = getCategoriesQuery();
      queryClient.prefetchQuery(categoriesQuery);
      return null;
    }
    const templatesFilterQuery = getTemplateFiltersQuery();
    queryClient.prefetchQuery(templatesFilterQuery);
    return null;
  };
}

export function TemplatesRoute() {
  const { isTreatmentActive: isTemplateLibraryEnabled } = useFeatureSplit(
    SplitNames.TemplateLibrary
  );
  if (isTemplateLibraryEnabled) {
    return <TemplatesRouteV2 />;
  }
  return <TemplatesRouteV1 />;
}
export function TemplatesRouteV2() {
  return <TemplateList />;
}

export function TemplatesRouteV1() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const { search: searchParam, pathname } = useLocation();
  const navigate = useAssemblyNavigate();
  const { previousUrls } = useLegacyPathStore();

  const {
    filter,
    isEmbeddedInApp,
    hasFilterApplied,
    isEmbeddedPublicly,
    isActionFilterApplied,
    isUseCaseFilterApplied,
    isJobTypesFilterApplied,
    receivedSelectedFilters,
    isCategoriesFilterApplied,
  } = getParsedFilterOptions({
    searchParam,
  });

  const [searchValue, setSearchValue] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    filter.categories ?? []
  );
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>(
    filter.jobTypes ?? []
  );
  const [selectedUseCases, setSelectedUseCases] = useState<string[]>(
    filter.useCases ?? []
  );
  const [selectedActions, setSelectedActions] = useState<string[]>(
    filter.actions ?? []
  );

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    receivedSelectedFilters
  );

  const {
    data,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isError: isTemplateDataError,
    isPending: isTemplateDataLoading,
  } = useGetTemplatesQuery({
    filter,
    keyword: searchValue,
  });

  const { data: categoriesData, isError: isCategoriesDataHasError } = useQuery(
    getTemplateFiltersQuery()
  );

  const { data: userDetails } = useUserDetails();
  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;

  const assemblyId = userDetails?.assembly.assemblyId ?? 'anonymous';

  const createFlowEditorDrawer = useCreateWorkflow();
  const { isTreatmentActive: isFlowCreationDrawerEnabled } = useFeatureSplit(
    SplitNames.FlowCreation
  );

  const {
    action = [],
    jobType = [],
    useCase = [],
    category = [],
  } = categoriesData ?? {};

  const [allFilters, setAllFilters] = useState<categoryLists[]>([]);

  useEffect(() => {
    if (isEmbeddedPublicly) {
      return;
    }
  }, [pathname, searchParam, formatMessage, isEmbeddedPublicly]);

  useEffect(() => {
    setAllFilters([
      ...action.map((item) => ({ ...item, mainCategory: 'action' })),
      ...category.map((item) => ({ ...item, mainCategory: 'category' })),
      ...jobType.map((item) => ({ ...item, mainCategory: 'jobType' })),
      ...useCase.map((item) => ({ ...item, mainCategory: 'useCase' })),
    ]);
  }, [action, category, jobType, useCase]);

  const templateData: Template[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.reduce<Template[]>(
      (acc, page) => [...acc, ...page.data.data],
      []
    );
  }, [data]);

  const handleOnChange = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      trackTemplatesAction('search', {
        searchQuery: e.target.value,
        filterApplied: allFilters
          .filter((item) => selectedFilters.includes(item.key))
          .map((filter) => filter.name)
          .join(','),
        source: getEventSource({
          isEmbeddedPublicly,
        }),
      });
      setSearchValue(e.target.value);
    }, 500);
  }, [allFilters, isEmbeddedPublicly, selectedFilters]);

  const formRef = useRef<HTMLFormElement>(null);
  const handleClearClick = useCallback(() => {
    setSearchValue('');
    formRef.current?.reset();
  }, []);

  const handleOnFilterClick = useCallback(
    (filterItem: OnFilterClickProps) => {
      const { filterName, id, value } = filterItem;
      if (filterName === 'category') {
        if (selectedCategories.includes(id) && !value) {
          setSelectedCategories(
            selectedCategories.filter((item) => item !== id)
          );
          setSelectedFilters(selectedFilters.filter((item) => item !== id));
        } else {
          setSelectedCategories((prev) => [...prev, id]);
          setSelectedFilters((prev) => [...prev, id]);
        }
      } else if (filterName === 'jobType') {
        if (selectedJobTypes.includes(id) && !value) {
          setSelectedJobTypes(selectedJobTypes.filter((item) => item !== id));
          setSelectedFilters(selectedFilters.filter((item) => item !== id));
        } else {
          setSelectedJobTypes((prev) => [...prev, id]);
          setSelectedFilters((prev) => [...prev, id]);
        }
      } else if (filterName === 'useCase') {
        if (selectedUseCases.includes(id) && !value) {
          setSelectedUseCases(selectedUseCases.filter((item) => item !== id));
          setSelectedFilters(selectedFilters.filter((item) => item !== id));
        } else {
          setSelectedUseCases((prev) => [...prev, id]);
          setSelectedFilters((prev) => [...prev, id]);
        }
      } else if (filterName === 'action') {
        if (selectedActions.includes(id) && !value) {
          setSelectedActions(selectedActions.filter((item) => item !== id));
          setSelectedFilters(selectedFilters.filter((item) => item !== id));
        } else {
          setSelectedActions((prev) => [...prev, id]);
          setSelectedFilters((prev) => [...prev, id]);
        }
      }

      trackTemplatesAction('applyFilter', {
        searchQuery: searchValue,
        filterApplied: `${allFilters
          .filter(
            (item) =>
              selectedFilters.includes(item.key || id) || item.key === id
          )
          .map((filter) => filter.name)
          .join(',')}`,
        source: getEventSource({
          isEmbeddedPublicly,
        }),
      });
    },
    [
      searchValue,
      allFilters,
      selectedCategories,
      selectedFilters,
      selectedJobTypes,
      selectedUseCases,
      selectedActions,
      isEmbeddedPublicly,
    ]
  );

  const handleClearAllFilter = () => {
    setSelectedCategories([]);
    setSelectedJobTypes([]);
    setSelectedUseCases([]);
    setSelectedActions([]);
    setSelectedFilters([]);
  };

  const handleFilterIconClick = () => setShowFilter(true);

  const handleFilterBackClick = () => setShowFilter(false);

  const handleStartFromScratch = useCallback(() => {
    trackTemplatesAction('startFromScratch', {
      searchQuery: searchValue,
      filterApplied: allFilters
        .filter((item) => selectedFilters.includes(item.key))
        .map((filter) => filter.name)
        .join(','),
      source: getEventSource({
        isEmbeddedPublicly,
      }),
    });
    createFlowEditorDrawer({
      view: 'editor',
      type: 'scratch',
      overrideTreatmentOffCallback() {
        if (isEmbeddedPublicly) {
          const url = `${config.domains.legacyApp}/flows/editor`;
          window.parent.location.replace(url);
        } else if (isEmbeddedInApp) {
          window.parent.postMessage(
            JSON.stringify({
              targetEvent: 'templates',
              action: 'startFromScratchClicked',
            }),
            '*'
          );
        } else if (assemblyId === 'anonymous') {
          navigate('/login');
        } else {
          navigate(`/${workspaceSlugPath}/flows/editor`);
        }
      },
    });
    if (isFlowCreationDrawerEnabled) {
      navigate('/a/discover');
    }
  }, [
    searchValue,
    allFilters,
    isEmbeddedPublicly,
    isEmbeddedInApp,
    assemblyId,
    selectedFilters,
    navigate,
    workspaceSlugPath,
    isFlowCreationDrawerEnabled,
    createFlowEditorDrawer,
  ]);

  const postTemplateMessage = (template: Template) => {
    const filterValue = allFilters.filter(
      (item) => item.key === template.category
    );
    const message = JSON.stringify({
      targetEvent: 'templates',
      action: 'templateSelected',
      payload: {
        templateId: template.templateId,
        color: filterValue[0]?.color,
        emoticon: template.icon.value,
        title: template.title,
        filterApplied: allFilters
          .filter((item) => selectedFilters.includes(item.key))
          .map((filter) => filter.name)
          .join(','),
        searchQuery: searchValue,
      },
    });
    window.parent.postMessage(message, '*');
  };

  const templateCardButtonClickHandler = (template: Template) => {
    if (isEmbeddedPublicly) {
      const url = `${config.domains.legacyApp}/create-one-click-flow/${template.templateId}`;
      window.parent.location.replace(url);
    } else if (isEmbeddedInApp) {
      postTemplateMessage(template);
    } else if (assemblyId === 'anonymous') {
      window.location.href = `${config.domains.legacyApp}/template/${template.templateId}`;
    } else {
      navigate(
        `/${workspaceSlugPath}/template/${template.templateId}?from=templateGallery`
      );
    }
  };

  const handleCloseModalClick = () => {
    if (isEmbeddedInApp) {
      window.parent.postMessage(
        JSON.stringify({
          targetEvent: 'templates',
          action: 'closeTemplateGalleryClicked',
        }),
        '*'
      );
    } else {
      navigate(-1);
    }
  };

  const navigateToTemplates = useCallback(
    (url: string) => {
      return navigate(url, { replace: true });
    },
    [navigate]
  );

  const objRequiredForQueryParams = useMemo(() => {
    return {
      isEmbeddedPublicly: isEmbeddedPublicly ? 'publicView' : undefined,
      isEmbedded: isEmbeddedInApp ? 'true' : undefined,
      categories: selectedCategories.join(','),
      jobTypes: selectedJobTypes.join(','),
      useCases: selectedUseCases.join(','),
      actions: selectedActions.join(','),
      selectedFilters: selectedFilters.join(','),
    };
  }, [
    isEmbeddedPublicly,
    isEmbeddedInApp,
    selectedCategories,
    selectedJobTypes,
    selectedUseCases,
    selectedActions,
    selectedFilters,
  ]);

  const navigateUrl = useMemo(() => {
    return createQueryParam(objRequiredForQueryParams, 'a/templates?=');
  }, [objRequiredForQueryParams]);

  /* To apply the filters to the route */
  useEffect(() => {
    navigateToTemplates(navigateUrl);
  }, [navigateToTemplates, objRequiredForQueryParams, navigateUrl]);

  /* To send the event to the new app */
  useEffect(() => {
    if (!isTemplateDataLoading) {
      window.parent.postMessage(
        JSON.stringify({
          targetEvent: 'templates',
          action: 'templateGalleryLoaded',
        }),
        '*'
      );
      if (isTemplateDataError) {
        logger.error('Templates are not getting loaded', {}, error as Error);
      }
    }
  }, [isTemplateDataLoading, error, isTemplateDataError]);

  const [scrollTop, setScrollTop] = useState(0);
  const handleScroll = (e: UIEvent<HTMLElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll, true);
    };
  }, []);

  const showResultText =
    (hasFilterApplied && templateData.length) ||
    (templateData.length > 0 &&
      !isTemplateDataLoading &&
      searchValue.length > 0);

  const hideQuickFilters = useMemo(() => {
    return hasFilterApplied || isTemplateDataLoading || searchValue.length > 0;
  }, [hasFilterApplied, isTemplateDataLoading, searchValue.length]);

  /* to read message from the main app */
  useEffect(() => {
    const handleReceivedEventMessage = (message: EventMessage) => {
      try {
        if (message.origin === `${config.domains.legacyApp}`) {
          handleClearAllFilter();
          setSearchValue('');
          formRef.current?.reset();
        }
      } catch (e) {
        logger.error(
          'Error while reading message from the main app',
          {},
          e as Error
        );
      }
    };
    window.addEventListener('message', handleReceivedEventMessage);
    return () => {
      window.removeEventListener('message', handleReceivedEventMessage);
    };
  }, []);

  const showHeader = !isEmbeddedInApp && !isEmbeddedPublicly;

  return (
    <section className="overflow-hidden bg-gray-1 md:h-screen">
      {Boolean(showHeader) && (
        <HeaderBar
          showCompanyName
          logoSrc={assemblyLogo}
          companyName={formatMessage(templatesText.flowTemplatesTitle)}
        >
          <HeaderBar.RightContent>
            <TextButton
              className="ml-auto"
              onClick={() => {
                if (previousUrls[previousUrls.length - 1]) {
                  navigate(previousUrls[previousUrls.length - 1]);
                } else {
                  navigate('/discover');
                }
              }}
            >
              <XMarkIcon className="h-6 w-6 text-gray-8" aria-hidden="true" />
            </TextButton>
          </HeaderBar.RightContent>
        </HeaderBar>
      )}
      <div
        className={twMerge(
          'mx-auto h-full max-w-[1500px]',
          showHeader && 'h-[calc(100%-72px)]'
        )}
      >
        <div className="sticky top-0 flex h-full items-center border-b border-gray-5 bg-gray-1 p-4 md:hidden">
          <div className="h-10 w-10 md:flex-1">
            <Button variation="secondaryLite" onClick={handleCloseModalClick}>
              <img src={leftArrowIcon} alt="" />
            </Button>
          </div>
          <div className="flex flex-[12] items-center">
            <img src={templatesIcon} alt="" className="mr-2" />
            <div>{formatMessage(templatesText.pageTitle)}</div>
          </div>
          <div className="md:flex-1">
            <Button variation="secondaryLite" onClick={handleFilterIconClick}>
              {!hasFilterApplied && <img src={filterIcon} alt="" />}
              {Boolean(hasFilterApplied) && (
                <>
                  <img src={filterSelectedIcon} alt="" className="mr-2" />
                  <span className="absolute right-2.5 top-1.5 h-2 w-2 rounded-[100%] bg-primary-6" />
                </>
              )}
            </Button>
          </div>
        </div>
        <div
          className={twMerge(
            `flex h-[calc(100vh-73px)] pb-1`,
            showHeader ? 'h-[calc(100vh-145px)]' : 'h-[calc(100vh-73px)]'
          )}
        >
          <div className="h-full flex-1 rounded-lg">
            <div
              className={twMerge(
                `sticky top-[73px] z-[1] bg-gray-1 p-4 px-6 transition-all md:top-0 lg:px-12`,
                scrollTop > 0 && 'shadow-md-down'
              )}
            >
              <form
                ref={formRef}
                noValidate
                onSubmit={(event: FormEvent<HTMLFormElement>) =>
                  event.preventDefault()
                }
              >
                <TextField
                  name="search"
                  type="text"
                  label={formatMessage(templatesText.searchInputLabel)}
                  hideLabel
                  placeholder={formatMessage(
                    templatesText.searchInputPlaceholder
                  )}
                  autoComplete="text"
                  spellCheck={false}
                  invalidTextTestId="email-error"
                  isInvalid={false}
                  invalidText=""
                  connectedLeft={
                    <MagnifyingGlassIcon className="pointer-events-none h-4 w-4 text-gray-5" />
                  }
                  defaultValue={searchValue}
                  required
                  className="w-full rounded-md border-gray-5 py-2 pl-8"
                  onChange={handleOnChange}
                  formNoValidate
                  connectedRight={
                    searchValue.length > 0 ? (
                      <button
                        type="button"
                        className="z-[1] flex h-full items-center justify-center p-2"
                        onClick={handleClearClick}
                        data-testid="clearSearch"
                      >
                        <img src={closeIcon} alt="" />
                      </button>
                    ) : null
                  }
                />
              </form>
            </div>
            {Boolean(isTemplateDataError) && (
              <TemplatesErrorView onButtonClick={handleStartFromScratch} />
            )}
            {!isTemplateDataError && (
              <div
                className="h-[calc(100%-73px)] w-full overflow-y-auto md:pb-4"
                onScroll={handleScroll}
                ref={scrollRef}
              >
                {!hideQuickFilters && (
                  <QuickFilters onClick={handleOnFilterClick} />
                )}

                <div className="align flex flex-col items-start px-6 lg:px-12">
                  <div className={hasFilterApplied ? 'pb-3 pt-2' : 'hidden'}>
                    {allFilters
                      .filter((item) => selectedFilters.includes(item.key))
                      .reverse()
                      .map((filter) => (
                        <div
                          className="mb-1 mr-3 inline-flex rounded-[100px] bg-primary-1 px-2 py-1 text-sm font-medium !text-primary-6 hover:!bg-primary-1"
                          key={filter.key}
                          data-testid={filter.name}
                        >
                          {filter.name}
                          <button
                            onClick={() =>
                              handleOnFilterClick({
                                filterName: filter.mainCategory ?? '',
                                id: filter.key,
                                value: false,
                              })
                            }
                            className="pl-2"
                          >
                            <img
                              src={closeIconCircle}
                              alt=""
                              data-testid="clearSelectedFilter"
                            />
                          </button>
                        </div>
                      ))}
                    <TextButton
                      className="text-sm !text-gray-8"
                      onClick={handleClearAllFilter}
                      data-testid="clearAll"
                      hidden={!hasFilterApplied}
                      underline
                    >
                      {formatMessage(templatesText.clearAll)}
                    </TextButton>
                  </div>

                  {templateData.length === 0 && !isTemplateDataLoading && (
                    <TemplatesEmptyView
                      onButtonClick={handleStartFromScratch}
                    />
                  )}
                  {Boolean(showResultText) && (
                    <div>{formatMessage(templatesText.result)}</div>
                  )}
                  {Boolean(isTemplateDataLoading) && (
                    <div className="grid grid-cols-1 gap-3 pt-4 md:grid-cols-2 lg:grid-cols-3">
                      <TemplateCardLoad />
                    </div>
                  )}
                </div>

                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => {
                    if (!isTemplateDataLoading && !isFetchingNextPage) {
                      fetchNextPage();
                    }
                  }}
                  hasMore={hasNextPage}
                  loader={<TemplateCardLoad />}
                  useWindow={false}
                  className="grid grid-cols-1 gap-4 px-6 pt-4 md:grid-cols-2 lg:grid-cols-3 lg:px-12"
                  getScrollParent={() => scrollRef.current}
                  key={0}
                  data-testid="template-grid"
                >
                  {templateData.map((template) => (
                    <TemplateCard
                      key={template.templateId}
                      title={template.title}
                      creator={template.creator}
                      description={template.description}
                      templateId={template.templateId}
                      icon={template.icon}
                      onClick={() => templateCardButtonClickHandler(template)}
                      buttonText={
                        isEmbeddedPublicly
                          ? formatMessage(templatesText.tryTemplate)
                          : formatMessage(templatesText.preview)
                      }
                    />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
          <div
            className={twMerge(
              'z-[2] h-full w-72 overflow-auto border-l border-gray-5 bg-gray-1 px-0 md:block md:px-4',
              showFilter
                ? 'fixed left-0 top-0 block h-screen !w-full overflow-scroll'
                : 'hidden bg-gray-1'
            )}
          >
            <div className="sticky top-0 z-[1] flex items-center border-b border-gray-5 bg-gray-1 px-4 py-4 md:hidden">
              <div className="h-10 w-10 md:flex-1">
                <Button
                  variation="secondaryLite"
                  onClick={handleFilterBackClick}
                >
                  <img src={leftArrowIcon} alt="" />
                </Button>
              </div>
              <div className="flex flex-[12] items-center">
                {<img src={filterTemplateIcon} alt="" className="mr-2" />}
                <div>{formatMessage(templatesText.filterTemplates)}</div>
              </div>
              <div className="flex-[3] text-right">
                <TextButton
                  className="text-sm !text-gray-8"
                  onClick={handleClearAllFilter}
                  hidden={!hasFilterApplied}
                >
                  {formatMessage(templatesText.clearAll)}
                </TextButton>
              </div>
            </div>
            <div className="sticky top-0 z-[1] block bg-gray-1 md:pt-6">
              <Button
                variation="secondaryEmphasized"
                isFullWidth
                onClick={handleStartFromScratch}
              >
                {formatMessage(templatesText.startFromScratch)}
              </Button>
              <div className="mt-4 hidden md:flex md:items-center md:justify-between">
                <div className="text-sm text-gray-8">
                  {formatMessage(templatesText.filterTemplates)}
                </div>
                <TextButton
                  className="text-sm !text-gray-8"
                  onClick={handleClearAllFilter}
                  data-testid="clearAllRightDrawer"
                  hidden={!hasFilterApplied}
                >
                  {formatMessage(templatesText.clearAll)}
                </TextButton>
              </div>
            </div>
            {Boolean(isCategoriesDataHasError) && (
              <div className="pt-4 text-sm text-gray-8">
                {formatMessage(templatesText.categoriesErrorText)}
              </div>
            )}
            {Boolean(categoriesData) && (
              <div className="px-7 md:px-2">
                <FilterAccordion
                  categoryName={formatMessage(templatesText.categoryFilter)}
                  filterName="category"
                  categoryLists={category}
                  onFilterClick={handleOnFilterClick}
                  selectedFilters={selectedCategories}
                  isFilterApplied={isCategoriesFilterApplied}
                />
                <hr className="h-[1px] rounded border-0 bg-gray-3 dark:bg-gray-9" />
                <FilterAccordion
                  categoryName={formatMessage(templatesText.jobTypeFilter)}
                  filterName="jobType"
                  categoryLists={jobType}
                  onFilterClick={handleOnFilterClick}
                  selectedFilters={selectedJobTypes}
                  isFilterApplied={isJobTypesFilterApplied}
                />
                <hr className="h-[1px] rounded border-0 bg-gray-3 dark:bg-gray-9" />
                <FilterAccordion
                  categoryName={formatMessage(templatesText.useCaseFilter)}
                  filterName="useCase"
                  categoryLists={useCase}
                  onFilterClick={handleOnFilterClick}
                  selectedFilters={selectedUseCases}
                  isFilterApplied={isUseCaseFilterApplied}
                />
                <hr className="h-[1px] rounded border-0 bg-gray-3 dark:bg-gray-9" />
                <FilterAccordion
                  categoryName={formatMessage(templatesText.workflowTypeFilter)}
                  filterName="action"
                  categoryLists={action}
                  onFilterClick={handleOnFilterClick}
                  selectedFilters={selectedActions}
                  isFilterApplied={isActionFilterApplied}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
