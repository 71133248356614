import { APIEndpoints, type RedeemInfoResponse } from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries } from '@tanstack/react-query';

function getRedeemRewardsDetailsQuery(
  options?: UseQueryOptions<RedeemInfoResponse>
): UseQueryOptions<RedeemInfoResponse> {
  return {
    ...options,
    queryKey: ['redeemRewardDetails'],
    queryFn: async () => {
      const response = await assemblyAPI.get<RedeemInfoResponse>(
        APIEndpoints.redeem
      );
      return response.data;
    },
  };
}

export function useRedeemRewardsDetails() {
  return useQueries({
    queries: [{ ...getRedeemRewardsDetailsQuery() }],
  });
}
