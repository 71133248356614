import { getUserDetailsQuery } from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import { defer, type LoaderFunctionArgs } from 'react-router-dom';

import { getAnnouncementsQuery } from '../queries/getAnnouncementsQuery';
import { getPlanFeaturesQuery } from '../queries/getPlanFeaturesQuery';

export function discoverPageLoader(queryClient: QueryClient) {
  return async function loader({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);

    const isDiscoverHomePage =
      url.pathname === '/a/discover' && !url.searchParams.has('filter');

    return defer({
      userDetailsQueryResponse: queryClient.fetchQuery(getUserDetailsQuery()),
      planFeaturesQueryResponse: queryClient.fetchQuery(getPlanFeaturesQuery()),
      ...(isDiscoverHomePage && {
        announcementsQueryResponse: queryClient.fetchInfiniteQuery(
          getAnnouncementsQuery()
        ),
      }),
    });
  };
}
