import type {
  DeadlineType,
  DeadlineUnitOption,
  FlowResponseType,
  RemindersType,
  ResponseFrequencyType,
} from '@assembly-web/services';

import type { FrequencyOption } from '../hooks/useGetFlowFrequencyOptions';
import type { MilestoneOption } from '../hooks/useGetMilestonesOptions';
import type { TimeOption } from '../hooks/useGetTimeOptions';

export function isResponseFrequencyType(
  value: string
): value is ResponseFrequencyType {
  return value === 'recurring' || value === 'once';
}

export function isDeadlineType(value: string): value is DeadlineType {
  return value === 'schedule' || value === 'manual';
}

export function isRemindersType(value: string): value is RemindersType {
  return value === 'automate' || value === 'manual';
}

export function isFlowResponseType(value: string): value is FlowResponseType {
  return value === 'anytime' || value === 'deadline' || value === 'milestone';
}

export function isFrequencyOption(option: unknown): option is FrequencyOption {
  return (
    typeof option === 'object' &&
    option !== null &&
    'id' in option &&
    'label' in option
  );
}

export function isTimeOption(option: unknown): option is TimeOption {
  return (
    typeof option === 'object' &&
    option !== null &&
    'id' in option &&
    'label' in option
  );
}

export function isMilestoneOption(option: unknown): option is MilestoneOption {
  return (
    typeof option === 'object' &&
    option !== null &&
    'id' in option &&
    'label' in option
  );
}

export function isDeadlineUnitOption(
  option: unknown
): option is DeadlineUnitOption {
  return (
    typeof option === 'object' &&
    option !== null &&
    'id' in option &&
    'label' in option &&
    ['minutes', 'hours', 'days', 'weeks'].includes(
      (option as DeadlineUnitOption).id
    )
  );
}
