import type { Collection, ItemToAdd } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import {
  Button,
  Checkbox,
  EmojiColorPicker,
  LoadingSpinner,
  Modal,
  TextField,
  TextStyle,
} from '@assembly-web/ui';
import type { ChangeEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useCreateCollectionModal } from '../../hooks/useCreateCollectionModal';
import { useIsNewUX } from '../../hooks/useIsNewUX';
import type { SearchPayload } from '../../hooks/useSearchIndex';

const folderMessages = defineMessages({
  createHeader: {
    defaultMessage: 'Create a Folder',
    id: 'yFvSRv',
  },
  editHeader: {
    defaultMessage: 'Edit a Folder',
    id: 'PRa/vS',
  },
  description: {
    defaultMessage:
      'Add flows, files, and links to a folder for easy navigation',
    id: 'E9jhch',
  },
  emojiSubheader: {
    defaultMessage: 'Choose a folder color',
    id: 'cGQegn',
  },
  invalidTitle: {
    defaultMessage:
      'Folder name is required and should be less than 30 characters',
    id: '0WVvUS',
  },
  folderNameLabel: {
    defaultMessage: 'Folder name*',
    id: 'lR/7o8',
  },
} as const);

const messages = defineMessages({
  createHeader: {
    defaultMessage: 'Create a Collection',
    id: 'CHas+4',
  },
  editHeader: {
    defaultMessage: 'Edit a Collection',
    id: 'RgbgV3',
  },
  content: {
    defaultMessage:
      'Bundle and share links, files, and other relevant material from all across the web.',
    id: 'IB/YLt',
  },
  emojiSubheader: {
    defaultMessage: 'Choose emoji and color',
    id: '4ZaCXI',
  },
  invalidTitle: {
    defaultMessage:
      'Collection name is required and should be less than 30 characters',
    id: '64rc4V',
  },
  nameLabel: {
    defaultMessage: 'Collection name',
    id: 'A6ozr9',
  },
  namePlaceholder: {
    defaultMessage: 'Enter a name',
    id: 'uqBy7y',
  },
  descriptionLabel: {
    defaultMessage: 'Description',
    id: 'Q8Qw5B',
  },
  descriptionPlaceholder: {
    defaultMessage: 'Enter a short description',
    id: 'xAhiBn',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  save: {
    defaultMessage: 'Save',
    id: 'jvo0vs',
  },
  saving: {
    defaultMessage: 'Saving',
    id: 'QLkzoS',
  },
  pinning: {
    defaultMessage: 'Pinning',
    id: 'J89KxL',
  },
  pinThisCollection: {
    defaultMessage: 'Pin this collection for easy navigation',
    id: 'v+SfqN',
  },
} as const);

type CreateCollectionModalProps = {
  filter: GlobalFilterOption;
  open: boolean;
  onClose: () => void;
  collection: Collection | null;
  isPinnedDefault: boolean;
  itemToAdd: ItemToAdd | null;
  numCollections?: number;
  query?: SearchPayload;
};

const inputClassName = 'mt-1 placeholder:text-gray-8 text-sm';
const labelClassName = 'text-sm font-medium';

export function CreateCollectionModal(props: CreateCollectionModalProps) {
  const {
    collection,
    filter,
    isPinnedDefault,
    itemToAdd,
    numCollections,
    open,
    onClose,
    query,
  } = props;
  const { formatMessage } = useIntl();
  const {
    colorName,
    emoji,
    description,
    isPinned,
    handleClose,
    handleColorChange,
    handleDescriptionChange,
    handleEmojiChange,
    handleIsPinnedChange,
    handleSubmit,
    handleTitleChange,
    isSubmitting,
    isTitleValid,
    title,
  } = useCreateCollectionModal({
    filter,
    onClose,
    collection,
    itemToAdd,
    numCollections,
    query,
    isPinnedDefault,
  });

  const isNewUX = useIsNewUX();

  return (
    <Modal
      title={formatMessage(
        collection?.collectionId
          ? isNewUX
            ? folderMessages.editHeader
            : messages.editHeader
          : isNewUX
            ? folderMessages.createHeader
            : messages.createHeader
      )}
      isOpen={open}
      onClose={handleClose}
    >
      <div className="grid grid-flow-row gap-4">
        <TextStyle variant="sm-regular" className="pr-8">
          {formatMessage(
            isNewUX ? folderMessages.description : messages.content
          )}
        </TextStyle>
        <div className="overflow-hidden">
          <TextStyle variant="sm-medium" className="mb-1">
            {formatMessage(
              isNewUX ? folderMessages.emojiSubheader : messages.emojiSubheader
            )}
          </TextStyle>
          <EmojiColorPicker
            showEmojiPicker={!isNewUX}
            colorName={colorName}
            emoji={emoji}
            onColorChange={handleColorChange}
            onEmojiChange={handleEmojiChange}
          />
        </div>
        <TextField
          name="title"
          label={formatMessage(
            isNewUX ? folderMessages.folderNameLabel : messages.nameLabel
          )}
          value={title}
          onChange={handleTitleChange}
          placeholder={formatMessage(messages.namePlaceholder)}
          isInvalid={!isTitleValid}
          invalidText={formatMessage(
            isNewUX ? folderMessages.invalidTitle : messages.invalidTitle
          )}
          inputClassName={inputClassName}
          labelClassName={labelClassName}
          required
        />
        <TextField
          name="description"
          label={formatMessage(messages.descriptionLabel)}
          value={description}
          onChange={handleDescriptionChange}
          placeholder={formatMessage(messages.descriptionPlaceholder)}
          isInvalid={false}
          invalidText=""
          inputClassName={inputClassName}
          labelClassName={labelClassName}
        />

        {!isNewUX && (
          <div>
            <TextStyle variant="sm-medium" className="mb-1">
              {formatMessage(messages.pinning)}
            </TextStyle>
            <div className="flex items-center rounded-lg bg-gray-3 px-4 py-3">
              <Checkbox
                id="pinCollection"
                checked={isPinned}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIsPinnedChange(e.target.checked)
                }
              />
              <label
                htmlFor="pinCollection"
                className="ml-2 cursor-pointer text-sm text-gray-9"
              >
                {formatMessage(messages.pinThisCollection)}
              </label>
            </div>
          </div>
        )}
      </div>
      <section className="mt-6 flex items-center">
        <Button
          variation="secondaryEmphasized"
          isFullWidth
          className="mr-3"
          onClick={handleClose}
        >
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          variation="primary"
          isFullWidth
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {Boolean(isSubmitting) && <LoadingSpinner />}
          {formatMessage(isSubmitting ? messages.saving : messages.save)}
        </Button>
      </section>
    </Modal>
  );
}
