import {
  MemberStatus,
  SplitNames,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import { GlobalFilter } from '@assembly-web/ui';

import { useGetImportantActivitiesQuery } from '../hooks/useGetImportantActivitiesQuery';
import { useIsNewUX } from '../hooks/useIsNewUX';
import { useRewardsHeaderDetails } from '../hooks/useRewardsHeaderDetails';
import { getDefaultRewardsType } from '../services/common';
import { isAdminMember } from '../services/member';

type DiscoverPageFilterSectionProps = {
  filter: GlobalFilterOption;
  aggregations: Record<GlobalFilterOption, number> | undefined;
  onFilterChange: (filter: GlobalFilterOption) => void;
};

export function DiscoverPageFilterSection({
  filter,
  aggregations,
  onFilterChange,
}: DiscoverPageFilterSectionProps) {
  const {
    hasCharitiesSetup,
    hasGiftCardsSetup,
    hasSwagsSetup,
    hasCultureSetup,
    hasAmazonSetup,
    isRewardsTabsDisabled,
  } = useRewardsHeaderDetails();

  const hasRewardsSetup =
    hasCharitiesSetup ||
    hasCultureSetup ||
    hasGiftCardsSetup ||
    hasSwagsSetup ||
    hasAmazonSetup;

  const { isTreatmentActive: isV3RewardsEnabled } = useFeatureSplit(
    SplitNames.RewardsV3
  );

  const { isTreatmentActive: isChallengesEnabled } = useFeatureSplit(
    SplitNames.Challenges
  );

  const rewardsFilter = getDefaultRewardsType({
    hasCharitiesSetup,
    hasCultureSetup,
    hasGiftCardsSetup,
    isRewardsTabsDisabled,
  });

  const { data: currentUserDetails } = useSuspenseUserDetails();
  const isAdmin = isAdminMember(currentUserDetails.member);

  const isObserver = currentUserDetails.member.status === MemberStatus.Observer;

  const showRewardsTab =
    isV3RewardsEnabled && (isAdmin || hasRewardsSetup) && !isObserver;

  const { data: importantActivities } = useGetImportantActivitiesQuery();

  const isNewUX = useIsNewUX();

  const showChallengesTab =
    isChallengesEnabled &&
    (isAdmin ||
      currentUserDetails.assembly.settings.challenge.creation.enabled);

  let notificationsCount;

  try {
    notificationsCount = importantActivities
      ? Number.parseInt(importantActivities.pages[0].data.total)
      : undefined;
  } catch {
    // ignore
  }

  return (
    <GlobalFilter
      isNewUX={isNewUX}
      selectedFilter={filter}
      aggregations={aggregations}
      showRewardsTab={showRewardsTab}
      onFilterChange={onFilterChange}
      showChallengesTab={showChallengesTab}
      notificationsCount={notificationsCount}
      hideAggregationsValue={rewardsFilter === 'amazon'}
    />
  );
}
