import {
  usePusherChannel,
  usePusherEvent,
  usePusherEvents,
} from '@assembly-web/pusher';
import {
  type CollectionItemsAPIResponse,
  type CollectionsAPIResponse,
  type FolderColor,
  type Icon,
  logger,
} from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import { navItemsQueryKey } from '../nav/useNavData';

type CollectionUpdatedEvent = {
  collectionId: string;
  name: string;
  colorName: FolderColor;
  description: string;
  icon: Icon;
};

type CollectionDeletedEvent = {
  collectionId: string;
};

type CollectionItemUpdatedEvent = {
  action: 'add' | 'remove' | 'reorder';
  entityId: string;
  type: string;
  collectionId: string;
};

const collectionQueryKey = ['collections'];

export function useCollectionUpdatesEvents() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const channel = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  usePusherEvent<CollectionUpdatedEvent>(
    channel,
    'COLLECTION_CREATED',
    (data) => {
      try {
        if (data) {
          const previousCollections =
            queryClient.getQueryData<CollectionsAPIResponse>(
              collectionQueryKey
            );

          const updatedCollections = produce(previousCollections, (draft) => {
            draft?.data.unshift(data);
          });
          queryClient.setQueryData(collectionQueryKey, updatedCollections);
        }
      } catch (e) {
        logger.error(
          'Error on collection created pusher event',
          {},
          e as Error
        );
      }
    }
  );

  usePusherEvent<CollectionUpdatedEvent>(
    channel,
    'COLLECTION_UPDATED',
    (data) => {
      try {
        if (data) {
          const previousCollections =
            queryClient.getQueryData<CollectionsAPIResponse>(
              collectionQueryKey
            );

          const updatedCollections = produce(previousCollections, (draft) => {
            if (draft) {
              draft.data = draft.data.map((collection) => {
                if (collection.collectionId === data.collectionId) {
                  return {
                    ...collection,
                    ...data,
                  };
                }
                return collection;
              });
            }
          });
          queryClient.setQueryData(collectionQueryKey, updatedCollections);
        }
      } catch (e) {
        logger.error(
          'Error on collection updated pusher event',
          {},
          e as Error
        );
      }
    }
  );
  usePusherEvents(
    channel,
    ['COLLECTION_ACCESS_DELETED', 'COLLECTION_ACCESS_ADDED'],
    () => {
      try {
        queryClient.refetchQueries({
          queryKey: ['collections'],
        });
        queryClient.refetchQueries({ queryKey: navItemsQueryKey });
      } catch (e) {
        logger.error(
          'Error on collection access update pusher event',
          {},
          e as Error
        );
      }
    }
  );

  usePusherEvent<CollectionDeletedEvent>(
    channel,
    'COLLECTION_DELETED',
    (data) => {
      try {
        if (data) {
          const previousCollections =
            queryClient.getQueryData<CollectionsAPIResponse>(
              collectionQueryKey
            );

          const updatedCollections = produce(previousCollections, (draft) => {
            if (draft) {
              draft.data = draft.data.filter((collection) => {
                return collection.collectionId !== data.collectionId;
              });
            }
          });
          queryClient.setQueryData(collectionQueryKey, updatedCollections);
        }
      } catch (e) {
        logger.error(
          'Error on collection deleted pusher event',
          {},
          e as Error
        );
      }
    }
  );

  usePusherEvent<CollectionItemUpdatedEvent>(
    channel,
    'COLLECTION_ITEM_UPDATED',
    (data) => {
      try {
        if (data) {
          const queryKey = ['collectionItems', data.collectionId];
          const previousCollectionItems =
            queryClient.getQueryData<CollectionItemsAPIResponse>(queryKey);

          if (data.action === 'add') {
            queryClient.invalidateQueries({
              queryKey: queryKey,
            });
          } else if (data.action === 'remove') {
            const updatedCollectionItems = produce(
              previousCollectionItems,
              (draft) => {
                if (draft?.data) {
                  draft.data = draft.data.filter(
                    (item) => item.id !== data.entityId
                  );
                }
              }
            );
            queryClient.setQueryData(queryKey, updatedCollectionItems);
          }
        }
      } catch (e) {
        logger.error(
          'Error on collection item updated pusher event',
          {},
          e as Error
        );
      }
    }
  );
  usePusherEvents(channel, ['ITEM_PINNED', 'ITEM_UNPINNED'], () => {
    try {
      queryClient.refetchQueries({ queryKey: navItemsQueryKey });
    } catch (e) {
      logger.error(
        'Error on collection pin update pusher event',
        {},
        e as Error
      );
    }
  });
}
