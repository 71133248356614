import type {
  AxomoShippingCountryAPIResponse,
  AxomoShippingStateAPIResponse,
  AxomoStoreInfoAPIResponse,
  AxomoSwagAPIResponse,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getSwagQuery(
  swagId: string,
  storeId: string,
  options?: UseQueryOptions<AxomoSwagAPIResponse>
): UseQueryOptions<AxomoSwagAPIResponse> {
  return {
    ...options,
    queryKey: ['swag', swagId, storeId],
    queryFn: async () => {
      const response = await assemblyAPI.get<AxomoSwagAPIResponse>(
        APIEndpoints.swagDetails(swagId, storeId)
      );
      return response.data;
    },
    gcTime: 0,
  };
}

export function getAxomoShippingCountriesQuery(
  options?: UseQueryOptions<AxomoShippingCountryAPIResponse[]>
): UseQueryOptions<AxomoShippingCountryAPIResponse[]> {
  return {
    ...options,
    queryKey: ['shippingCountries'],
    queryFn: async () => {
      const response = await assemblyAPI.get<AxomoShippingCountryAPIResponse[]>(
        APIEndpoints.axomoShippingCountries
      );
      return response.data;
    },
  };
}

export function getAxomoShippingStatesQuery(
  countryCode: string,
  makeRequest: boolean,
  options?: UseQueryOptions<AxomoShippingStateAPIResponse>
): UseQueryOptions<AxomoShippingStateAPIResponse> {
  return {
    ...options,
    enabled: makeRequest,
    queryKey: ['shippingStates', countryCode],
    queryFn: async () => {
      const response = await assemblyAPI.get<AxomoShippingStateAPIResponse>(
        APIEndpoints.stateList(countryCode)
      );
      return response.data;
    },
  };
}

export function getAxomoStoreInfo(
  options?: Partial<UseQueryOptions<AxomoStoreInfoAPIResponse>>
) {
  return {
    ...options,
    queryKey: ['axomoStoreInfo'],
    queryFn: async () => {
      const response = await assemblyAPI.get<AxomoStoreInfoAPIResponse>(
        APIEndpoints.axomoStoreInfo
      );
      return response.data;
    },
  };
}
