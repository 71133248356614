import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

import type { TemplateFiltersApiResponse } from '../types';

export function getTemplateFiltersQuery(): UseQueryOptions<TemplateFiltersApiResponse> {
  return {
    queryKey: ['templateFilters'],
    queryFn: async () => {
      const response = await assemblyAPI.get<TemplateFiltersApiResponse>(
        APIEndpoints.listTemplateFilters
      );
      return response.data;
    },
  };
}
