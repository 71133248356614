import { config, mapHexCodeToEmoticon } from '@assembly-web/services';
import {
  IconButton,
  OverflowText,
  QuickAction,
  TextStyle,
  Tooltip,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { PlusIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

import { useActionBarQuery } from '../../../hooks/useActionBarQuery';
import { GIVE_RECOGNITION_FLOW } from '../../FlowsDropdown';
import { CollectionSkeletonLoader } from '../CollectionSkeletonLoader';

type FlowSectionProps = {
  isUserStatusObserver: boolean;
  workspaceSlugPath: string;
  onCreateFlowButtonClick: () => void;
  canCreateFlow: boolean;
};

const messages = defineMessages({
  flowsHeader: {
    defaultMessage: 'Flows I am a part of',
    id: 'Ogptme',
  },
  createFlowTooltip: {
    defaultMessage: 'Create a flow',
    id: 'Nmjp0t',
  },
});

export const FlowSection = ({
  isUserStatusObserver,
  workspaceSlugPath,
  onCreateFlowButtonClick,
  canCreateFlow,
}: FlowSectionProps) => {
  const { data, isPending } = useActionBarQuery();

  const navigate = useAssemblyNavigate();
  const { formatMessage } = useIntl();

  const flowsToView = data?.flowsToView ?? [];
  const flows = [
    ...(!isUserStatusObserver ? [GIVE_RECOGNITION_FLOW] : []),
    ...flowsToView.map((flow) => ({
      id: flow.flowId,
      name: flow.name,
      icon: flow.icon,
    })),
  ];

  const handleFlowItemClick = ({ id }: { id: string }) => {
    const params = new URLSearchParams();
    params.append('redirectUrl', `${config.domains.app}/a/discover`);
    const urlToNavigate = `/${workspaceSlugPath}/flows/${id}?${params.toString()}`;

    navigate(urlToNavigate);
  };

  return (
    <section>
      <div className="flex w-full items-center justify-between py-1 pl-4 pr-2 text-left">
        <TextStyle aria-hidden="true" variant="base-medium">
          {formatMessage(messages.flowsHeader)}
        </TextStyle>
        {canCreateFlow ? (
          <Tooltip tooltipText={formatMessage(messages.createFlowTooltip)}>
            <IconButton
              onClick={onCreateFlowButtonClick}
              variation="tertiaryLite"
              size="small"
            >
              <PlusIcon className="h-4 w-4" />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
      <div>
        {isPending ? (
          <CollectionSkeletonLoader />
        ) : (
          flows.map(({ name, id, icon }) => (
            <QuickAction
              icon={mapHexCodeToEmoticon(icon.value)}
              key={id}
              text={
                <OverflowText tooltipText={name} className="!block truncate">
                  {name}
                </OverflowText>
              }
              onClick={() => handleFlowItemClick({ id })}
            />
          ))
        )}
      </div>
    </section>
  );
};
