import type { RemindersType } from '@assembly-web/services';
import { PromptWithChoiceBox, Select, TextStyle } from '@assembly-web/ui';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Root as AccordionRoot,
} from '@radix-ui/react-accordion';
import { getTimeZones, type TimeZone } from '@vvo/tzdb';
import { type ReactNode, useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetFlowBuilderState,
  useGetFlowResponseType,
  useGetRemindersTimeZone,
  useGetRemindersType,
  useSetFlowBuilderState,
} from '../../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../hooks/useAnyOccurrenceInProgress';
import { useFlowFrequencyOptions } from '../../hooks/useGetFlowFrequencyOptions';
import { useGetTimeOptions } from '../../hooks/useGetTimeOptions';
import {
  isFrequencyOption,
  isRemindersType,
  isTimeOption,
} from '../../utils/typeGuards';

type ReminderCountItem = {
  id: number;
  label: number;
};

type ReminderChoicesType = {
  label: string;
  id: RemindersType;
  description?: string;
};

const messages = defineMessages({
  remindersRespondToFlowLabel: {
    defaultMessage: 'How do you want to remind people to post?',
    id: 'DwIF0z',
  },
  automateLabel: {
    defaultMessage: 'Automatically',
    id: 'fzCKkR',
  },
  automateDescription: {
    defaultMessage: 'Send automated reminders',
    id: 'VZd41y',
  },
  manualLabel: {
    defaultMessage: 'Manually',
    id: '4RfzPv',
  },
  manualDescription: {
    defaultMessage: 'Send reminders on-demand',
    id: 'lQNrDH',
  },
  scheduleSettingsLabel: {
    defaultMessage: 'Settings: Automated reminders',
    id: 'cJ988l',
  },
  remindersSettingsLabel: {
    defaultMessage: 'How often would you like to remind people to post',
    id: 'PtJQh6',
  },
  atWhatTimeLabel: {
    defaultMessage: 'At what time',
    id: 'nVpRpB',
  },
  chooseTimeZoneLabel: {
    defaultMessage: 'Choose a time zone',
    id: 'neoj+d',
  },
  howManyRemindersLabel: {
    defaultMessage: 'How many reminders would you like to send?',
    id: '+zj6Tl',
  },
  howManyRemindersDescription: {
    defaultMessage: `We'll evenly space reminders across the scheduled window. Only people who haven't posted will be reminded.`,
    id: 'Wx+LK4',
  },
});

function Root({ children }: { children: ReactNode }) {
  return (
    <AccordionRoot
      collapsible
      type="single"
      defaultValue="schedule"
      className="my-4 w-full rounded-md bg-gray-2 p-4"
    >
      {children}
    </AccordionRoot>
  );
}

function Row({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();

  return (
    <AccordionItem value="schedule" defaultValue="schedule">
      <AccordionTrigger className="group flex w-full items-center gap-2 p-2 data-[state=open]:border-gray-5">
        <ChevronDownIcon className="h-4 w-4 stroke-current text-gray-9 will-change-transform group-data-[state=open]:rotate-180" />
        <TextStyle variant="base-medium">
          {formatMessage(messages.scheduleSettingsLabel)}
        </TextStyle>
      </AccordionTrigger>
      <AccordionContent className="px-2">{children}</AccordionContent>
    </AccordionItem>
  );
}

function Settings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const flowResponseType = useGetFlowResponseType(id);
  const remindersCount = useGetFlowBuilderState(id, 'remindersCount');

  const setRemindersCount = useSetFlowBuilderState(id, 'remindersCount');

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const remindersCountOptions = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => ({
      id: i + 1,
      label: i + 1,
    }));
  }, []);

  const frequencyTimeOptions = useGetTimeOptions();
  const frequencyOptions = useFlowFrequencyOptions(new Date());
  const timeZonesWithUtc = getTimeZones({ includeUtc: true });

  const timeZone = useGetRemindersTimeZone(id);
  const dueTime = useGetFlowBuilderState(id, 'remindersDueTime');
  const repeatFrequency = useGetFlowBuilderState(id, 'remindersFrequency');

  const setDueTime = useSetFlowBuilderState(id, 'remindersDueTime');
  const setSelectedTimeZone = useSetFlowBuilderState(id, 'remindersTimeZone');
  const setRemindersFrequency = useSetFlowBuilderState(
    id,
    'remindersFrequency'
  );

  const selectedRepeatFrequency = frequencyOptions.find(
    (option) => option.id === repeatFrequency
  );

  const selectedFrequencyTime =
    frequencyTimeOptions.find((option) => option.id === dueTime) ??
    frequencyTimeOptions[0];

  const selectedReminderCount = remindersCountOptions.find(
    (option) => option.id === remindersCount
  );

  const selectedTimeZone = timeZonesWithUtc.find(
    (option) => option.name === timeZone
  );

  return (
    <section className="flex max-w-2xl flex-col gap-3">
      {flowResponseType === 'anytime' ? (
        <>
          <Select
            position="top"
            name="timeSettings"
            options={frequencyOptions}
            disabled={isOccurrenceInProgress}
            selected={selectedRepeatFrequency}
            label={formatMessage(messages.remindersSettingsLabel)}
            onChange={(option: unknown) => {
              if (isFrequencyOption(option)) {
                setRemindersFrequency(option.id);
              }
            }}
            getValue={(option) => (isFrequencyOption(option) ? option.id : '')}
            getText={(option) =>
              isFrequencyOption(option) ? option.label : ''
            }
          />
          <div className="flex gap-4">
            <Select
              position="top"
              classNames="w-full"
              name="timeSettings"
              options={frequencyTimeOptions}
              selected={selectedFrequencyTime}
              disabled={isOccurrenceInProgress}
              label={formatMessage(messages.atWhatTimeLabel)}
              onChange={(option: unknown) => {
                if (isTimeOption(option)) {
                  setDueTime(option.id);
                }
              }}
              getValue={(option) => (isTimeOption(option) ? option.id : '')}
              getText={(option) => (isTimeOption(option) ? option.label : '')}
            />
            <Select
              position="top"
              classNames="w-full"
              name="timeZoneSettings"
              options={timeZonesWithUtc}
              selected={selectedTimeZone}
              disabled={isOccurrenceInProgress}
              label={formatMessage(messages.chooseTimeZoneLabel)}
              onChange={(option: unknown) => {
                const value = option as TimeZone;
                setSelectedTimeZone(value.name);
              }}
              getValue={(option) => {
                const value = option as TimeZone;
                return value.name;
              }}
              getText={(option) => {
                const value = option as TimeZone;
                return `${value.alternativeName} - ${value.countryName}`;
              }}
            />
          </div>
        </>
      ) : (
        <section>
          <Select
            position="top"
            name="reminderCount"
            options={remindersCountOptions}
            selected={selectedReminderCount}
            disabled={isOccurrenceInProgress}
            label={formatMessage(messages.howManyRemindersLabel)}
            onChange={(option: unknown) => {
              const { id } = option as ReminderCountItem;
              setRemindersCount(id);
            }}
            getValue={(option) => {
              const { id } = option as ReminderCountItem;
              return id;
            }}
            getText={(option) => {
              const { label } = option as ReminderCountItem;
              return label.toString();
            }}
          />
          <TextStyle variant="xs-regular" subdued>
            {formatMessage(messages.howManyRemindersDescription)}
          </TextStyle>
        </section>
      )}
    </section>
  );
}

const ReminderSchedule = {
  Row,
  Root,
  Settings,
};

export function RemindersSettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const choices: ReminderChoicesType[] = useMemo(() => {
    return [
      {
        id: 'automate',
        label: formatMessage(messages.automateLabel),
        description: formatMessage(messages.automateDescription),
      },
      {
        id: 'manual',
        label: formatMessage(messages.manualLabel),
        description: formatMessage(messages.manualDescription),
      },
    ];
  }, [formatMessage]);

  const reminderType = useGetRemindersType(id);
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const setReminderType = useSetFlowBuilderState(id, 'remindersType');

  const handleValueChange = useCallback(
    (value: string) => {
      if (isRemindersType(value)) {
        setReminderType(value);
      }
    },
    [setReminderType]
  );

  return (
    <>
      <PromptWithChoiceBox.Root>
        <PromptWithChoiceBox.Row>
          <PromptWithChoiceBox.Prompt>
            {formatMessage(messages.remindersRespondToFlowLabel)}
          </PromptWithChoiceBox.Prompt>
          <PromptWithChoiceBox.Choices
            choices={choices}
            value={reminderType}
            disabled={isOccurrenceInProgress}
            onValueChange={handleValueChange}
          />
        </PromptWithChoiceBox.Row>
      </PromptWithChoiceBox.Root>
      {reminderType === 'automate' && (
        <ReminderSchedule.Root>
          <ReminderSchedule.Row>
            <ReminderSchedule.Settings />
          </ReminderSchedule.Row>
        </ReminderSchedule.Root>
      )}
    </>
  );
}
