import {
  APIEndpoints,
  type CollectionsAPIResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getCollectionsQuery(
  options?: Partial<UseQueryOptions<CollectionsAPIResponse>>
): UseQueryOptions<CollectionsAPIResponse> {
  return {
    ...options,
    queryKey: ['collections'],
    queryFn: async () => {
      const response = await assemblyAPI.get<CollectionsAPIResponse>(
        APIEndpoints.listCollections
      );
      return response.data;
    },
  };
}
