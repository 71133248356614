import type { ImportantActivitiesApiResponse } from '@assembly-web/services';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getImportantActivitiesQuery } from '../queries/getImportantActivitiesQuery';

export function useGetImportantActivitiesQuery() {
  return useInfiniteQuery<ImportantActivitiesApiResponse>(
    getImportantActivitiesQuery()
  );
}
