import type { Timeout } from '@assembly-web/services';
import {
  AnnouncementBanner,
  Button,
  IdContextProvider,
  PostDrawerContextProvider,
  PostError,
  PostLoader,
  useDeviceInfo,
  useIdContext,
} from '@assembly-web/ui';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import {
  type ElementRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RemoveScroll } from 'react-remove-scroll';
import { twJoin } from 'tailwind-merge';

import { useGetPostAndRepliesDetails } from '../../../../../hooks/useGetPostAndRepliesDetails';
import { usePostDrawerStore } from '../../../../../stores/usePostDrawerStore';
import { useNewReplyAddedEvent } from '../../../hooks/domainEvents/useNewReplyAddedEvent';
import { usePostReactionsUpdatesEvents } from '../../../hooks/domainEvents/usePostReactionsUpdatesEvents';
import { useGetPostRepliesInfiniteQuery } from '../../../hooks/replies/useRepliesQueries';
import { useGetImportantActivitiesQuery } from '../../../hooks/useGetImportantActivitiesQuery';
import { useGetRepliesDrafts } from '../../../hooks/useGetRepliesDrafts';
import { getShouldOpenPostDrawer } from '../../../services/common';
import type { PostDrawerProps } from '../types';
import { PostContainer } from './PostContainer';
import { RepliesContainer } from './RepliesContainer';
import { RepliesEditorContainer } from './RepliesEditorContainer';
import {
  getMentionedMembersInFlowResponse,
  getPostDetailsFromPostData,
  getReplyByReplyId,
} from './utils/util';

const FooterContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const id = useIdContext();

  return (
    <section id={id} className={className}>
      {children}
    </section>
  );
};

const messages = defineMessages({
  newReplyActivity: {
    defaultMessage:
      'See {count, plural, =0 {no replies} one {# reply} other {# replies}}',
    id: 'ME0fk5',
  },
});

export const PostDrawerBody = (props: PostDrawerProps) => {
  const { formatMessage } = useIntl();
  const [isRemoveScrollEnabled, setIsRemoveScrollEnabled] = useState(false);

  const postDrawerRef = useRef<ElementRef<'section'>>(null);
  const postWithRepliesContainerRef = useRef<ElementRef<'section'>>(null);

  const [showShadowForRepliesEditor, setShowShadowForRepliesEditor] =
    useState(false);
  const { repliesToEditMap, updateRepliesToEditMap } = usePostDrawerStore();

  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const { flowId, responseId, postType } = getPostDetailsFromPostData(
    props.data
  );
  const drawerId = `${flowId}-${responseId}`;
  const selectedReply = repliesToEditMap[drawerId];

  usePostReactionsUpdatesEvents();

  const { count: currentReplyCount, reset: resetReplyCount } =
    useNewReplyAddedEvent({
      responseId: responseId,
      flowId: flowId === 'recognition' ? '' : flowId,
      postId: flowId === 'recognition' ? responseId : '',
    });

  const {
    isSuccess,
    flowResponse,
    isPostCardDataError,
    isPostCardDataLoading,
    isPostNotFoundError,
  } = useGetPostAndRepliesDetails({
    flowId,
    responseId,
  });

  const { refetch: refetchImportantActivities } =
    useGetImportantActivitiesQuery();

  useEffect(() => {
    let timerId: Timeout;
    if (isSuccess && getShouldOpenPostDrawer()) {
      timerId = setTimeout(() => refetchImportantActivities(), 3000);
    }
    return () => clearTimeout(timerId);
  }, [isSuccess, refetchImportantActivities, responseId]);

  const getCommentIdToInclude = () => {
    const searchParams = new URLSearchParams(location.search);

    const flowIdFromUrl = searchParams.get('flowId');
    const postIdFromUrl = searchParams.get('postId');
    const responseIdFromUrl = searchParams.get('responseId');
    const commentIdFromUrl = searchParams.get('commentId') ?? undefined;

    const shouldOpenPostDrawer = getShouldOpenPostDrawer();

    const isFlowPost = Boolean(flowIdFromUrl && responseIdFromUrl);
    const isLegacyFlowPost = Boolean(postIdFromUrl && !isFlowPost);

    if (
      shouldOpenPostDrawer &&
      ((isFlowPost &&
        flowIdFromUrl === flowId &&
        responseIdFromUrl === responseId) ||
        (isLegacyFlowPost && postIdFromUrl === responseId))
    ) {
      return commentIdFromUrl;
    }

    const commentId = props.data.commentId;

    if (commentId && commentId !== 'viewLastReply') {
      return commentId;
    }

    return undefined;
  };

  const {
    refetch: refetchReplies,
    isLoading: isRepliesLoading,
    data: repliesData,
    error: isGetPostRepliesError,
  } = useGetPostRepliesInfiniteQuery({
    flowId,
    responseId,
    commentId: getCommentIdToInclude(),
  });

  const { isPending: isDraftsPending, error: isGetRepliesDraftsError } =
    useGetRepliesDrafts(responseId);

  const isLoading =
    isDraftsPending || isRepliesLoading || isPostCardDataLoading;

  const handleShadowForRepliesEditor = useCallback((container: HTMLElement) => {
    const { scrollTop, scrollHeight, clientHeight } = container;
    const isScrollAtEnd =
      Math.abs(scrollTop + clientHeight - scrollHeight) <= 10;
    setShowShadowForRepliesEditor(!isScrollAtEnd);
  }, []);

  const handleOnPostWithRepliesScroll = useCallback(() => {
    const { current: container } = postWithRepliesContainerRef;
    if (container) {
      handleShadowForRepliesEditor(container);
    }
  }, [handleShadowForRepliesEditor]);

  useEffect(() => {
    const { current: container } = postWithRepliesContainerRef;
    const { current: postDrawer } = postDrawerRef;
    let mutationObserver: MutationObserver | undefined;
    if (container && postDrawer) {
      mutationObserver = new MutationObserver(() => {
        handleShadowForRepliesEditor(container);
      });

      mutationObserver.observe(postDrawer, {
        subtree: true,
        attributes: true,
        childList: true,
      });
    }

    return () => {
      mutationObserver?.disconnect();
    };
  }, [handleShadowForRepliesEditor, isLoading]);

  const updateSelectedReply = useCallback(
    (replyId: string) => {
      const reply = getReplyByReplyId(repliesData, replyId);
      if (reply) {
        updateRepliesToEditMap({ ...reply, isEditing: true }, drawerId);
      }
    },
    [drawerId, repliesData, updateRepliesToEditMap]
  );

  const handleEditReplyClick = (replyId: string) => {
    updateSelectedReply(replyId);
  };

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (postDrawerRef.current?.contains(e.target as Node)) {
        setIsRemoveScrollEnabled(true);
      } else {
        setIsRemoveScrollEnabled(false);
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: true });
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    if (props.data.editSelectedCommentId) {
      updateSelectedReply(props.data.editSelectedCommentId);
    }
    // Removed dependency intentionally - here updateSelectedReply only on editing from Reply card
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.editSelectedCommentId, isLoading]);

  if (isPostCardDataError || isGetPostRepliesError || isGetRepliesDraftsError) {
    return (
      <PostError errorType={isPostNotFoundError ? 'NotFound' : 'ServerError'} />
    );
  }

  return (
    <PostDrawerContextProvider>
      <IdContextProvider>
        <section ref={postDrawerRef} className="h-full">
          <RemoveScroll
            enabled={!isMobile && isRemoveScrollEnabled}
            className="flex h-full flex-col overflow-hidden bg-gray-1"
          >
            {isLoading ? (
              <section className="flex flex-grow flex-col overflow-auto px-6 pb-4">
                <PostLoader />
              </section>
            ) : (
              <>
                {flowResponse?.activeAnnouncement?.color ? (
                  <AnnouncementBanner
                    className="h-10 rounded-none"
                    color={flowResponse.activeAnnouncement.color}
                    title={flowResponse.activeAnnouncement.title}
                    description={flowResponse.activeAnnouncement.description}
                  />
                ) : null}
                <section
                  ref={postWithRepliesContainerRef}
                  className="flex flex-grow flex-col overflow-x-hidden px-6 pb-4"
                  onScroll={handleOnPostWithRepliesScroll}
                >
                  <PostContainer
                    kind={postType}
                    flowId={flowId}
                    responseId={responseId}
                  />
                  <RepliesContainer
                    containerRef={postWithRepliesContainerRef}
                    flowId={flowId}
                    responseId={responseId}
                    onEditReplyClick={handleEditReplyClick}
                    editingReplyId={
                      selectedReply?.isEditing ? selectedReply.commentID : null
                    }
                    focusedCommentID={props.data.commentId ?? null}
                  />
                </section>
                {currentReplyCount > 0 && (
                  <Button
                    size="small"
                    variation="primary"
                    className="absolute bottom-44 left-1/3"
                    onClick={async () => {
                      resetReplyCount();
                      await refetchReplies();
                      setTimeout(() => {
                        postWithRepliesContainerRef.current?.scrollTo({
                          behavior: 'smooth',
                          top:
                            postWithRepliesContainerRef.current.scrollHeight -
                            postWithRepliesContainerRef.current.clientHeight,
                        });
                      }, 0);
                    }}
                  >
                    <div className="h-4 w-4">
                      <ArrowDownCircleIcon title="arrow-down-icon" />
                    </div>
                    {formatMessage(messages.newReplyActivity, {
                      count: currentReplyCount,
                    })}
                  </Button>
                )}
                {!flowResponse?.hideReplies ? (
                  <FooterContainer
                    className={twJoin(
                      'z-10 flex max-h-[80%] flex-shrink-0 flex-col gap-2 bg-gray-1 px-6 pb-4 pt-3 transition-all duration-300',
                      showShadowForRepliesEditor && 'shadow-base-up'
                    )}
                  >
                    <RepliesEditorContainer
                      key={selectedReply?.commentID ?? responseId}
                      containerRef={postWithRepliesContainerRef}
                      kind={postType}
                      flowId={flowId}
                      drawerId={drawerId}
                      responseId={responseId}
                      selectedReply={selectedReply}
                      commentId={props.data.commentId}
                      flowName={flowResponse?.flow.name}
                      mentionedUsers={getMentionedMembersInFlowResponse(
                        flowResponse
                      )}
                      isAnnouncement={Boolean(
                        flowResponse?.activeAnnouncement?.announcementId
                      )}
                      onSelectedReplyChange={updateRepliesToEditMap}
                      isRepliesDisabled={Boolean(flowResponse?.hideReplies)}
                      editedCommentId={props.data.editSelectedCommentId}
                    />
                  </FooterContainer>
                ) : null}
              </>
            )}
          </RemoveScroll>
        </section>
      </IdContextProvider>
    </PostDrawerContextProvider>
  );
};
