import type { MetadataProps, PinnedCollection } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

export const navItemsQueryKey = ['navItems'];

type NavItemsPageData = {
  total: number;
  metadata?: MetadataProps;
  data: PinnedCollection[];
};

export type NavItemsResponse = {
  pages: NavItemsPageData[];
  pageParams: unknown[];
};

function getNavDataInfinite(
  options?: Partial<UndefinedInitialDataInfiniteOptions<NavItemsPageData>>
): UndefinedInitialDataInfiniteOptions<NavItemsPageData> {
  return {
    ...options,
    queryKey: navItemsQueryKey,
    queryFn: async ({ pageParam }) => {
      const { data } = await assemblyAPI.post(APIEndpoints.getNavItems, {
        ...(pageParam ? { cursor: pageParam, limit: 10 } : { limit: 10 }),
      });
      return data satisfies NavItemsPageData;
    },
    initialPageParam: 0,
    getNextPageParam: (page) => page.metadata?.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata?.pagination.cursor.previous,
  };
}

export function useNavData(
  options?: Partial<UndefinedInitialDataInfiniteOptions<NavItemsPageData>>
) {
  // TODO: handle error
  // onError: () => trackNavError({ action: 'getNavData' }),
  const result = useInfiniteQuery<NavItemsPageData>(
    getNavDataInfinite(options)
  );
  const pinnedCollections = result.data?.pages.flatMap((x) => x.data) ?? [];

  const totalNoOfPinnedCollections = result.data?.pages[0].total ?? 0;
  const totalSoftPinnedCollections =
    result.data?.pages[0].data.filter((item) => item.softPin).length ?? 0;

  return {
    ...result,
    pinnedCollections,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
  };
}
