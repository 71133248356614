import type {
  Collection,
  FolderColor,
  Icon,
  ItemToAdd,
} from '@assembly-web/services';
import { defaultFolderColor, useUserDetails } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';

import { trackDiscoverAction } from '../services/analytics';
import type { CollectionPayload } from './useCollectionsMutation';
import { useCollectionsMutation } from './useCollectionsMutation';
import type { SearchPayload } from './useSearchIndex';

const defaultEmoji = '1f4c1';
const defaultPinnedEmoji = '1f4cc';

const getIcon = (emoji: string): Icon => {
  return {
    kind: 'HEX_CODE',
    value: emoji,
  };
};

type CollectionCreateModalHookProps = {
  collection: Collection | null;
  filter: GlobalFilterOption;
  onClose: () => void;
  isPinnedDefault: boolean;
  itemToAdd: ItemToAdd | null;
  numCollections?: number;
  query?: SearchPayload;
};

export function useCreateCollectionModal(
  props: CollectionCreateModalHookProps
) {
  const {
    collection,
    filter,
    isPinnedDefault,
    itemToAdd,
    numCollections = 0,
    onClose,
    query,
  } = props;
  const [colorName, setColorName] = useState<FolderColor>(defaultFolderColor);
  const [emoji, setEmoji] = useState<string>(defaultEmoji);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPinned, setIsPinned] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: updateCollection } = useCollectionsMutation();

  const { data: userDetails } = useUserDetails();
  const workspaceSlug = userDetails?.assembly.workspaceSlugPath;

  useEffect(() => {
    if (collection?.collectionId) {
      setColorName(collection.colorName as FolderColor);
      setEmoji(collection.icon.value);
      setTitle(collection.name);
      setDescription(collection.description ?? '');
      setIsPinned(collection.isPinned ?? false);
    } else {
      setColorName(defaultFolderColor as FolderColor);
      setEmoji(defaultEmoji);
      setTitle('');
      setDescription('');
      setIsPinned(false);
    }
  }, [
    collection?.collectionId,
    collection?.colorName,
    collection?.description,
    collection?.icon,
    collection?.name,
    collection?.isPinned,
  ]);

  useEffect(() => {
    if (isPinnedDefault) {
      setIsPinned(true);
      setEmoji(defaultPinnedEmoji);
    }
  }, [isPinnedDefault]);

  const handleTitleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setTitle(ev.target.value);
  };

  const checkValidTitle = (newValue: string) => {
    const trimmedValue = newValue.trim();
    const isValid = trimmedValue.length > 0 && trimmedValue.length < 30;

    setIsTitleValid(isValid);
    return isValid;
  };

  const handleColorChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setColorName(ev.target.value as FolderColor);
  };

  const handleDescriptionChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setDescription(ev.target.value);
  };

  const handleEmojiChange = (emoji: string) => {
    setEmoji(emoji);
  };

  const handleIsPinnedChange = (newValue: boolean) => {
    setIsPinned(newValue);
  };

  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      handleSubmit();
      ev.stopPropagation();
    }
  };

  const handleClose = () => {
    // Clear all input fields.
    setTimeout(() => {
      setColorName(defaultFolderColor);
      setDescription('');
      setEmoji(defaultEmoji);
      setTitle('');
      setIsPinned(false);

      setIsTitleValid(true);
      setIsSubmitting(false);
    }, 300); // wait for exit animation before clearing

    onClose();
  };

  const handleEdit = async () => {
    const payload = {
      collectionId: collection?.collectionId,
      name: title,
      colorName,
      description,
      icon: getIcon(emoji),
      isPinned,
    };
    const response = await updateCollection({ payload, query }, {});
    trackDiscoverAction('editCollection', {
      filterType: filter,
    });
    if (response.status === 200) {
      handleClose();
    } else {
      setIsSubmitting(false);
    }
  };

  const handleCreate = async () => {
    let payload: CollectionPayload = {
      name: title,
      colorName,
      description,
      icon: getIcon(emoji),
      isPinned,
    };

    const getItem = (itemToAdd: ItemToAdd) => {
      if (itemToAdd.name === 'Give Recognition') {
        return {
          type: 'link',
          link: {
            url: `${origin}/a/${workspaceSlug}/flows/recognition`,
            title: 'Give recognition',
          },
        };
      }
      if (itemToAdd.type === 'link') {
        return {
          type: itemToAdd.type,
          link: itemToAdd.link,
        };
      }
      return {
        id: itemToAdd.entityId,
        type: itemToAdd.type,
        responseId: itemToAdd.responseId,
      };
    };

    if (itemToAdd) {
      payload = {
        ...payload,
        item: getItem(itemToAdd),
      };
    }

    const response = await updateCollection({ payload, query });
    trackDiscoverAction('createCollection', {
      numCollections: numCollections + 1,
      filterType: filter,
    });
    if (response.status === 200) {
      handleClose();
    } else {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    const isValidTitle = checkValidTitle(title);

    if (isValidTitle) {
      setIsSubmitting(true);

      if (collection?.collectionId) {
        await handleEdit();
      } else {
        await handleCreate();
      }
      setTimeout(() => {
        setIsSubmitting(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (!isTitleValid) {
      checkValidTitle(title);
    }
  }, [isTitleValid, title]);

  return {
    checkValidTitle,
    colorName,
    emoji,
    description,
    handleClose,
    handleColorChange,
    handleEmojiChange,
    handleDescriptionChange,
    handleKeyDown,
    handleIsPinnedChange,
    handleSubmit,
    handleTitleChange,
    isPinned,
    isSubmitting,
    isTitleValid,
    title,
  };
}
