import { assemblyLogo } from '@assembly-web/assets';
import {
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import {
  Button,
  IconButton,
  TextField,
  TextStyle,
  useAssemblyNavigate,
  useDeviceInfo,
} from '@assembly-web/ui';
import { Disclosure } from '@headlessui/react';
import {
  ArrowUpRightIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  PencilIcon,
  TrophyIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import type React from 'react';
import {
  type ChangeEvent,
  isValidElement,
  type ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';
import invariant from 'tiny-invariant';

import { trackTemplatesAction } from '../../../services/analytics';
import { useCreateWorkflow } from '../../discover/hooks/flowsEditor/useCreateWorkflow';
import { getCategoriesQuery } from '../queries/getCategoriesQuery';
import { useGetTemplatesQuery } from '../queries/getTemplatesQuery';
import { useCategorizedTemplatesQuery } from '../queries/getTemplatesV2Query';
import type { categoryLists, Template } from '../types';
import {
  CategorizedTemplatesLoader,
  SearchContainer,
  TemplateLoader,
  TemplatesBackground,
  TemplateTile,
} from './TemplateLayout';

const iconMap: Record<string, JSX.Element> = {
  'chat-bubble-left-right': <ChatBubbleLeftRightIcon className="h-12 w-12" />,
  megaphone: <MegaphoneIcon className="h-12 w-12" />,
  users: <UsersIcon className="h-12 w-12" />,
  heart: <HeartIcon className="h-12 w-12" />,
  trophy: <TrophyIcon className="h-12 w-12" />,
  'clipboard-document-list': (
    <ClipboardDocumentListIcon className="h-12 w-12" />
  ),
};

const message = defineMessages({
  flowTemplates: {
    id: 'oKj7i7',
    defaultMessage: 'Flow Templates',
  },
  search: {
    id: '2BM55e',
    defaultMessage: 'Search templates...',
  },
  startFromScratch: {
    id: 'fGhwMp',
    defaultMessage: 'Start from scratch',
  },
  popularTemplates: {
    id: '9z/HEU',
    defaultMessage: 'Popular Templates',
  },
  selectTemplate: {
    id: 'cke5z2',
    defaultMessage: 'Select a template to preview or edit.',
  },
  seeAll: {
    id: '078WAr',
    defaultMessage: 'See all',
  },
  collapse: {
    id: 'W/V6+Y',
    defaultMessage: 'Collapse',
  },
  emptySearchResult: {
    id: 'RQTsW7',
    defaultMessage: 'Oh no, we couldn’t find any templates for “{key}”',
  },
  templatesFor: {
    id: 'zvGHA9',
    defaultMessage: 'Templates for “{key}”',
  },
  createOwnFlow: {
    id: 'xvboQR',
    defaultMessage: 'Create your own',
  },
});

export const mapBgColor: Record<string, string> = {
  magenta: 'bg-magenta-2',
  gold: 'bg-upgrade-2',
  cyan: 'bg-cyan-2',
  blue: 'bg-blue-2',
  brand: 'bg-brand-2',
  purple: 'bg-purple-2',
};
const mapTextColor: Record<string, string> = {
  magenta: 'text-magenta-6',
  gold: 'text-upgrade-6',
  cyan: 'text-cyan-6',
  blue: 'text-blue-6',
  brand: 'text-brand-6',
  purple: 'text-purple-6',
};

type categorizedTemplatesType = categoryLists & {
  templates: Template[];
};

export function TemplateList() {
  const [search, setSearch] = useState('');
  const { formatMessage } = useIntl();
  const { data: userDetails } = useUserDetails();
  invariant(userDetails);
  const navigate = useAssemblyNavigate();
  const isTablet = useDeviceInfo().deviceType === 'tablet';
  const isMobile = useDeviceInfo().deviceType === 'mobile';
  const createFlowEditorDrawer = useCreateWorkflow();
  const { isTreatmentActive: isFlowCreationDrawerEnabled } = useFeatureSplit(
    SplitNames.FlowCreation
  );
  const { data: categoriesData, isPending: isCategoriesLoading } =
    useQuery(getCategoriesQuery());
  const categories = categoriesData?.data ?? [];

  const categoryColorMap: Record<
    string,
    {
      color: string;
      icon: ReactNode;
    }
  > = {};
  categories.forEach((category) => {
    if (category.key) {
      categoryColorMap[category.key] = {
        color: category.color,
        icon:
          typeof category.icon === 'string'
            ? iconMap[category.icon]
            : category.icon || (
                <ChatBubbleLeftRightIcon className="h-12 w-12" />
              ),
      };
    }
  });

  const results = useCategorizedTemplatesQuery(categories);

  const templateData = results
    .map((result) => {
      return result.data;
    })
    .flat();

  const isTemplatesLoading = results.some((result) => result.isLoading);

  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    data: result,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isPending: isSearchResultLoading,
  } = useGetTemplatesQuery(
    {
      filter: {},
      keyword: search,
    },
    {
      enabled: Boolean(search),
    }
  );

  const handleSearch = debounce((value: string) => {
    setSearch(value);
    trackTemplatesAction('search', { searchQuery: value });
  }, 500);

  const searchResults: Template[] = useMemo(() => {
    if (!result) {
      return [];
    }

    return result.pages.reduce<Template[]>(
      (acc, page) => [...acc, ...page.data.data],
      []
    );
  }, [result]);

  const categorizedTemplates: categorizedTemplatesType[] | undefined =
    useMemo(() => {
      return categories.map((category) => {
        const icon = categoryColorMap[category.key].icon;
        let iconElement: string | React.ReactElement;

        if (isValidElement(icon)) {
          iconElement = icon;
        } else {
          iconElement = (
            <ChatBubbleLeftRightIcon className="border-magenta-6" />
          );
        }
        return {
          ...category,
          icon: (
            <div
              className={twJoin(
                'h-16 w-16 rounded-lg p-2',
                mapBgColor[category.color],
                mapTextColor[category.color]
              )}
            >
              {iconElement}
            </div>
          ),
          templates:
            templateData.filter((template) => {
              if (template?.category === category.key) {
                return [...template.data.data];
              }
            })[0]?.data.data ?? [],
        };
      });
    }, [templateData, categories, categoryColorMap]);

  const popularTemplates: Template[] = useMemo(() => {
    return templateData
      .map((templateList) => {
        return (
          templateList?.data.data.filter(
            (template: Template) => template.isPopular
          ) ?? []
        );
      })
      .flat();
  }, [templateData]);

  const createFlowFromScratch = () => {
    const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;
    createFlowEditorDrawer({
      view: 'editor',
      type: 'scratch',
      overrideTreatmentOffCallback() {
        navigate(`/${workspaceSlugPath}/flows/editor`);
      },
    });
    if (isFlowCreationDrawerEnabled) {
      navigate('/a/discover');
    }
    trackTemplatesAction('startFromScratch');
  };

  return (
    <>
      {/* Header bar */}
      <section
        className={twJoin(
          'flex gap-5 border-b border-gray-5 p-6 md:gap-8',
          'flex-col sm:flex-row'
        )}
      >
        <div className="flex items-center gap-2">
          <img src={assemblyLogo} alt="logo" />
          <TextStyle variant="2xl-bold">
            {formatMessage(message.flowTemplates)}
          </TextStyle>
        </div>
        <div className="md:flex-end flex grow justify-end gap-8 md:order-last">
          <Button
            variation="secondaryEmphasized"
            onClick={createFlowFromScratch}
            className="w-full md:w-fit"
          >
            <PlusIcon className="h-4 w-4" />
            {formatMessage(message.startFromScratch)}
          </Button>
          {!isMobile && (
            <IconButton
              variation="tertiaryLite"
              size={'large'}
              data-testid="modalCloseButton"
              onClick={() => {
                trackTemplatesAction('exitTemplateLibrary');
                navigate('/a/discover');
              }}
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </IconButton>
          )}
        </div>
        <TextField
          placeholder={formatMessage(message.search)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
          className="md:w-[528px]"
          connectedLeft={<MagnifyingGlassIcon className="h-4 w-4" />}
        />
      </section>
      {search ? (
        <>
          <div className="absolute flex h-[385px] w-full gap-8">
            <TemplatesBackground showBg={!search} />
          </div>
          {isSearchResultLoading ? (
            <SearchContainer search={search}>
              <TemplateLoader />
            </SearchContainer>
          ) : (
            <>
              {searchResults.length ? (
                <SearchContainer search={search}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                      if (!isFetchingNextPage) {
                        fetchNextPage();
                      }
                    }}
                    hasMore={hasNextPage}
                    useWindow={false}
                    className="grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 xl:grid-cols-3"
                    getScrollParent={() => scrollRef.current}
                    key={0}
                    data-testid="template-grid"
                  >
                    <TemplateTile
                      templates={searchResults}
                      categoryColorMap={categoryColorMap}
                    />
                  </InfiniteScroll>
                </SearchContainer>
              ) : (
                <div className="m-auto flex flex-col gap-6 pt-10 text-center opacity-90">
                  <TextStyle variant="xl-medium">
                    {formatMessage(message.emptySearchResult, { key: search })}
                  </TextStyle>
                  <Button
                    variation="secondaryEmphasized"
                    className="m-auto cursor-pointer"
                    onClick={createFlowFromScratch}
                  >
                    <PencilIcon className="h-4 w-4 text-primary-6" />
                    {formatMessage(message.createOwnFlow)}
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/* Popular templates */}
          <div className="relative pb-6">
            <TemplatesBackground showBg={!search} />
            <section className="m-auto flex flex-col items-center gap-4 py-6 opacity-90">
              <div className="flex items-center gap-2">
                <ArrowUpRightIcon className="h-6 w-6 rounded-md bg-red-gradient p-1 text-gray-1" />
                <TextStyle variant="xl-medium">
                  {formatMessage(message.popularTemplates)}
                </TextStyle>
              </div>
              <TextStyle variant="base-regular" className="text-gray-8">
                {formatMessage(message.selectTemplate)}
              </TextStyle>
            </section>
            <div className="grid grid-cols-1 gap-4 opacity-90 sm:mx-20 sm:grid-cols-2 lg:mx-44 xl:grid-cols-3">
              {isCategoriesLoading || isTemplatesLoading ? (
                <>
                  {Array.from({ length: 6 }).map((_, i) => (
                    <TemplateLoader key={i} />
                  ))}
                </>
              ) : (
                <TemplateTile
                  templates={popularTemplates}
                  categoryColorMap={categoryColorMap}
                />
              )}
            </div>
          </div>
          {/*  Categorized templates */}
          <div className="flex flex-col gap-12 pt-4 sm:mx-20 lg:mx-44">
            {isCategoriesLoading || isTemplatesLoading ? (
              <CategorizedTemplatesLoader />
            ) : (
              <>
                {categorizedTemplates.map((category, i) => {
                  const templatesOnPreview = isTablet || isMobile ? 2 : 3;
                  return category.templates.length ? (
                    <div className="flex flex-col gap-6" key={i}>
                      <div className="flex-start flex gap-2 p-3 sm:gap-8 sm:p-0">
                        {category.icon}
                        <div className="flex flex-col gap-2">
                          <TextStyle variant="xl-medium">
                            {category.name}
                          </TextStyle>
                          <TextStyle
                            variant="sm-regular"
                            className="text-gray-8"
                          >
                            {category.description}
                          </TextStyle>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
                        <TemplateTile
                          templates={category.templates.slice(
                            0,
                            templatesOnPreview
                          )}
                          categoryColorMap={categoryColorMap}
                        />
                      </div>
                      {category.templates.length > templatesOnPreview && (
                        //TODO: add animation for accordion
                        <Disclosure>
                          {({ open }: { open: boolean }) => (
                            <>
                              <Disclosure.Panel>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
                                  <TemplateTile
                                    templates={category.templates.slice(
                                      templatesOnPreview,
                                      category.templates.length
                                    )}
                                    categoryColorMap={categoryColorMap}
                                  />
                                </div>
                              </Disclosure.Panel>
                              <Disclosure.Button className="flex-start m-auto flex sm:m-0">
                                <Button
                                  variation="tertiaryLite"
                                  className="w-fit items-center gap-2 text-primary-6"
                                  onClick={() => {
                                    !open &&
                                      trackTemplatesAction(
                                        'seeAllTemplatesClicked'
                                      );
                                  }}
                                >
                                  <TextStyle variant="sm-regular">
                                    {formatMessage(
                                      open ? message.collapse : message.seeAll
                                    )}
                                  </TextStyle>
                                  <ChevronDownIcon
                                    className={twJoin(
                                      'w-5',
                                      open && 'rotate-180'
                                    )}
                                  />
                                </Button>
                              </Disclosure.Button>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </div>
                  ) : null;
                })}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
