import {
  getTodayCalendarDate,
  type ResponseFrequencyType,
} from '@assembly-web/services';
import {
  Banner,
  DatePicker,
  type DateSegmentType,
  HorizontalRule,
  NumberInput,
  PromptWithChoiceBox,
  Select,
  TextField,
  TextStyle,
} from '@assembly-web/ui';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Root as AccordionRoot,
} from '@radix-ui/react-accordion';
import { getTimeZones, type TimeZone } from '@vvo/tzdb';
import { AnimatePresence, motion } from 'framer-motion';
import {
  type ElementRef,
  type ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetDeadlineType,
  useGetDueDate,
  useGetFlowBuilderState,
  useGetFlowResponseType,
  useGetNumberOfResponses,
  useGetRepeatFrequency,
  useGetResponseFrequencyTimeZone,
  useGetResponseFrequencyType,
  useGetResponseTimeUnit,
  useGetResponseTimeValue,
  useSetFlowBuilderState,
} from '../../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../hooks/useAnyOccurrenceInProgress';
import { useGetDeadlineTimeOptions } from '../../hooks/useGetDeadlineTimeOptions';
import { useFlowFrequencyOptions } from '../../hooks/useGetFlowFrequencyOptions';
import { useGetMilestonesOptions } from '../../hooks/useGetMilestonesOptions';
import { useGetTimeOptions } from '../../hooks/useGetTimeOptions';
import { defaultReminderTime } from '../../utils/distribution';
import {
  isDeadlineUnitOption,
  isFrequencyOption,
  isMilestoneOption,
  isResponseFrequencyType,
  isTimeOption,
} from '../../utils/typeGuards';
import { EndDateModal } from './EndDateModal';

type ResponseFrequencyChoicesType = {
  label: string;
  description?: string;
  id: ResponseFrequencyType;
};

const MinimumDeadlineTime = 5;
const MaximumDeadlineTime = 131400;

const animationProps = {
  transition: { duration: 0.3 },
  exit: { opacity: 0, height: 0 },
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
};

const messages = defineMessages({
  responseFrequencyPromptLabel: {
    defaultMessage: 'How often do you want them to post?',
    id: 'xd2i9v',
  },
  onRecurringBasisLabel: {
    defaultMessage: 'On a recurring basis',
    id: '+nwyNd',
  },
  onRecurringBasisDescription: {
    defaultMessage: 'Ex. Once a week, on Fridays',
    id: '5UhSSx',
  },
  onRecurringBasisDescriptionForMilestone: {
    defaultMessage: 'Starting on or after start date',
    id: 'hw9SgO',
  },
  justOnceLabel: {
    defaultMessage: 'Just once',
    id: 'hGd/5e',
  },
  justOnceDescription: {
    defaultMessage: 'Once on a choosen date',
    id: 'tL/LiY',
  },
  justOnceDescriptionForMilestone: {
    defaultMessage: 'Once on or after start date',
    id: 'TfUOci',
  },
  scheduleSettingsLabel: {
    defaultMessage: 'Settings: Scheduling your flow',
    id: 'MJYwox',
  },
  dueDateLabel: {
    defaultMessage: 'Set your first deadline',
    id: 'zJiUoi',
  },
  dueDateForJustOnceLabel: {
    defaultMessage: 'Set your deadline',
    id: '+MyJU1',
  },
  repeatLabel: {
    defaultMessage: 'repeat',
    id: 'dmsZyG',
  },
  stopRepeatLabel: {
    defaultMessage: 'Want this form to stop repeating?',
    id: 'JSpCtN',
  },
  repeatUntilLabel: {
    defaultMessage: 'This form will repeat until {endDate}',
    id: 'qq0/a1',
  },
  setEndDateLabel: {
    defaultMessage: 'Set an end date',
    id: 'HO09oL',
  },
  editOrRemoveEndDateLabel: {
    defaultMessage: 'Edit or remove end date',
    id: '65CTSG',
  },
  atWhatTimeLabel: {
    defaultMessage: 'At what time?',
    id: '2c6c8y',
  },
  chooseTimeZoneLabel: {
    defaultMessage: 'Choose a time zone',
    id: 'neoj+d',
  },
  ownTimeZoneLabel: {
    defaultMessage: `In each person's own time zone`,
    id: 'XFZysL',
  },
  howLongPeopleCanRespondLabel: {
    defaultMessage: 'Amount of time to post before the deadline',
    id: 'vLvx0s',
  },
  deadlineMinutesTooShort: {
    defaultMessage: 'Time should be greater than five minutes',
    id: 'Ek3JMG',
  },
  deadlineMinutesTooLong: {
    defaultMessage: 'Time should be less than 12 weeks',
    id: 'hioZIj',
  },
  dueDateTimesLabel: {
    defaultMessage: 'Number of times people can post before the deadline',
    id: '142dHh',
  },
  settingsSubTitleLabel: {
    defaultMessage: 'Due {dueDate} at {dueTime}, {respondTime} to respond',
    id: 'FqAUUx',
  },
  notificationMessageRecurring: {
    defaultMessage:
      "We're going to notify participants to post in this flow {frequency} {time} {timeZone}",
    id: 'BouxNJ',
  },
  notificationMessageOneTime: {
    defaultMessage:
      "We're going to notify participants to post in this flow by {frequency} {time} {timeZone}",
    id: 'bZSie5',
  },
  notifyingParticipantsLabel: {
    defaultMessage: 'Notifying participants',
    id: 'cVH7pj',
  },
  chooseMilestoneLabel: {
    defaultMessage: 'Choose the milestone when you want people to post',
    id: 'EkIo2x',
  },
  formSentOutLabel: {
    defaultMessage: 'The form should be sent out',
    id: 'Oy4R5k',
  },
  flowRecurrenceLabel: {
    defaultMessage: 'How many times should this flow occur?',
    id: 'kFKbuW',
  },
  day: {
    id: 'xcWsw2',
    defaultMessage: '{count, plural, one {# day} other {# days}}',
  },
  hour: {
    id: '3vcLDd',
    defaultMessage: '{count, plural, one {# hour} other {# hours}}',
  },
  minute: {
    id: 'eN3B1l',
    defaultMessage: '{count, plural, one {# minute} other {# minutes}}',
  },
  week: {
    id: 'WCkIa+',
    defaultMessage: '{count, plural, one {# week} other {# weeks}}',
  },
});

function CustomDateSegment(
  { segment, onButtonClick }: DateSegmentType & { onButtonClick?: () => void },
  options?: { locale: string }
) {
  const formatSegment = useCallback(() => {
    switch (segment.type) {
      case 'month':
        return new Date(2000, parseInt(segment.text) - 1, 1).toLocaleString(
          options?.locale ?? 'en-US',
          { month: 'long' }
        );
      case 'day':
        return `${parseInt(segment.text)},`;
      case 'year':
        return segment.text;
      default:
        return '';
    }
  }, [segment.text, segment.type, options?.locale]);

  return (
    <TextStyle variant="sm-regular" className="mr-0.5 text-gray-9">
      <button onClick={onButtonClick}>{formatSegment()}</button>
    </TextStyle>
  );
}

function Root({ children }: { children: ReactNode }) {
  return (
    <AccordionRoot
      collapsible
      type="single"
      defaultValue="schedule"
      className="my-4 w-full rounded-md bg-gray-2 p-4"
    >
      {children}
    </AccordionRoot>
  );
}

function Row({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const dueDate = useGetDueDate(id);
  const responseTimeUnit = useGetResponseTimeUnit(id);
  const responseTimeValue = useGetResponseTimeValue(id);
  const dueTime = useGetFlowBuilderState(id, 'dueTime');

  function formatResponseTime(
    value: number,
    unit: 'days' | 'hours' | 'minutes' | 'weeks'
  ) {
    const unitMessages = {
      days: messages.day,
      hours: messages.hour,
      weeks: messages.week,
      minutes: messages.minute,
    };

    return formatMessage(unitMessages[unit], { count: value });
  }

  return (
    <AccordionItem value="schedule" defaultValue="schedule">
      <AccordionTrigger className="group flex w-full items-center gap-2 data-[state=open]:border-gray-5">
        <section className="flex w-full items-start gap-2 p-2">
          <ChevronDownIcon className="mt-1 h-4 w-4 stroke-current text-gray-9 will-change-transform group-data-[state=open]:rotate-180" />
          <section className="flex flex-col items-start lg:flex-row lg:gap-2">
            <TextStyle variant="base-medium">
              {formatMessage(messages.scheduleSettingsLabel)}
            </TextStyle>
            <TextStyle variant="sm-regular" className="text-gray-9">
              {formatMessage(messages.settingsSubTitleLabel, {
                dueDate: dueDate.toDate('UTC').toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }),
                dueTime: dueTime || defaultReminderTime,
                respondTime: formatResponseTime(
                  responseTimeValue,
                  responseTimeUnit as 'days' | 'hours' | 'minutes'
                ),
              })}
            </TextStyle>
          </section>
        </section>
        <CheckCircleIcon className="h-4 w-4 text-success-7" />
      </AccordionTrigger>
      <AccordionContent className="px-2">{children}</AccordionContent>
    </AccordionItem>
  );
}

function DueDateSettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const dueDate = useGetDueDate(id);

  const repeatFrequency = useGetRepeatFrequency(id);
  const endDate = useGetFlowBuilderState(id, 'endDate');
  const responseFrequency = useGetResponseFrequencyType(id);
  const flowResponseType = useGetFlowBuilderState(id, 'flowResponseType');

  const setDueDate = useSetFlowBuilderState(id, 'dueDate');
  const setRepeatFrequency = useSetFlowBuilderState(id, 'repeatFrequency');

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isEndDateModalOpen, setIsEndDateModalOpen] = useState(false);

  const todayDate = useMemo(() => getTodayCalendarDate(), []);
  const frequencyOptions = useFlowFrequencyOptions(dueDate.toDate('UTC'));

  const frequencySettingsContainerRef = useRef<ElementRef<'section'>>(null);

  const showRepeatOption = responseFrequency === 'recurring';
  const selectedRepeatFrequency = frequencyOptions.find(
    (option) => option.id === repeatFrequency
  );

  const handleDateSegmentClick = useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);

  if (flowResponseType === 'milestone') {
    return null;
  }

  return (
    <section
      className="flex flex-col gap-1"
      ref={frequencySettingsContainerRef}
    >
      <div className="flex w-full items-end gap-2">
        <DatePicker
          minValue={todayDate}
          focusedValue={dueDate}
          selectedValue={dueDate}
          isOpen={isDatePickerOpen}
          isLoading={isOccurrenceInProgress}
          onOpenChange={setIsDatePickerOpen}
          className={showRepeatOption ? 'w-auto' : 'w-full'}
          portalContainer={
            frequencySettingsContainerRef.current === null
              ? undefined
              : frequencySettingsContainerRef.current
          }
          onFocusedValueChange={setDueDate}
          onSelectedValueChange={setDueDate}
          shouldCloseOnSelect={true}
          DateSegment={(props) =>
            CustomDateSegment({
              ...props,
              onButtonClick: handleDateSegmentClick,
            })
          }
          label={
            showRepeatOption
              ? formatMessage(messages.dueDateLabel)
              : formatMessage(messages.dueDateForJustOnceLabel)
          }
        />
        {Boolean(showRepeatOption) && (
          <>
            <TextStyle variant="sm-regular" className="mb-2 text-gray-9">
              {formatMessage(messages.repeatLabel)}
            </TextStyle>
            <Select
              label=""
              name="repeat"
              classNames="w-full"
              options={frequencyOptions}
              disabled={isOccurrenceInProgress}
              selected={selectedRepeatFrequency}
              onChange={(option: unknown) => {
                if (isFrequencyOption(option)) {
                  setRepeatFrequency(option.id);
                }
              }}
              getValue={(option) =>
                isFrequencyOption(option) ? option.id : ''
              }
              getText={(option) =>
                isFrequencyOption(option) ? option.label : ''
              }
            />
          </>
        )}
      </div>
      {Boolean(showRepeatOption) && (
        <div className="flex gap-1">
          <TextStyle variant="xs-regular" className="text-gray-8">
            {formatMessage(
              endDate ? messages.repeatUntilLabel : messages.stopRepeatLabel,
              {
                endDate: endDate?.toDate('UTC').toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }),
              }
            )}
          </TextStyle>
          <button
            onClick={() => setIsEndDateModalOpen(true)}
            disabled={isOccurrenceInProgress}
          >
            <TextStyle variant="xs-regular" className="text-gray-8 underline">
              {formatMessage(
                endDate
                  ? messages.editOrRemoveEndDateLabel
                  : messages.setEndDateLabel
              )}
            </TextStyle>
          </button>
        </div>
      )}
      <EndDateModal
        minValue={dueDate}
        isOpen={isEndDateModalOpen}
        onClose={() => {
          setIsEndDateModalOpen(false);
        }}
      />
    </section>
  );
}

function TimeSettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const timeZone = useGetResponseFrequencyTimeZone(id);
  const dueTime = useGetFlowBuilderState(id, 'dueTime');
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const setDueTime = useSetFlowBuilderState(id, 'dueTime');
  const setTimeZone = useSetFlowBuilderState(id, 'responseFrequencyTimeZone');

  const frequencyTimeOptions = useGetTimeOptions();

  const timeZonesWithUtc = useMemo(() => {
    const sortedTimeZones = getTimeZones().sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return [
      {
        ...sortedTimeZones[0],
        name: '',
        alternativeName: formatMessage(messages.ownTimeZoneLabel),
      },
      ...sortedTimeZones,
    ];
  }, [formatMessage]);

  const selectedFrequencyTime =
    frequencyTimeOptions.find((option) => option.id === dueTime) ??
    frequencyTimeOptions[0];

  const selectedTimeZone = timeZonesWithUtc.find(
    (option) => option.name === timeZone
  );

  return (
    <div className="flex gap-4">
      <Select
        name="timeSettings"
        classNames="w-full"
        options={frequencyTimeOptions}
        selected={selectedFrequencyTime}
        disabled={isOccurrenceInProgress}
        label={formatMessage(messages.atWhatTimeLabel)}
        onChange={(option: unknown) => {
          if (isTimeOption(option)) {
            setDueTime(option.id);
          }
        }}
        getValue={(option) => (isTimeOption(option) ? option.id : '')}
        getText={(option) => (isTimeOption(option) ? option.label : '')}
      />
      <Select
        name="timeSettings"
        classNames="w-full"
        options={timeZonesWithUtc}
        selected={selectedTimeZone}
        disabled={isOccurrenceInProgress}
        label={formatMessage(messages.chooseTimeZoneLabel)}
        onChange={(option: unknown) => {
          const value = option as TimeZone;
          setTimeZone(value.name);
        }}
        getValue={(option) => {
          const value = option as TimeZone;
          return value.name;
        }}
        getText={(option) => {
          const value = option as TimeZone;
          if (value.name === '') {
            return value.alternativeName;
          }

          return `${value.alternativeName} - ${value.countryName}`;
        }}
      />
    </div>
  );
}

function DeadlineSettings() {
  const { id } = useEditorDataContext();
  const { formatMessage } = useIntl();

  const responseTimeUnit = useGetResponseTimeUnit(id);
  const responseTimeValue = useGetResponseTimeValue(id);
  const numberOfResponses = useGetNumberOfResponses(id);
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const setResponseTimeUnit = useSetFlowBuilderState(id, 'responseTimeUnit');
  const setResponseTimeValue = useSetFlowBuilderState(id, 'responseTimeValue');
  const setNumberOfResponses = useSetFlowBuilderState(id, 'numberOfResponses');

  const deadlineDayOptions = useGetDeadlineTimeOptions();

  const [selectedResponseTimeUnit, setSelectedResponseTimeUnit] = useState(
    deadlineDayOptions[0]
  );

  const deadLineMinutes = useMemo(() => {
    switch (responseTimeUnit) {
      case 'minutes':
        return responseTimeValue;
      case 'hours':
        return responseTimeValue * 60;
      case 'days':
        return responseTimeValue * 24 * 60;
      case 'weeks':
        return responseTimeValue * 7 * 24 * 60;
      default:
        throw new Error('Invalid time unit');
    }
  }, [responseTimeValue, responseTimeUnit]);

  return (
    <>
      <div className="flex flex-col gap-1">
        <TextStyle variant="sm-medium" className="text-gray-9">
          {formatMessage(messages.howLongPeopleCanRespondLabel)}
        </TextStyle>
        <div className="flex items-end gap-4">
          <NumberInput
            minValue={1}
            className="w-full"
            dynamicWidth={false}
            value={responseTimeValue}
            isDisabled={isOccurrenceInProgress}
            onChange={(value) => {
              setResponseTimeValue(value);
              const unit = deadlineDayOptions.find(
                (option) => option.id === responseTimeUnit
              );
              if (unit) {
                setSelectedResponseTimeUnit({ ...unit });
              }
            }}
            label={formatMessage(messages.howLongPeopleCanRespondLabel)}
          />
          <Select
            label=""
            classNames="w-full"
            name="responseDurationUnit"
            options={deadlineDayOptions}
            disabled={isOccurrenceInProgress}
            selected={selectedResponseTimeUnit}
            onChange={(option: unknown) => {
              if (isDeadlineUnitOption(option)) {
                setResponseTimeUnit(option.id);
              }
            }}
            getValue={(option) =>
              isDeadlineUnitOption(option) ? option.id : ''
            }
            getText={(option) => {
              if (isDeadlineUnitOption(option)) {
                return (
                  deadlineDayOptions.find((x) => x.id === option.id)?.label ??
                  ''
                );
              }
              return '';
            }}
          />
        </div>
      </div>
      {(deadLineMinutes < MinimumDeadlineTime ||
        deadLineMinutes > MaximumDeadlineTime) && (
        <TextStyle variant="sm-regular" className="-mt-2 text-error-6">
          {deadLineMinutes < MinimumDeadlineTime
            ? formatMessage(messages.deadlineMinutesTooShort)
            : formatMessage(messages.deadlineMinutesTooLong)}
        </TextStyle>
      )}
      <div className="flex flex-col gap-1">
        <TextStyle variant="sm-medium" className="text-gray-9">
          {formatMessage(messages.dueDateTimesLabel)}
        </TextStyle>
        <NumberInput
          minValue={1}
          className="w-full"
          dynamicWidth={false}
          value={numberOfResponses}
          isDisabled={isOccurrenceInProgress}
          onChange={(value) => {
            setNumberOfResponses(value);
          }}
          label={formatMessage(messages.dueDateTimesLabel)}
        />
      </div>
    </>
  );
}

function Summary() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const dueDate = useGetDueDate(id);
  const repeatFrequency = useGetRepeatFrequency(id);
  const dueTime = useGetFlowBuilderState(id, 'dueTime');
  const responseFrequencyType = useGetResponseFrequencyType(id);
  const responseFrequencyTimeZone = useGetResponseFrequencyTimeZone(id);

  const frequencyTimeOptions = useGetTimeOptions();
  const frequencyOptions = useFlowFrequencyOptions(dueDate.toDate('UTC'));

  const timeZonesWithUtc = getTimeZones({ includeUtc: true });

  const selectedRepeatFrequency = frequencyOptions.find(
    (option) => option.id === repeatFrequency
  );

  const selectedRepeatTimeFrequency =
    frequencyTimeOptions.find((option) => option.id === dueTime) ??
    frequencyTimeOptions[0];

  const selectedResponseFrequencyTimeZone = timeZonesWithUtc.find(
    (option) => option.name === responseFrequencyTimeZone
  );

  return (
    <div className="mt-3 flex flex-col gap-2">
      <TextStyle variant="sm-medium" className="text-gray-9">
        {formatMessage(messages.notifyingParticipantsLabel)}
      </TextStyle>
      <Banner status="info" className="rounded-lg">
        <TextStyle className="text-primary-9" variant="xs-regular">
          {formatMessage(
            responseFrequencyType === 'recurring'
              ? messages.notificationMessageRecurring
              : messages.notificationMessageOneTime,
            {
              frequency:
                responseFrequencyType === 'recurring' ? (
                  <b>{selectedRepeatFrequency?.label}</b>
                ) : (
                  <b>
                    {dueDate.toDate('UTC').toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </b>
                ),
              time: <b>{selectedRepeatTimeFrequency.label}</b>,
              timeZone: selectedResponseFrequencyTimeZone?.abbreviation,
            }
          )}
        </TextStyle>
      </Banner>
    </div>
  );
}

function MilestoneSettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const responseFrequencyType = useGetResponseFrequencyType(id);
  const flowResponseType = useGetFlowBuilderState(id, 'flowResponseType');

  const { milestoneOptions, frequencyOptions } = useGetMilestonesOptions();

  const [selectedMilestone, setSelectedMilestone] = useState<string>(
    milestoneOptions[0].id
  );

  const [selectedFrequency, setSelectedFrequency] = useState<string>(
    frequencyOptions[0].id
  );

  const [numberOfResponses, setNumberOfResponses] = useState(1);

  if (flowResponseType !== 'milestone') {
    return null;
  }

  const selectedMilestoneOption = milestoneOptions.find(
    (option) => option.id === selectedMilestone
  );

  const selectedFrequencyOption = frequencyOptions.find(
    (option) => option.id === selectedFrequency
  );

  if (responseFrequencyType === 'recurring') {
    return (
      <div className="flex flex-col gap-4">
        <Select
          name="milestones"
          classNames="w-full"
          options={milestoneOptions}
          disabled={isOccurrenceInProgress}
          selected={selectedMilestoneOption}
          label={formatMessage(messages.chooseMilestoneLabel)}
          onChange={(option: unknown) => {
            if (isMilestoneOption(option)) {
              setSelectedMilestone(option.id);
            }
          }}
          getValue={(option) => (isMilestoneOption(option) ? option.id : '')}
          getText={(option) => (isMilestoneOption(option) ? option.label : '')}
        />
        <Select
          name="frequency"
          classNames="w-full"
          options={frequencyOptions}
          disabled={isOccurrenceInProgress}
          selected={selectedFrequencyOption}
          label={formatMessage(messages.formSentOutLabel)}
          onChange={(option: unknown) => {
            if (isMilestoneOption(option)) {
              setSelectedFrequency(option.id);
            }
          }}
          getValue={(option) => (isMilestoneOption(option) ? option.id : '')}
          getText={(option) => (isMilestoneOption(option) ? option.label : '')}
        />
        <div className="flex flex-col gap-1">
          <TextStyle variant="sm-medium" className="text-gray-9">
            {formatMessage(messages.flowRecurrenceLabel)}
          </TextStyle>
          <TextField
            type="number"
            value={numberOfResponses}
            disabled={isOccurrenceInProgress}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              setNumberOfResponses(value);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <Select
        name="milestones"
        classNames="w-full"
        options={milestoneOptions}
        disabled={isOccurrenceInProgress}
        selected={selectedMilestoneOption}
        label={formatMessage(messages.chooseMilestoneLabel)}
        onChange={(option: unknown) => {
          if (isMilestoneOption(option)) {
            setSelectedMilestone(option.id);
          }
        }}
        getValue={(option) => (isMilestoneOption(option) ? option.id : '')}
        getText={(option) => (isMilestoneOption(option) ? option.label : '')}
      />
    </div>
  );
}

function Settings({ children }: { children: ReactNode }) {
  return (
    <section className="flex max-w-2xl flex-col gap-3">{children}</section>
  );
}

const ResponseSchedule = {
  Row,
  Root,
  Summary,
  Settings,
  TimeSettings,
  DueDateSettings,
  DeadlineSettings,
  MilestoneSettings,
};

export function ResponseFrequencySettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const deadlineType = useGetDeadlineType(id);
  const flowResponseType = useGetFlowResponseType(id);
  const responseFrequencyType = useGetResponseFrequencyType(id);

  const setResponseFrequencyType = useSetFlowBuilderState(
    id,
    'responseFrequencyType'
  );

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleValueChange = useCallback(
    (value: string) => {
      if (isResponseFrequencyType(value)) {
        setResponseFrequencyType(value);
      }
    },
    [setResponseFrequencyType]
  );

  const choices: ResponseFrequencyChoicesType[] = useMemo(
    () => [
      {
        id: 'recurring',
        label: formatMessage(messages.onRecurringBasisLabel),
        description:
          flowResponseType === 'milestone'
            ? formatMessage(messages.onRecurringBasisDescriptionForMilestone)
            : formatMessage(messages.onRecurringBasisDescription),
      },
      {
        id: 'once',
        label: formatMessage(messages.justOnceLabel),
        description:
          flowResponseType === 'milestone'
            ? formatMessage(messages.justOnceDescriptionForMilestone)
            : formatMessage(messages.justOnceDescription),
      },
    ],
    [formatMessage, flowResponseType]
  );

  if (flowResponseType === 'anytime') {
    return null;
  }

  if (flowResponseType === 'deadline' && deadlineType === 'manual') {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div {...animationProps}>
        <PromptWithChoiceBox.Root>
          <PromptWithChoiceBox.Row>
            <PromptWithChoiceBox.Prompt>
              {formatMessage(messages.responseFrequencyPromptLabel)}
            </PromptWithChoiceBox.Prompt>
            <PromptWithChoiceBox.Choices
              choices={choices}
              onValueChange={handleValueChange}
              disabled={isOccurrenceInProgress}
              value={responseFrequencyType}
            />
          </PromptWithChoiceBox.Row>
        </PromptWithChoiceBox.Root>
        <ResponseSchedule.Root>
          <ResponseSchedule.Row>
            <ResponseSchedule.Settings>
              <ResponseSchedule.MilestoneSettings />
              <ResponseSchedule.DueDateSettings />
              <ResponseSchedule.TimeSettings />
              <ResponseSchedule.DeadlineSettings />
            </ResponseSchedule.Settings>
            <ResponseSchedule.Summary />
          </ResponseSchedule.Row>
        </ResponseSchedule.Root>
      </motion.div>
      <HorizontalRule className="-ml-8 -mr-8" />
    </AnimatePresence>
  );
}
