import { assemblyLogo } from '@assembly-web/assets';
import {
  browserUtils,
  routeConstants,
  SplitNames,
  useFeatureSplit,
  userAuthStore,
  useThemeStyle,
  useUserDetails,
} from '@assembly-web/services';
import {
  AutocompleteSearchField,
  Button,
  GlobalFilterOption,
  HorizontalRule,
  IconButton,
  Tooltip,
  useAssemblyNavigate,
  useDevice,
  useDeviceInfo,
} from '@assembly-web/ui';
import {
  Bars3Icon,
  BookOpenIcon,
  MagnifyingGlassIcon,
  PuzzlePieceIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import type { Key, ReactNode } from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import useResizeObserver from 'use-resize-observer';

import { trackAppConnectionAction } from '../../connections/services/analytics';
import { AskDoraContext } from '../contexts/AskDoraContext';
import { DiscoverPageFiltersAggregationContext } from '../hooks/DiscoverFiltersProvider';
import { useNavData } from '../hooks/nav/useNavData';
import { useNavStore } from '../hooks/nav/useNavStore';
import { useAppFilter } from '../hooks/useAppFilters';
import { useDiscoverSearchOptions } from '../hooks/useDiscoverSearchOptions';
import { useGlobalFilter } from '../hooks/useGlobalFilter';
import { useIsFullScreenLegacyPage } from '../hooks/useIsFullScreenLegacyPage';
import { useMobileMessageBridge } from '../hooks/useMobileMessageBridge';
import {
  getSortMethodValue,
  trackDiscoverAction,
  trackNavShow,
} from '../services/analytics';
import { isAdminMember } from '../services/member';
import { recentSearchesStore } from '../stores/recentSearchesStore';
import { useFeedSortStore } from '../stores/useFeedSortStore';
import { DiscoverPageFilterSection } from './DiscoverPageFilterSection';
import { useCreateAskDoraDrawer } from './Drawers/AskDoraDrawer/hooks/useCreateAskDoraDrawer';
import type { DoraResponseMetadata } from './Drawers/AskDoraDrawer/hooks/useDoraEventSource';
import { AddItemsToCollectionViaSearchMsgKey } from './Nav/NavContainer';
import { useNavTrigger } from './Nav/useNavTrigger';
import { Rewards } from './Rewards';
import { UserMenu } from './UserMenu';

export const discoverPageHeaderMessages = defineMessages({
  clearBtn: {
    defaultMessage: 'Clear',
    id: '/GCoTA',
  },
  searchPlaceholderForAddingToCollection: {
    defaultMessage:
      'To add to this collection, search for what you want go into the 3 dotted menu.',
    id: 'caghRp',
  },
  searchPlaceholder: {
    defaultMessage: 'What do you want to find or do?',
    id: 'oMLMPu',
  },
  searchByShortcutPlaceholder: {
    defaultMessage: 'Start your search',
    id: '1K006E',
  },
  searchLabel: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
  exploreTemplatesCTA: {
    defaultMessage: 'Explore templates',
    id: '1ODiI7',
  },
  introJSSearchBarTitle: {
    defaultMessage: "Find what you're looking for",
    id: 'J0jjZl',
  },
  introJSSearchBarMsg: {
    defaultMessage:
      'Search for relevant flows, posts, replies, tasks, files, people, and more.',
    id: 'tT774e',
  },
  connectAppsCTA: {
    defaultMessage: 'Connect apps',
    id: 'wKK2GT',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  searchShortcutMac: {
    defaultMessage: '<kbd>⌘ cmd</kbd><kbd>shift</kbd><kbd>space</kbd>',
    id: 'oWX6F1',
  },
  searchShortcutNonMac: {
    defaultMessage: '<kbd>ctrl</kbd><kbd>shift</kbd><kbd>space</kbd>',
    id: 'VL56SZ',
  },
});

const keyClassName =
  'shrink-0 rounded bg-gray-3 px-1 py-0.5 font-sans text-sm text-gray-8 shadow-none';

export function DiscoverPageHeader(props: { filter: GlobalFilterOption }) {
  const { filter } = props;
  const { data: userDetails } = useUserDetails();
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();
  const { pathname } = useLocation();
  const { totalNoOfPinnedCollections, totalSoftPinnedCollections } =
    useNavData();
  const { getSortType } = useFeedSortStore();
  const sortType = getSortType(filter);

  const onDiscoverPageFilterChange = useAppFilter();
  const { aggregations } = useContext(DiscoverPageFiltersAggregationContext);

  const { isBottomNav } = useNavTrigger();
  const isNavPinned = useNavStore((state) => state.isPinned);
  const pinNav = useNavStore((state) => state.pinNav);
  const showNav = useNavStore((state) => state.showNav);
  const hideNav = useNavStore((state) => state.hideNav);
  const unpinNav = useNavStore((state) => state.unpinNav);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isSearchInFocus, setIsSearchInFocus] = useState(false);
  const [inSearchFlow, setInSearchFlow] = useState(false);
  const isMobileAppV3 = Boolean(userAuthStore.getState().isMobileAppV3);

  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  const isMobileSearchExperience = isMobileView && inSearchFlow;

  const isLegacyPagePath = routeConstants.legacyExperiencePaths.some((path) =>
    pathname.includes(path)
  );

  const isFocusOnMountRef = useRef(false);

  const handleHeaderClick = useCallback(() => {
    navigate('/discover');
    setSearchTerm('');

    /**
     * Rule: Clicking the brand logo (if you are viewing an embedded page/not the v3 feed) should take you back to the v3 feed
     *
     * Rule: Clicking the brand logo if you are scrolled down in the v3 feed, have a search and/or filter applied, should reset the feed and bring you
     * to the top of the feed with search and/or filters cleared/back to 'All'
     */
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [navigate]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('search') ?? ''
  );
  const newSearchParams = useMemo(
    () => new URLSearchParams(searchParams),
    [searchParams]
  );

  const currentGlobalFilter = useGlobalFilter();

  const handleSearchQueryUpdate = useCallback(
    (value: string, shouldStoreInHistory = true) => {
      if (pathname !== '/a/discover') {
        navigate(`/discover?search=${value}`);
      } else {
        if (
          currentGlobalFilter === GlobalFilterOption.Notifications &&
          value !== ''
        ) {
          newSearchParams.delete('filter');
        }
        setSearchParams({
          ...Object.fromEntries(newSearchParams),
          search: value,
        });
      }

      if (shouldStoreInHistory) {
        recentSearchesStore.getState().pushNewSearch(value, userDetails);
      }
    },
    [
      currentGlobalFilter,
      navigate,
      newSearchParams,
      pathname,
      setSearchParams,
      userDetails,
    ]
  );

  const updateQueryParams = useMemo(
    () => debounce(handleSearchQueryUpdate, 500),
    [handleSearchQueryUpdate]
  );

  const { isTreatmentActive: addMergeAppsEnabled } = useFeatureSplit(
    SplitNames.AddMergeApps
  );

  const canAddConnections =
    addMergeAppsEnabled && userDetails && isAdminMember(userDetails.member);

  const { isTreatmentActive: isAskDoraPopoutEnabled } = useFeatureSplit(
    SplitNames.AskDoraPopout
  );
  const { isTreatmentActive: isMSTeamsAppSubmissionEnabled } = useFeatureSplit(
    SplitNames.MSTeamsAppSubmission
  );
  const shouldShowMenu =
    isBottomNav &&
    (userAuthStore.getState().msTeamsContext
      ? !isMSTeamsAppSubmissionEnabled && isMobileView
      : true);

  const createAskDoraDrawer = useCreateAskDoraDrawer();

  const showDoraSuggestions =
    currentGlobalFilter !== GlobalFilterOption.Rewards;

  const {
    isAutocompleteDropdownOpen,
    setIsAutocompleteDropdownOpen,
    suggestions,
  } = useDiscoverSearchOptions({
    searchValue: searchTerm,
    userDetails,
    showDoraSuggestions,
  });

  const hotkey = browserUtils.isMac ? 'meta+shift+space' : 'ctrl+shift+space';
  useHotkeys(hotkey, () => searchInputRef.current?.focus());

  useEffect(() => {
    const hideAutoCompleteDropdown = () => {
      if (isMobileSearchExperience) {
        return;
      }

      setIsAutocompleteDropdownOpen(false);
    };

    window.addEventListener('scroll', hideAutoCompleteDropdown);

    return () => window.removeEventListener('scroll', hideAutoCompleteDropdown);
  }, [isMobileSearchExperience, setIsAutocompleteDropdownOpen]);

  const [showAddToCollectionPlaceholder, setShowAddToCollectionPlaceholder] =
    useState(false);

  useEffect(() => {
    function handleMessage({ data: { type } }: MessageEvent<{ type: string }>) {
      if (type === AddItemsToCollectionViaSearchMsgKey) {
        setShowAddToCollectionPlaceholder(true);
        searchInputRef.current?.focus();
      }
    }
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    let callbackId: number;
    if (
      !isFocusOnMountRef.current &&
      searchParams.get('focus_search_input') === 'true'
    ) {
      callbackId = window.requestIdleCallback(() =>
        searchInputRef.current?.focus()
      );
      isFocusOnMountRef.current = true;
    }

    return () => window.cancelIdleCallback(callbackId);
  }, [searchParams]);

  useMobileMessageBridge((data) => {
    if (data.type === 'focusSearchInput') {
      searchInputRef.current?.focus();
    }
  });

  const theme = useThemeStyle({
    theme: userDetails?.assembly.settings.theme,
    applyOpacity: false,
  });

  const {
    getDoraResponse,
    getPrevQuestionsAsked,
    resetContext: resetDoraContext,
    savePrevQuestionAsked,
  } = useContext(AskDoraContext);

  const handleAutoCompleteOptionSelect = (selectedOptionId: Key | null) => {
    const flattenedOptions = suggestions.flatMap((section) => section.options);

    const match = flattenedOptions.find(
      (option) => option.id === selectedOptionId
    );

    if (match) {
      handleSearchQueryUpdate(match.textValue, !match.isDefaultDora);

      if (match.textValue !== searchTerm) {
        resetDoraContext();
      }

      if (match.isRecent) {
        trackDiscoverAction('recentSearchClicked', {
          searchQuery: match.textValue,
        });
      }

      if (match.triggerDora) {
        const defaultSuggestionClicked = selectedOptionId === 'suggested';

        const metadata: DoraResponseMetadata = {
          documentId: match.documentId,
          isAdmin: userDetails?.member
            ? isAdminMember(userDetails.member)
            : false,
          shouldUseStaticResponse: defaultSuggestionClicked,
          suggestedQuestionActionType: !defaultSuggestionClicked
            ? 'suggestedPromptClicked'
            : undefined,
          suggestedQuestionsHistory: getPrevQuestionsAsked(),
        };

        if (isAskDoraPopoutEnabled) {
          createAskDoraDrawer({ question: match.textValue, metadata });
        } else {
          savePrevQuestionAsked(match.textValue);
          getDoraResponse(match.textValue, metadata);
        }
      }

      setSearchTerm(match.textValue);
      setIsAutocompleteDropdownOpen(false);
      setInSearchFlow(false);
      searchInputRef.current?.blur();
    }
  };

  function clearSearch() {
    setSearchTerm('');
    updateQueryParams('');
    sessionStorage.setItem('discoverPageURL', '/a/discover');
    if (pathname !== '/a/discover') {
      navigate(`/discover`);
    }
  }

  const onMenuClick = useCallback(() => {
    if (isNavPinned) {
      unpinNav();
      hideNav();
    } else {
      pinNav();
      trackNavShow({
        numSoftPinnedCollections: totalSoftPinnedCollections,
        numPinnedCollections: totalNoOfPinnedCollections,
      });
      showNav();
    }
  }, [
    hideNav,
    isNavPinned,
    pinNav,
    showNav,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
    unpinNav,
  ]);

  const searchWrapper = useRef<HTMLDivElement>(null);

  const deviceType = useDevice();

  useResizeObserver<HTMLDivElement>({
    ref: document.getElementById('assembly_app') as HTMLDivElement,
    onResize: () => {
      let leftPosition = null;
      const searchInputLeftPosition =
        searchWrapper.current?.getBoundingClientRect().left;

      if (
        deviceType !== 'xs' &&
        deviceType !== 'sm' &&
        searchInputLeftPosition
      ) {
        const leftAdjustment =
          deviceType === 'lg' || deviceType === 'xl' ? 20 : 0;
        leftPosition = `${searchInputLeftPosition - leftAdjustment}px`;
      }

      document
        .getElementById('global-filters')
        ?.style.setProperty('margin-left', leftPosition);
    },
  });

  const isFullScreenPage = useIsFullScreenLegacyPage();

  if (isFullScreenPage || (isMobileAppV3 && isLegacyPagePath)) {
    return null;
  }

  function onAutocompleteFocus() {
    trackDiscoverAction('searchStart', {
      sortMethod: getSortMethodValue(sortType),
      searchQuery: searchTerm,
      connectedTools: '',
      filterType: filter,
      time: new Date().toISOString(),
    });
    setIsAutocompleteDropdownOpen(true);
    setIsSearchInFocus(true);
    setInSearchFlow(true);
  }

  function onAutocompleteBlur() {
    trackDiscoverAction('searchEnd', {
      sortMethod: getSortMethodValue(sortType),
      searchQuery: searchTerm,
      connectedTools: '',
      filterType: filter,
      time: new Date().toISOString(),
    });
    setShowAddToCollectionPlaceholder(false);
    setIsSearchInFocus(false);
    if (isMobileSearchExperience) {
      return;
    }
    setIsAutocompleteDropdownOpen(false);
  }

  const searchPlaceholder = isMobileView
    ? formatMessage(discoverPageHeaderMessages.searchLabel)
    : showAddToCollectionPlaceholder
      ? formatMessage(
          discoverPageHeaderMessages.searchPlaceholderForAddingToCollection
        )
      : isSearchInFocus
        ? formatMessage(discoverPageHeaderMessages.searchPlaceholder)
        : formatMessage(discoverPageHeaderMessages.searchByShortcutPlaceholder);

  function onSearchClear() {
    searchInputRef.current?.focus();

    trackDiscoverAction('searchCleared', {
      sortMethod: getSortMethodValue(sortType),
      searchQuery: searchTerm,
      connectedTools: '',
      filterType: filter,
      time: new Date().toISOString(),
    });
    clearSearch();
  }

  return (
    <div className="sticky top-0 z-10 p-5 max-md:w-screen" style={theme}>
      <header className="flex flex-col rounded-lg border border-gray-5 bg-gray-1 shadow-base-down @container">
        <section className="flex w-full justify-between gap-2 px-4 py-3 md:gap-5">
          <section className="flex grow gap-x-4">
            {!isMobileAppV3 && (
              <>
                {shouldShowMenu ? (
                  <button
                    className="inline-flex cursor-pointer items-center justify-center p-2"
                    onClick={onMenuClick}
                  >
                    <Bars3Icon className="h-8 w-8" />
                  </button>
                ) : (
                  <button
                    className="inline-flex cursor-pointer space-x-2 xl:w-52"
                    onClick={handleHeaderClick}
                  >
                    <img
                      src={
                        userDetails?.assembly.logo
                          ? userDetails.assembly.logo
                          : assemblyLogo
                      }
                      alt=""
                      className="h-12 w-12"
                    />
                  </button>
                )}
              </>
            )}
            <div
              className={twMerge(
                'relative overflow-hidden [&:has(input:focus)]:overflow-visible',
                'md:m-auto md:px-4 lg:px-0',
                isMobileAppV3
                  ? 'w-full'
                  : 'md:w-[500px] lg:w-[620px] xl:w-[825px]',
                isMobileView &&
                  isMobileSearchExperience &&
                  'absolute inset-0 z-10 flex h-[72px] items-center gap-1 rounded-lg bg-gray-1 px-3 [&_[data-cancel-btn]]:block [&_[data-rac]]:flex-1'
              )}
              ref={searchWrapper}
              data-step={1}
              data-title={formatMessage(
                discoverPageHeaderMessages.introJSSearchBarTitle
              )}
              data-intro={formatMessage(
                discoverPageHeaderMessages.introJSSearchBarMsg
              )}
            >
              <AutocompleteSearchField
                ref={searchInputRef}
                inputSize="lg"
                label={formatMessage(discoverPageHeaderMessages.searchLabel)}
                hideLabel
                autoComplete="off"
                placeholder={searchPlaceholder}
                invalidText=""
                isInvalid={false}
                value={searchTerm}
                connectedLeft={
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-8" />
                }
                connectedRight={
                  Boolean(searchTerm) && (
                    <>
                      <div className="h-full w-16 bg-gradient-to-l from-gray-1 via-transparent"></div>
                      <button
                        id="search-clear-btn"
                        className="hover bg-gray-1 text-gray-9 underline hover:focus:text-gray-9 max-md:py-2 max-md:pl-2"
                        onClick={onSearchClear}
                      >
                        <span className="pl-2 max-md:hidden">
                          {formatMessage(discoverPageHeaderMessages.clearBtn)}
                        </span>
                        <span className="pointer-events-none touch-none max-md:block md:hidden">
                          <XMarkIcon className="h-4 w-4" />
                        </span>
                      </button>
                    </>
                  )
                }
                inputClassName="border-gray-5 text-ellipsis from-white to-transparent after:w-8 after:bg-gradient-to-l"
                popoverProps={{
                  className: twMerge(
                    'max-md:w-full max-md:h-full max-md:border-none max-md:shadow-none max-md:ring-opacity-0 max-md:overflow-hidden',
                    !isSearchInFocus && 'max-md:!max-h-[calc(100dvh-68px)]'
                  ),
                  lockBodyScroll: isMobileView,
                }}
                id="searchInput"
                isOpen={isAutocompleteDropdownOpen}
                suggestions={suggestions}
                onSelectionChange={handleAutoCompleteOptionSelect}
                onChange={(e) => {
                  setSearchTerm((e.target as HTMLInputElement).value);
                  if (!isMobileView) {
                    updateQueryParams((e.target as HTMLInputElement).value);
                  }
                  setIsAutocompleteDropdownOpen(true);
                  resetDoraContext();
                }}
                onFocus={onAutocompleteFocus}
                onBlur={onAutocompleteBlur}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    if (isMobileView) {
                      updateQueryParams(searchTerm);
                    }
                    setIsAutocompleteDropdownOpen(false);
                    setInSearchFlow(false);
                    searchInputRef.current?.blur();
                  }
                }}
              />
              {!isMobileView && (
                <span
                  className={twMerge(
                    'pointer-events-none absolute left-[180px] top-0 flex h-12 items-center gap-1',
                    (isSearchInFocus ||
                      searchTerm ||
                      showAddToCollectionPlaceholder) &&
                      'hidden'
                  )}
                >
                  {formatMessage(
                    browserUtils.isMac
                      ? discoverPageHeaderMessages.searchShortcutMac
                      : discoverPageHeaderMessages.searchShortcutNonMac,
                    {
                      kbd: (text: ReactNode) => (
                        <kbd className={keyClassName}>{text}</kbd>
                      ),
                    }
                  )}
                </span>
              )}
              {Boolean(isMobileView) && (
                <Button
                  data-cancel-btn
                  variation="tertiaryLite"
                  size="large"
                  className="hidden"
                  onClick={() => {
                    clearSearch();
                    setInSearchFlow(false);
                    setIsAutocompleteDropdownOpen(false);
                  }}
                >
                  {formatMessage(discoverPageHeaderMessages.cancelButton)}
                </Button>
              )}
            </div>
          </section>

          <div className="flex w-fit items-center">
            <div
              className={twMerge(
                'flex gap-1',
                !canAddConnections && 'flex-row-reverse'
              )}
            >
              {userDetails?.member.permissions.canCreateFlow ? (
                <IconButton
                  onClick={() => navigate('/templates')}
                  variation="tertiaryLite"
                  className="hidden lg:block"
                >
                  <Tooltip
                    tooltipText={formatMessage(
                      discoverPageHeaderMessages.exploreTemplatesCTA
                    )}
                    side="bottom"
                    contentClassName="p-2 z-[11]"
                  >
                    <BookOpenIcon className="h-full w-full rounded text-gray-8 hover:bg-gray-3" />
                  </Tooltip>
                </IconButton>
              ) : (
                <div className="h-10 w-10 p-[7px]" />
              )}

              {canAddConnections ? (
                <IconButton
                  onClick={() => {
                    navigate('/connections');
                    trackAppConnectionAction('manageAppsOpened', {
                      filterType: filter,
                    });
                  }}
                  variation="tertiaryLite"
                  className="hidden lg:block"
                >
                  <Tooltip
                    tooltipText={formatMessage(
                      discoverPageHeaderMessages.connectAppsCTA
                    )}
                    side="bottom"
                    contentClassName="p-2 z-[11]"
                  >
                    <PuzzlePieceIcon className="h-full w-full rounded text-gray-8 hover:bg-gray-3" />
                  </Tooltip>
                </IconButton>
              ) : (
                <div className="h-10 w-10 p-[7px]" />
              )}
            </div>

            {!isMobileAppV3 && (
              <>
                <div
                  className={twMerge(
                    'mx-3 hidden h-8 w-[1px] rounded lg:block',
                    userDetails?.member.permissions.canCreateFlow ||
                      canAddConnections
                      ? 'bg-gray-5'
                      : 'bg-gray-1'
                  )}
                />
                <UserMenu filter={filter} />
              </>
            )}
          </div>
        </section>
        {!isLegacyPagePath && Boolean(isMobileView) && (
          <HorizontalRule className="absolute top-[68px] w-full" />
        )}
        {!isLegacyPagePath && (
          <section className="flex w-full gap-x-4 pb-3 pt-1">
            <DiscoverPageFilterSection
              aggregations={searchTerm ? aggregations : undefined}
              filter={filter}
              onFilterChange={onDiscoverPageFilterChange}
            />
            <Rewards className="ml-auto hidden whitespace-nowrap pr-4 lg:block" />
          </section>
        )}
      </header>
    </div>
  );
}
