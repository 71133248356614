import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ActionBarQueryResponse } from '../queries/getActionBarQuery';
import { getActionBarQuery } from '../queries/getActionBarQuery';

export function useActionBarQuery(
  options?: Partial<UseQueryOptions<ActionBarQueryResponse>>
): UseQueryResult<ActionBarQueryResponse> {
  return useQuery(getActionBarQuery(options));
}
