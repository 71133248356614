import type { DeadlineType } from '@assembly-web/services';
import { HorizontalRule, PromptWithChoiceBox } from '@assembly-web/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetDeadlineType,
  useGetFlowResponseType,
  useSetFlowBuilderState,
} from '../../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../hooks/useAnyOccurrenceInProgress';
import { isDeadlineType } from '../../utils/typeGuards';

type DeadlineChoicesType = {
  label: string;
  id: DeadlineType;
  description?: string;
};

const animationProps = {
  transition: { duration: 0.3 },
  exit: { opacity: 0, height: 0 },
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
};

const messages = defineMessages({
  deadLineRespondToFlowLabel: {
    defaultMessage: 'How do you want to set the deadline?',
    id: 'A/0JEo',
  },
  scheduleItLabel: {
    defaultMessage: 'Schedule it',
    id: 'Pi+HE5',
  },
  scheduleItDescription: {
    defaultMessage: 'Automated on date and time',
    id: '4VIAvz',
  },
  setManuallyLabel: {
    defaultMessage: 'Set manually',
    id: 'bo78uA',
  },
  setManuallyDescription: {
    defaultMessage: `Configure when you're ready`,
    id: 'ce5QjD',
  },
});

export function DeadlineSettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const choices: DeadlineChoicesType[] = useMemo(
    () => [
      {
        id: 'schedule',
        label: formatMessage(messages.scheduleItLabel),
        description: formatMessage(messages.scheduleItDescription),
      },
      {
        id: 'manual',
        label: formatMessage(messages.setManuallyLabel),
        description: formatMessage(messages.setManuallyDescription),
      },
    ],
    [formatMessage]
  );

  const deadlineType = useGetDeadlineType(id);
  const flowResponseType = useGetFlowResponseType(id);

  const setDeadlineType = useSetFlowBuilderState(id, 'deadlineType');

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleValueChange = useCallback(
    (value: string) => {
      if (isDeadlineType(value)) {
        setDeadlineType(value);
      }
    },
    [setDeadlineType]
  );

  if (flowResponseType !== 'deadline') {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div {...animationProps}>
        <PromptWithChoiceBox.Root>
          <PromptWithChoiceBox.Row>
            <PromptWithChoiceBox.Prompt>
              {formatMessage(messages.deadLineRespondToFlowLabel)}
            </PromptWithChoiceBox.Prompt>
            <PromptWithChoiceBox.Choices
              choices={choices}
              value={deadlineType}
              disabled={isOccurrenceInProgress}
              onValueChange={handleValueChange}
            />
          </PromptWithChoiceBox.Row>
        </PromptWithChoiceBox.Root>
      </motion.div>
      <HorizontalRule className="-ml-8 -mr-8" />
    </AnimatePresence>
  );
}
