import { PusherProvider } from '@assembly-web/pusher';
import { config, getRoutePath, routeConstants } from '@assembly-web/services';
import { AssemblyLoadingIndicator } from '@assembly-web/ui';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  redirect,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { NotFoundPage } from './components/NotFoundPage';
import { connectionsLoader } from './modules/connections/loaders/connections';
import { ConnectionsRoute } from './modules/connections/pages/connections';
import { DiscoverRoot } from './modules/discover/DiscoverRoot';
import { DiscoverPageFiltersProvider } from './modules/discover/hooks/DiscoverFiltersProvider';
import { AnnouncementsProvider } from './modules/discover/hooks/useAnnouncements';
import { adminRoutesLoader } from './modules/discover/loaders/adminRoutesLoader';
import { authenticateSAMLLoader } from './modules/discover/loaders/authenticateSAMLLoader';
import { discoverPageLoader } from './modules/discover/loaders/discoverPageLoader';
import { externalParticipationLoader } from './modules/discover/loaders/externalParticipationLoader';
import { LegacyRoute } from './modules/discover/pages/legacy-path';
import { signOutLoader, SignOutRoute } from './modules/discover/pages/signout';
import { embedsLoader, EmbedsRoute } from './modules/embeds/pages/embeds';
import { OnboardingLayout } from './modules/onboarding/components/OnboardingLayout';
import {
  createAccountAction,
  createAccountLoader,
  CreateAccountRoute,
} from './modules/onboarding/pages/create-account';
import {
  joinWorkspaceSlugAction,
  joinWorkspaceSlugLoader,
  JoinWorkspaceSlugRoute,
} from './modules/onboarding/pages/join/$workspaceSlug';
import {
  accountInviteLoader,
  AccountInviteRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/account-invite/$inviteToken';
import {
  checkEmailAction,
  checkEmailLoader,
  CheckEmailRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/check-email';
import {
  userAccountCreationSuccessLoader,
  UserAccountCreationSuccessRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/success';
import {
  userDetailsAction,
  userDetailsLoader,
  UserDetailsRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/user-details';
import {
  joinWithProviderLoader,
  JoinWithProviderRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/validate/$provider';
import {
  loginAction,
  loginLoader,
  LoginRoute,
} from './modules/onboarding/pages/login';
import {
  loginProviderLoader,
  LoginProviderRoute,
} from './modules/onboarding/pages/login/$workspaceSlug/$provider';
import {
  MSTeamsAuth,
  msTeamsAuthLoader,
} from './modules/onboarding/pages/ms-teams/auth';
import {
  MSTeamsConfig,
  msTeamsConfigLoader,
} from './modules/onboarding/pages/ms-teams/config';
import {
  MSTeams,
  msTeamsAction,
  msTeamsLoader,
} from './modules/onboarding/pages/ms-teams/login';
import {
  ownerDetailsAction,
  ownerDetailsLoader,
  OwnerDetailsRoute,
} from './modules/onboarding/pages/onboarding/owner-details';
import { userDetailsLoader as userDetailsMagicLinkLoader } from './modules/onboarding/pages/onboarding/user-details';
import { WaitForAccountRoute } from './modules/onboarding/pages/onboarding/wait-for-account';
import {
  ssoAccountCreationLoader,
  SSOAccountCreationRoute,
} from './modules/onboarding/pages/sso-account-creation/$provider';
import {
  ssoLoginLoader,
  SSOLoginRoute,
} from './modules/onboarding/pages/sso-signin-in/$provider';
import {
  verifyEmailFlowAction,
  VerifyEmailFlowRoute,
} from './modules/onboarding/pages/verify-email/$flow';
import {
  workspacesAction,
  workspacesLoader,
  WorkspacesRoute,
} from './modules/onboarding/pages/workspaces';
import {
  TemplatesRoute,
  templatesRouteLoader,
} from './modules/templates/pages/templates';
import { Root } from './Root';
import { RootErrorBoundary } from './RootErrorBoundary';

const redirectPath = getRoutePath('', { directUrl: true });
const currentPath = window.location.pathname;
const redirectPathNameArray = redirectPath.split('/');
const currentPathNameArray = currentPath.split('/');

export function RouteDefinitions() {
  const queryClient = useQueryClient();

  const routeElementsWithoutPrefix = useMemo(
    () => (
      <Route
        path="/"
        loader={async () => {
          const searchParams = new URLSearchParams(window.location.search);
          if (window.location.pathname.endsWith('/discover')) {
            const urlWithParams = `/a/discover?${searchParams.toString()}`;
            return redirect(urlWithParams);
          }

          if (
            redirectPathNameArray.includes('a') &&
            !currentPathNameArray.includes('a')
          ) {
            let finalPath = redirectPath;
            if (searchParams.toString()) {
              finalPath = `${redirectPath}?${decodeURIComponent(
                searchParams.toString()
              )}`;
            }
            return redirect(finalPath);
          }
          return null;
        }}
      >
        <Route path="/admin/*" loader={adminRoutesLoader} />
        <Route path="/auth/saml" loader={authenticateSAMLLoader} />
        <Route path="/s/auth/saml" loader={authenticateSAMLLoader} />
        <Route
          key="external"
          path="e/flows/:flowId"
          loader={externalParticipationLoader}
        />
        <Route
          element={
            <Root>
              <OnboardingLayout>
                <Outlet />
              </OnboardingLayout>
            </Root>
          }
          errorElement={
            <OnboardingLayout shouldShowFooter={false}>
              <RootErrorBoundary />
            </OnboardingLayout>
          }
        >
          <Route index loader={() => redirect('/login')} />
          <Route path="/ms-teams">
            <Route
              path="login"
              element={<MSTeams />}
              action={msTeamsAction}
              loader={msTeamsLoader(queryClient)}
            />
            <Route
              path="auth"
              element={<MSTeamsAuth />}
              loader={msTeamsAuthLoader}
            />
            <Route
              path="config"
              element={<MSTeamsConfig />}
              loader={msTeamsConfigLoader}
            />
          </Route>
          <Route path="/login">
            <Route
              index
              element={<LoginRoute />}
              action={loginAction}
              loader={loginLoader(queryClient)}
            />
            <Route
              path="ms-teams"
              element={<LoginRoute isMSTeamsPage={true} />}
              action={loginAction}
              loader={loginLoader(queryClient, true)}
            />
            <Route
              path=":workspaceSlug/:provider"
              loader={loginProviderLoader}
              element={<LoginProviderRoute />}
            />
          </Route>
          <Route
            path="/create-account"
            element={<CreateAccountRoute />}
            action={createAccountAction}
            loader={createAccountLoader}
          />
          <Route
            path="/verify-email/:flow"
            action={verifyEmailFlowAction}
            element={<VerifyEmailFlowRoute />}
          />
          <Route
            path="/workspaces"
            action={workspacesAction}
            loader={workspacesLoader}
            element={<WorkspacesRoute />}
          />
          <Route path="/onboarding">
            <Route index element={<Navigate to="/create-account" />} />

            <Route
              path="owner-details"
              element={<OwnerDetailsRoute />}
              loader={ownerDetailsLoader}
              action={ownerDetailsAction}
            />
            <Route path="wait-for-account" element={<WaitForAccountRoute />} />
            <Route
              path="user-details"
              loader={userDetailsMagicLinkLoader(queryClient)}
              element={<AssemblyLoadingIndicator />}
            />
          </Route>
          <Route path="/sso-account-creation">
            <Route index element={<Navigate to="/create-account" />} />
            <Route
              path=":provider"
              element={<SSOAccountCreationRoute />}
              loader={ssoAccountCreationLoader}
            />
          </Route>
          <Route path="/sso-sign-in">
            <Route index element={<Navigate to="/login" />} />

            <Route
              path=":provider"
              element={<SSOLoginRoute />}
              loader={ssoLoginLoader}
            />
          </Route>
          <Route path="/join">
            <Route index element={<Navigate to="/login" />} />

            <Route
              path=":workspaceSlug"
              loader={joinWorkspaceSlugLoader}
              id="join-workspace-root"
            >
              <Route
                index
                action={joinWorkspaceSlugAction}
                element={<JoinWorkspaceSlugRoute />}
              />
              <Route
                path="check-email"
                loader={checkEmailLoader}
                action={checkEmailAction}
                element={<CheckEmailRoute />}
              />
              <Route path="validate">
                <Route index element={<Navigate to="/login" />} />
                <Route
                  path=":provider"
                  loader={joinWithProviderLoader}
                  element={<JoinWithProviderRoute />}
                />
              </Route>
              <Route
                path="user-details"
                element={<UserDetailsRoute />}
                action={userDetailsAction}
                loader={userDetailsLoader(queryClient)}
              />
              <Route
                path="success"
                loader={userAccountCreationSuccessLoader(queryClient)}
                element={<UserAccountCreationSuccessRoute />}
              />
              <Route
                path="account-invite/:inviteToken"
                loader={accountInviteLoader}
                element={<AccountInviteRoute />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          element={
            <Root>
              <OnboardingLayout>
                <Outlet />
              </OnboardingLayout>
            </Root>
          }
          errorElement={
            <OnboardingLayout shouldShowFooter={false}>
              <RootErrorBoundary />
            </OnboardingLayout>
          }
        >
          <Route
            path="signout"
            element={<SignOutRoute />}
            loader={signOutLoader(queryClient)}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    ),
    [queryClient]
  );

  const routeElementsWithPrefix = useMemo(
    () => (
      <Route path="/a">
        <Route
          path="templates"
          loader={templatesRouteLoader(queryClient)}
          element={<TemplatesRoute />}
          errorElement={<RootErrorBoundary />}
        />
        <Route
          element={<ConnectionsRoute />}
          errorElement={<RootErrorBoundary />}
          loader={() => connectionsLoader(queryClient)}
          path="connections"
        />
        <Route
          element={
            <Root>
              <div id="google_translate_element"></div>
              <DiscoverPageFiltersProvider>
                <AnnouncementsProvider>
                  <DiscoverRoot>
                    <Outlet />
                  </DiscoverRoot>
                </AnnouncementsProvider>
              </DiscoverPageFiltersProvider>
            </Root>
          }
          errorElement={<RootErrorBoundary />}
        >
          <Route index loader={() => redirect('/a/discover')} />
          <Route
            path="discover"
            lazy={() =>
              import('./modules/discover/pages/discover').then((m) => ({
                Component: m.DiscoverRoute,
              }))
            }
            loader={discoverPageLoader(queryClient)}
          />
          {routeConstants.legacyExperiencePaths.map((path) => (
            <Route
              key={path}
              // NOTE: path includes a leading slash
              path={`:workspaceSlug${path}/*`}
              element={<LegacyRoute />}
            />
          ))}
        </Route>
        <Route
          path="embeds"
          element={<EmbedsRoute />}
          loader={embedsLoader(queryClient)}
        />
      </Route>
    ),
    [queryClient]
  );

  const routeElements = useMemo(
    () =>
      createRoutesFromElements(
        <Route
          element={
            <PusherProvider {...config.pusher}>
              <Outlet />
            </PusherProvider>
          }
        >
          {routeElementsWithoutPrefix}
          {routeElementsWithPrefix}
        </Route>
      ),
    [routeElementsWithPrefix, routeElementsWithoutPrefix]
  );

  const router = useMemo(
    () => wrapCreateBrowserRouter(createBrowserRouter)(routeElements),
    [routeElements]
  );

  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
}
