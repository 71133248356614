import type { FlowResponseType } from '@assembly-web/services';
import { HorizontalRule, PromptWithChoiceBox } from '@assembly-web/ui';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetFlowResponseType,
  useSetFlowBuilderState,
} from '../../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../hooks/useAnyOccurrenceInProgress';
import { isFlowResponseType } from '../../utils/typeGuards';

type FlowResponseChoicesType = {
  label: string;
  id: FlowResponseType;
  description?: string;
};

const messages = defineMessages({
  teammatesRespondToFlowLabel: {
    defaultMessage: 'When do you want people to post in this flow?',
    id: 'BobFW8',
  },
  anyTimeLabel: {
    defaultMessage: 'Anytime',
    id: 'AwhX1o',
  },
  onAMilestoneLabel: {
    defaultMessage: 'On a milestone',
    id: 'EnKwd7',
  },
  onAMilestoneDescription: {
    defaultMessage: 'Post based on start date',
    id: 'UmFdjH',
  },
  anyTimeDescription: {
    defaultMessage: 'Post whenever they want',
    id: 'S+TJeF',
  },
  onADeadlineLabel: {
    defaultMessage: 'On a deadline',
    id: '/aBpxY',
  },
  onADeadlineDescription: {
    defaultMessage: 'Post within a timeframe',
    id: 'Nsanmg',
  },
});

export function FlowResponseSettings() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const choices: FlowResponseChoicesType[] = useMemo(
    () => [
      {
        id: 'anytime',
        label: formatMessage(messages.anyTimeLabel),
        description: formatMessage(messages.anyTimeDescription),
      },
      {
        id: 'deadline',
        label: formatMessage(messages.onADeadlineLabel),
        description: formatMessage(messages.onADeadlineDescription),
      },
      {
        id: 'milestone',
        label: formatMessage(messages.onAMilestoneLabel),
        description: formatMessage(messages.onAMilestoneDescription),
      },
    ],
    [formatMessage]
  );

  const flowResponseType = useGetFlowResponseType(id);
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const setDeadlineType = useSetFlowBuilderState(id, 'deadlineType');
  const setFlowResponseType = useSetFlowBuilderState(id, 'flowResponseType');
  const setFrequencyType = useSetFlowBuilderState(id, 'responseFrequencyType');
  const handleValueChange = useCallback(
    (value: string) => {
      if (isFlowResponseType(value)) {
        setFlowResponseType(value);

        if (value === 'milestone') {
          setFrequencyType('once');
        }

        if (value === 'deadline') {
          setDeadlineType('manual');
        }
      }
    },
    [setFlowResponseType, setFrequencyType, setDeadlineType]
  );

  return (
    <PromptWithChoiceBox.Root>
      <PromptWithChoiceBox.Row>
        <PromptWithChoiceBox.Prompt>
          {formatMessage(messages.teammatesRespondToFlowLabel)}
        </PromptWithChoiceBox.Prompt>
        <PromptWithChoiceBox.Choices
          choices={choices}
          value={flowResponseType}
          onValueChange={handleValueChange}
          disabled={isOccurrenceInProgress}
        />
      </PromptWithChoiceBox.Row>
      <HorizontalRule className="-ml-8 -mr-8" />
    </PromptWithChoiceBox.Root>
  );
}
