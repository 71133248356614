import type {
  FlowsToAnswerApiResponse,
  FlowsToDoCountApiResponse,
  UserDetails,
} from '@assembly-web/services';
import { config } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import { FlowsToDoMenu, useAssemblyNavigate } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { Suspense, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Await, useLoaderData } from 'react-router-dom';

import { useActionBarQuery } from '../hooks/useActionBarQuery';
import { trackDiscoverAction } from '../services/analytics';

const messages = defineMessages({
  flowsLabel: {
    defaultMessage: '{count, plural, one {1 flow to do} other {# flows to do}}',
    id: 'og6OiT',
  },
});

export const GIVE_RECOGNITION_FLOW = {
  id: 'recognition',
  name: 'Give Recognition',
  icon: {
    kind: 'HEX_CODE',
    value: '1F389',
  },
};

type FlowsDropdownProps = {
  alignOffset?: number;
  hideOnNoFlowsToDo?: boolean;
  userDetails: UserDetails;
  children?: ReactNode;
  open?: boolean;
  handleOnClose?: () => void;
  filterType: GlobalFilterOption;
  fullWidth?: boolean;
  actionBar?: boolean;
};

export function FlowsDropdown({
  alignOffset,
  children,
  hideOnNoFlowsToDo,
  userDetails,
  open,
  handleOnClose,
  filterType,
  fullWidth,
  actionBar,
}: FlowsDropdownProps) {
  const navigate = useAssemblyNavigate();
  const loaderData = useLoaderData() as {
    actionBarQueryResponse: Promise<{
      flowsToDoCount: FlowsToDoCountApiResponse;
      flowsToAnswerAnytime: FlowsToAnswerApiResponse;
      flowsToAnswerNow: FlowsToAnswerApiResponse;
    }>;
  };

  const { data } = useActionBarQuery();

  const { formatMessage } = useIntl();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;
  const isUserStatusObserver = userDetails.member.status === 'observer';

  const handleFlowMenuItemClick = useCallback(
    (flowId: string, flowName: string) => {
      const urlToNavigate = `/${workspaceSlugPath}/flows/${flowId}/answer?redirectUrl=${`${config.domains.app}/a/discover`}`;
      navigate(urlToNavigate);
      const eventName = actionBar
        ? 'actionBarStartPostFlowSelected'
        : children
          ? 'createPostFlowClicked'
          : 'toDoButtonFlowClicked';
      trackDiscoverAction(eventName, {
        flowId,
        filterType,
        flowName,
      });
    },
    [children, filterType, actionBar, navigate, workspaceSlugPath]
  );

  const handleOpenChange = useCallback(() => {
    if (actionBar) {
      trackDiscoverAction('actionBarStartPostClicked');
    }
  }, [actionBar]);

  let showToDoMenu =
    (hideOnNoFlowsToDo && data && data.flowsToDoCount.count > 0) ||
    !hideOnNoFlowsToDo;

  return (
    <Suspense fallback={null}>
      <Await resolve={loaderData.actionBarQueryResponse} errorElement={null}>
        {showToDoMenu && data ? (
          <FlowsToDoMenu
            onMenuItemClick={handleFlowMenuItemClick}
            answerNowFlows={data.flowsToAnswerNow.data.map((flow) => ({
              id: flow.flowId,
              name: flow.name,
              icon: flow.icon,
              dueDate: flow.occurrence.activeOccurrence?.endTime,
            }))}
            answerAnytimeFlows={[
              ...(!isUserStatusObserver ? [GIVE_RECOGNITION_FLOW] : []),
              ...data.flowsToAnswerAnytime.data.map((flow) => ({
                id: flow.flowId,
                name: flow.name,
                icon: flow.icon,
              })),
            ]}
            defaultOpen={open}
            handleOnClose={handleOnClose}
            alignOffset={alignOffset}
            fullWidth={fullWidth}
            onOpenChange={handleOpenChange}
          >
            {children ?? (
              <button
                className="focus-visible:ring-white font-regular ml-2 cursor-pointer justify-center rounded-full bg-primary-2 px-2 text-sm text-primary-6 hover:bg-primary-3 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75"
                onClick={() => trackDiscoverAction('toDoButtonClicked')}
              >
                {formatMessage(messages.flowsLabel, {
                  count: data.flowsToDoCount.count,
                })}
              </button>
            )}
          </FlowsToDoMenu>
        ) : null}
      </Await>
    </Suspense>
  );
}
