import {
  CriteriaRuleType,
  Operator,
  PermissionType,
  type UserDetails,
} from '@assembly-web/services';
import {
  Banner,
  type CriteriaItemProps,
  type PeopleWithAccessProps,
  type PreviewMembersProps,
} from '@assembly-web/ui';
import {
  Button,
  ConfirmationModal,
  ShareModal,
  ShareModalContent,
  SoftPinCollection,
  useToastStore,
} from '@assembly-web/ui';
import uniq from 'lodash/uniq';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';

import {
  type GetShareCollectionCriteriaAPIResponse,
  useGetShareCollectionCriteria,
} from '../../hooks/shareCollections/queries/useGetShareCollectionCriteria';
import { useSaveCollectionsCriteriaMutation } from '../../hooks/shareCollections/queries/useSaveShareCollectionCriteria';
import { usePreviewShareCollectionRules } from '../../hooks/shareCollections/usePreviewShareCollectionRules';
import {
  operatorLabelMap,
  useShareSheet,
} from '../../hooks/shareModal/useShareSheet';
import { useShareSheetCriteriaRules } from '../../hooks/shareModal/useShareSheetCriteriaRules';
import { useShareSheetMemberSearch } from '../../hooks/shareModal/useShareSheetMemberSearch';
import { useIsNewUX } from '../../hooks/useIsNewUX';
import { trackSharesheetAction } from '../../services/analytics';
import { generateSaveCriteriaPayloadFromRules } from '../../services/criteriaUtil';

export const ShareCollectionsMessageKey = 'OPEN_SHARE_COLLECTIONS_MODAL';

type ShareCollectionsMessageType = typeof ShareCollectionsMessageKey;

type ShareCollectionsMessagePayload = {
  collectionId: string;
  createdBy?: string;
};

type ShareCollectionModalProps = {
  currentUserDetails: UserDetails;
  setCollectionId: Dispatch<SetStateAction<string | null>>;
  collectionId: string | null;
  collectionOwnerId: string | null;
  initialShareCollectionRules?: GetShareCollectionCriteriaAPIResponse;
  isLoading?: boolean;
};

const messages = defineMessages({
  peopleWithAccess: {
    defaultMessage: 'People with access',
    id: 'uQGyjD',
  },
  confirmationModalTitle: {
    defaultMessage: 'Done Editing?',
    id: '1PH1ei',
  },
  confirmationModalBody: {
    defaultMessage:
      'You have unsaved changes. Before you go, choose whether you’d like to save or discard them.',
    id: 'jhcPUj',
  },
  discardChanges: {
    defaultMessage: 'Discard Changes',
    id: 'sLnDOb',
  },
  saveChanges: {
    defaultMessage: 'Save Changes',
    id: '3VI9mt',
  },
  successMessage: {
    defaultMessage: 'Updated permissions successfully',
    id: 'wZJxLk',
  },
  shareCollection: {
    defaultMessage: 'Share Collection',
    id: 'xj+IYa',
  },
  folderSharedBannerText: {
    defaultMessage:
      'All users this folder is shared with will see it in their folders',
    id: 'mAmQWS',
  },
  shareFolder: {
    defaultMessage: 'Share Folder',
    id: '3OhAPq',
  },
  previewModalSubtitle: {
    defaultMessage:
      'See who has permission to collaborate and view this folder.',
    id: 'eE45Fg',
  },
});

function ShareCollectionModalImpl({
  currentUserDetails,
  setCollectionId,
  collectionId,
  collectionOwnerId,
  initialShareCollectionRules,
  isLoading,
}: ShareCollectionModalProps) {
  const [rules, setRules] = useImmer<CriteriaItemProps[]>([]);
  const [isPinned, onIsPinnedChange] = useState(
    () => initialShareCollectionRules?.shouldPin ?? false
  );
  const [view, setView] = useState<'peopleWithAccess' | 'previewMembers'>(
    'peopleWithAccess'
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [hasRulesChanged, setHasRulesChanged] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { showSuccessToast } = useToastStore();

  const isNewUX = useIsNewUX();

  const closeShareCollectionModal = () => {
    setCollectionId(null);
    setRules([]);
    onCustomRuleSelect(null);
    setView('peopleWithAccess');
  };

  const handleCloseWithConfirmationModal = () => {
    if (hasRulesChanged) {
      setIsConfirmationModalOpen(true);
    } else {
      closeShareCollectionModal();
    }
  };

  const {
    customRules,
    customRuleOptions,
    onCustomRuleSelect,
    onCustomRulesAction,
    selectedCustomRule,
    onRulesPermissionChange,
    onRulesSelectionChange,
    handleOnEmailInvite,
  } = useShareSheet({
    enabled: view === 'peopleWithAccess',
    rules,
    setRules,
    type: 'collection',
    onEmailInvite() {
      trackSharesheetAction({
        actionName: 'inviteMemberClicked',
        props: {
          criteriaSelected: `${CriteriaRuleType.Email}${
            operatorLabelMap[Operator.Is]
          }`,
          roleSelected: PermissionType.Viewer,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'collection',
        },
      });
    },
    onRemoveRule({ currentRule }) {
      trackSharesheetAction({
        actionName: 'removeMemberClicked',
        props: {
          criteriaSelected: `${currentRule.metaData?.field}${
            currentRule.metaData?.operator &&
            operatorLabelMap[currentRule.metaData.operator]
          }`,
          roleSelected: currentRule.permission?.id,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'collection',
        },
      });
    },
  });

  useShareSheetCriteriaRules({
    rules,
    setRules,
    type: 'collection',
    initialRules: initialShareCollectionRules,
    setHasRulesChanged,
    owner: {
      ownerId: collectionOwnerId,
    },
  });

  const { mutate: saveShareCollectionCriteria, isPending: isSaveInProgress } =
    useSaveCollectionsCriteriaMutation({
      onSuccess: () => {
        closeShareCollectionModal();
        showSuccessToast(formatMessage(messages.successMessage));
      },
    });

  const {
    isLoadingMembers,
    hasNextPage,
    isFetchingNextPage,
    searchTerm,
    members,
    onMemberSearch,
    onLoadMore,
    canInviteCurrentUser,
    hasUserInvitePermission,
  } = useShareSheetMemberSearch({
    enabled: view === 'peopleWithAccess' && Boolean(collectionId),
  });

  const {
    displayRules,
    previewFilterOptions,
    onSearchOrFilterList,
    isLoading: isLoadingPreview,
    isFetchingNextPage: isFetchingNextPageForPreview,
    hasNextPage: hasNextPageForPreview,
    fetchNextPage: fetchNextPageForPreview,
  } = usePreviewShareCollectionRules({
    rules,
    enabled: view === 'previewMembers',
    collectionOwnerId,
    currentUserDetails,
  });

  const onDoneAction = () => {
    const payload = generateSaveCriteriaPayloadFromRules(rules, isPinned);
    saveShareCollectionCriteria({
      collectionId: collectionId ?? '',
      payload,
    });
    const selectedCriteria = rules.map(
      (rule) =>
        `${rule.metaData?.field}${
          rule.metaData?.operator && operatorLabelMap[rule.metaData.operator]
        }`
    );
    trackSharesheetAction({
      actionName: 'shareSettingsSaved',
      props: {
        isSoftPinnedCollection: isPinned,
        ruleType: 'simple',
        isV3sharesheet: true,
        sharesheetType: 'collection',
        criteriaSelected: uniq(selectedCriteria),
      },
    });
  };

  const props: PeopleWithAccessProps | PreviewMembersProps =
    view === 'peopleWithAccess'
      ? {
          view: 'peopleWithAccess',
          customRuleSelectorProps: {
            customRuleOptions,
            onCustomRulesAction,
            onCustomRuleSelect,
            ruleOptions: customRuleOptions,
            selectedCustomRule,
          },
          rulesListProps: {
            hasNextPageForRules: false,
            onRulesPermissionChange,
            rules,
          },
          searchableRulesDropdownProps: {
            canInviteEmail: canInviteCurrentUser ?? false,
            handleOnEmailInvite,
            customRules,
            hasNextPageForMembers: hasNextPage,
            hasUserInvitePermission,
            members: canInviteCurrentUser ? [] : members,
            onLoadMore,
            onMemberSearch,
            onRulesSelectionChange,
            searchTerm,
            isFetchingMoreMembers: isFetchingNextPage,
            isLoadingMembers,
          },
          isLoading,
          peopleWithAccessHeaderProps: {
            title: formatMessage(messages.peopleWithAccess),
            onPreviewButtonClick: () => {
              setView('previewMembers');
              trackSharesheetAction({
                actionName: 'previewListClicked',
                props: {
                  ruleType: 'simple',
                  isV3sharesheet: true,
                  sharesheetType: 'collection',
                },
              });
            },
          },
        }
      : {
          view: 'previewMembers',
          options: previewFilterOptions,
          onPreviewModalBackButtonClick: () => setView('peopleWithAccess'),
          rules: displayRules,
          onLoadMore: fetchNextPageForPreview,
          hasNextPage: hasNextPageForPreview,
          onSearchOrFilterList,
          isLoadingPreview,
          isFetchingMoreMembers: isFetchingNextPageForPreview,
          className: 'h-[400px]',
          ...(isNewUX
            ? { subTitle: formatMessage(messages.previewModalSubtitle) }
            : {}),
        };

  return (
    <>
      <ShareModal
        isOpen={Boolean(collectionId)}
        onClose={handleCloseWithConfirmationModal}
        showCTASection={view === 'peopleWithAccess'}
        onGetHelpClick={() => {
          trackSharesheetAction({
            actionName: 'getHelpClicked',
            props: {
              ruleType: 'simple',
              isV3sharesheet: true,
              sharesheetType: 'collection',
            },
          });
          window.open(
            'https://www.joinassembly.com/articles/collections',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        onDoneAction={onDoneAction}
        isSaveInProgress={isSaveInProgress}
      >
        <ShareModalContent
          tab={{
            title: formatMessage(
              isNewUX ? messages.shareFolder : messages.shareCollection
            ),
            content: isNewUX ? (
              <Banner status="info">
                {formatMessage(messages.folderSharedBannerText)}
              </Banner>
            ) : (
              <SoftPinCollection
                {...{
                  isPinned,
                  onIsPinnedChange,
                  isLoading,
                }}
              />
            ),
          }}
          {...props}
        />
      </ShareModal>
      <ConfirmationModal
        description={formatMessage(messages.confirmationModalBody)}
        rightButton={
          <Button
            isFullWidth
            onClick={() => {
              setIsConfirmationModalOpen(false);
              onDoneAction();
            }}
            variation="primary"
          >
            {formatMessage(messages.saveChanges)}
          </Button>
        }
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        open={isConfirmationModalOpen}
        leftButton={
          <Button
            isFullWidth
            onClick={() => {
              setIsConfirmationModalOpen(false);
              closeShareCollectionModal();
            }}
            variation="secondaryEmphasized"
          >
            {formatMessage(messages.discardChanges)}
          </Button>
        }
        title={formatMessage(messages.confirmationModalTitle)}
      />
    </>
  );
}

export function ShareCollectionModal({
  currentUserDetails,
}: Pick<ShareCollectionModalProps, 'currentUserDetails'>) {
  const [collectionId, setCollectionId] = useState<string | null>(null);
  const [collectionOwnerId, setCollectionOwnerId] = useState<string | null>(
    null
  );

  const { data: initialShareCollectionRules, isLoading: isCriteriaLoading } =
    useGetShareCollectionCriteria({
      id: collectionId ?? '',
      enabled: Boolean(collectionId),
    });

  useEffect(() => {
    const handleMessage = ({
      data: { type, payload },
    }: MessageEvent<{
      type: ShareCollectionsMessageType;
      payload: ShareCollectionsMessagePayload;
    }>) => {
      switch (type) {
        case ShareCollectionsMessageKey:
          setCollectionId(payload.collectionId);
          setCollectionOwnerId(payload.createdBy ?? null);
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <ShareCollectionModalImpl
      key={`${collectionId}-${isCriteriaLoading}`}
      {...{
        currentUserDetails,
        collectionId,
        collectionOwnerId,
        initialShareCollectionRules,
        setCollectionId,
        isLoading: isCriteriaLoading,
      }}
    />
  );
}
