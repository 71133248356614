import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type { categoryLists } from '../types';

export type CategoriesAPIResponse = {
  data: categoryLists[];
};

export function getCategoriesQuery(): UseQueryOptions<CategoriesAPIResponse> {
  return {
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await assemblyAPI.get<CategoriesAPIResponse>(
        APIEndpoints.listCategories
      );
      return response.data;
    },
  };
}

export function useCategoriesQuery() {
  return useQuery(getCategoriesQuery());
}
