import type {
  Collection,
  MemberPermissions,
  PinnedCollection,
  UserDetails,
} from '@assembly-web/services';
import {
  browserUtils,
  config,
  copyToClipboard,
  SplitNames,
  useFeatureSplit,
  userAuthStore,
} from '@assembly-web/services';
import {
  GlobalFilterOption,
  useAssemblyNavigate,
  useToastStore,
} from '@assembly-web/ui';
import { AnimatePresence, motion } from 'framer-motion';
import type { ComponentProps } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import invariant from 'tiny-invariant';

import { getReorderingInfo } from '../../../../services/dragDropUtils';
import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import { useCreateWorkflow } from '../../hooks/flowsEditor/useCreateWorkflow';
import { useNavCollectionReorderMutation } from '../../hooks/nav/useNavCollectionReorderMutation';
import { useNavData } from '../../hooks/nav/useNavData';
import { useNavStore } from '../../hooks/nav/useNavStore';
import { usePinnedCollectionToggleStateUpdater } from '../../hooks/nav/usePinnedCollectionToggleStateUpdater';
import { useActionBarOptions } from '../../hooks/useActionBarOptions';
import { useCollectionItemReorderMutation } from '../../hooks/useCollectionItemReorderMutation';
import { useCollectionItemsMutation } from '../../hooks/useCollectionItemsMutation';
import { useGetImportantActivitiesQuery } from '../../hooks/useGetImportantActivitiesQuery';
import { useGlobalFilter } from '../../hooks/useGlobalFilter';
import {
  getRewardsRedirectionPath,
  useRewardsHeaderDetails,
} from '../../hooks/useRewardsHeaderDetails';
import {
  trackDoraAction,
  trackFlowEditorAction,
  trackNavAction,
  trackNavShow,
} from '../../services/analytics';
import { setFullScreenDrawerId } from '../../services/drawers';
import { GIVE_RECOGNITION_FLOW } from '../FlowsDropdown';
import { ShareCollectionsMessageKey } from '../modals/ShareCollectionModal';
import { NavBar } from './NavBar';
import { useNavTrigger } from './useNavTrigger';

const msgs = defineMessages({
  pinningKeyboardShortcut: {
    defaultMessage:
      'Pin <metaKey>Placeholder</metaKey><cmd>shift</cmd><cmd>P</cmd>',
    id: '3rYm8G',
  },
  copiedToClipboardSuccess: {
    defaultMessage: 'Link successfully copied to clipboard',
    id: 'QHwY68',
  },
  doraDrawerTitle: {
    defaultMessage: 'DoraAI Reporting',
    id: 'eE3c8F',
  },
  createNewFlowTitle: {
    defaultMessage: 'Create a new flow',
    id: 'eOrH+o',
  },
});

type NavContainerProps = {
  permissions: Partial<MemberPermissions>;
  userDetails: UserDetails;
  handleImportantActivityClick: () => void;
  openCreatePinnedCollectionModal: (collection?: Collection) => void;
  openDeleteCollectionModal: (collection: Collection) => void;
  openPinExistingCollectionModal: () => void;
  openUnpinModal: (collection: Collection) => void;
};

function getBaseCollectionModel(
  pinnedCollectionItem: PinnedCollection
): Collection {
  return {
    collectionId: pinnedCollectionItem.id,
    name: pinnedCollectionItem.name,
    colorName: pinnedCollectionItem.colorName,
    icon: pinnedCollectionItem.icon,
    description: pinnedCollectionItem.description,
    isPinned: true,
  };
}

export const AddItemsToCollectionViaSearchMsgKey =
  'ADD_ITEMS_TO_COLLECTION_VIA_SEARCH';

function NavContainerImpl({
  permissions,
  userDetails,
  handleImportantActivityClick,
  openCreatePinnedCollectionModal,
  openDeleteCollectionModal,
  openPinExistingCollectionModal,
  openUnpinModal,
}: NavContainerProps) {
  const { formatMessage } = useIntl();

  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);

  const navigate = useAssemblyNavigate();

  const { data: importantActivities } = useGetImportantActivitiesQuery();

  const { mutate: updatePinnedCollectionToggleState } =
    usePinnedCollectionToggleStateUpdater();

  const importantActivityCount =
    importantActivities?.pages[0].data.total ?? '0';

  const { treatment } = useFeatureSplit(SplitNames.RewardsV3);
  const isV3RewardsEnabled = treatment === 'on';

  const { isTreatmentActive: disableNavPinningTreatmentActive } =
    useFeatureSplit(SplitNames.DisableNavPinning);

  const isNavOpen = useNavStore((state) => state.isOpen);
  const showNav = useNavStore((state) => state.showNav);
  const hideNav = useNavStore((state) => state.hideNav);
  const pinNav = useNavStore((state) => state.pinNav);
  const unpinNav = useNavStore((state) => state.unpinNav);
  const isNavPinned = useNavStore((state) => state.isPinned);

  const isActionSectionExpanded = useNavStore(
    (state) => state.isActionSectionExpanded
  );
  const updateActionSection = useNavStore((state) => state.updateActionSection);

  const navTriggerPosition = useNavStore((state) => state.navTriggerPosition);

  const { isBottomNav, hotkey } = useNavTrigger();

  const { showSuccessToast } = useToastStore();

  const { hasCharitiesSetup, hasGiftCardsSetup } = useRewardsHeaderDetails();

  const { giveRecognition } = useActionBarOptions();

  const filter = useGlobalFilter();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const {
    pinnedCollections,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
    isPending,
    hasNextPage: hasMoreCollections,
    fetchNextPage: fetchMoreCollections,
    isFetchingNextPage: isFetchingMoreCollections,
  } = useNavData();

  useEffect(() => {
    if (disableNavPinningTreatmentActive && !isBottomNav) {
      showNav();
      pinNav();
    }
  }, [disableNavPinningTreatmentActive, pinNav, showNav, isBottomNav]);

  const expandedSections = useMemo(() => {
    const sections = isActionSectionExpanded ? ['actions'] : [];

    return [
      ...sections,
      ...pinnedCollections
        .filter(({ isExpanded }) => isExpanded)
        .map(({ id }) => id),
    ];
  }, [isActionSectionExpanded, pinnedCollections]);

  const updateExpandedSections = useCallback(
    function (expandedSectionsFromNav: string[]) {
      updateActionSection(expandedSectionsFromNav.includes('actions'));
      const expanded = [];
      const collapsed = [];
      let isSoftPinnedCollection = false;

      for (const collection of pinnedCollections) {
        if (
          collection.isExpanded &&
          !expandedSectionsFromNav.includes(collection.id)
        ) {
          collapsed.push(collection.id);
          isSoftPinnedCollection = Boolean(collection.softPin);
        } else if (
          !collection.isExpanded &&
          expandedSectionsFromNav.includes(collection.id)
        ) {
          expanded.push(collection.id);
          isSoftPinnedCollection = Boolean(collection.softPin);
        }
      }

      if (expanded.length > 0 || collapsed.length > 0) {
        trackNavAction(
          expanded.length > 0 ? 'collectionExpanded' : 'collectionCollapsed',
          {
            isSoftPinnedCollection,
          }
        );
        updatePinnedCollectionToggleState({
          expanded,
          collapsed,
        });
      }
    },
    [pinnedCollections, updateActionSection, updatePinnedCollectionToggleState]
  );

  const { mutate: reorderCollectionMutation } =
    useNavCollectionReorderMutation();

  const { mutate: reorderCollectionItemMutation } =
    useCollectionItemReorderMutation();
  const { mutate: removeFromCollection } = useCollectionItemsMutation();

  const createFlow = useCreateWorkflow({ flowCreationOverrideEnabled: true });

  useHotkeys(hotkey, () => {
    if (isNavPinned) {
      hideNav();
      unpinNav();
    } else {
      trackNavShow({
        numPinnedCollections: totalNoOfPinnedCollections,
        numSoftPinnedCollections: totalSoftPinnedCollections,
      });
      showNav();
      pinNav();
    }
  });

  const entryAnimation = useMemo(() => {
    if (isBottomNav) {
      return { height: 'auto', opacity: 100, right: 'auto', left: 0 };
    }

    return navTriggerPosition === 'left'
      ? { left: 24, opacity: 100 }
      : { right: 24, opacity: 100 };
  }, [navTriggerPosition, isBottomNav]);

  const exitAnimation = useMemo(() => {
    if (isBottomNav) {
      return { height: '0', opacity: 100 };
    }

    return navTriggerPosition === 'left'
      ? { left: -100, opacity: 0 }
      : { right: -100, opacity: 0 };
  }, [navTriggerPosition, isBottomNav]);

  const handleActionClick: ComponentProps<typeof NavBar>['onActionClick'] = (
    action
  ) => {
    if (!isNavPinned && isNavOpen) {
      hideNav();
    }
    switch (action) {
      case 'goToImportantActivitySection':
        handleImportantActivityClick();
        break;
      case 'goToExploreTemplatesPage':
      case 'createFlow':
        createFlow();
        trackNavAction('createFlowClicked');
        trackFlowEditorAction('createFlowNavClicked');
        break;
      case 'goToConnectToolsPage':
        navigate('/connections');
        break;
      case 'createPost':
        if (giveRecognition === 'primary') {
          navigate(
            `/a/${workspaceSlugPath}/flows/${
              GIVE_RECOGNITION_FLOW.id
            }/answer?redirectUrl=${`${config.domains.app}/a/discover`}`
          );
        } else {
          navigate('/discover?openDropdown=post', { replace: true });
        }
        trackNavAction('createPostClicked');
        break;
      case 'createTask':
        trackNavAction('createTaskClicked');
        navigate(
          `/${userDetails.assembly.workspaceSlugPath}/notebook/deadline?createTask=true`
        );
        break;
      case 'exploreRewards':
        trackNavAction('exploreRewardsClicked');
        if (filter === GlobalFilterOption.Rewards && isV3RewardsEnabled) {
          return;
        } else {
          navigate(
            getRewardsRedirectionPath({
              workspaceSlugPath: userDetails.assembly.workspaceSlugPath,
              hasGiftCardsSetup,
              hasCharitiesSetup,
              isV3RewardsEnabled,
            })
          );
        }
        break;
      case 'createNewCollection':
        openCreatePinnedCollectionModal();
        trackNavAction('createCollection');
        break;
      case 'pinExistingCollection':
        openPinExistingCollectionModal();
        trackNavAction('pinExistingCollectionClicked');
        break;
      case 'openDoraReportingInsights': {
        const drawerId = createDrawer({
          type: 'doraChat',
          title: formatMessage(msgs.doraDrawerTitle),
          data: {},
        });

        setFullScreenDrawerId(drawerId); // Open in full-screen mode by default
        trackDoraAction('aiReportingInsightsClicked');
        break;
      }
      default:
        console.log('Function not implemented.', action);
        break;
    }
  };

  const handleCollectionHeaderActionClick: ComponentProps<
    typeof NavBar
  >['onCollectionHeaderActionClick'] = (action, collectionId) => {
    const currentCollectionIndex = pinnedCollections.findIndex(
      (x) => x.id === collectionId
    );
    const collection = pinnedCollections[currentCollectionIndex];
    const isSoftPinnedCollection = Boolean(collection.softPin);

    function reorderCollection(direction: 'up' | 'down') {
      invariant(collectionId, 'collectionId must be defined');

      invariant(
        currentCollectionIndex >= 0,
        'collection must be present in nav'
      );

      if (direction === 'up') {
        const afterId = pinnedCollections[currentCollectionIndex - 1]?.id;

        invariant(afterId, 'there must be a collection above it');
        reorderCollectionMutation({ collectionId, afterId });
        trackNavAction('moveCollectionUp', { isSoftPinnedCollection });
      } else {
        const beforeId = pinnedCollections[currentCollectionIndex + 1]?.id;

        invariant(beforeId, 'there must be a collection below it');
        reorderCollectionMutation({ collectionId, beforeId });
        trackNavAction('moveCollectionDown', { isSoftPinnedCollection });
      }
    }

    switch (action) {
      case 'editCollection':
        {
          openCreatePinnedCollectionModal(getBaseCollectionModel(collection));
          trackNavAction('editCollection', { isSoftPinnedCollection });
        }
        break;
      case 'deleteCollection':
        {
          openDeleteCollectionModal(getBaseCollectionModel(collection));
          trackNavAction('deleteCollection', { isSoftPinnedCollection });
        }
        break;
      case 'moveCollectionDown':
        reorderCollection('down');
        break;
      case 'moveCollectionUp':
        reorderCollection('up');
        break;
      case 'shareCollection':
        window.postMessage(
          {
            type: ShareCollectionsMessageKey,
            payload: {
              collectionId: collectionId,
              createdBy: collection.createdBy ?? undefined,
            },
          },
          '*'
        );
        trackNavAction('shareCollectionClicked', { isSoftPinnedCollection });
        break;
      case 'addItemsToCollection':
        window.postMessage({ type: AddItemsToCollectionViaSearchMsgKey });
        trackNavAction('addToCollection', { isSoftPinnedCollection });
        break;
      case 'unpinCollection':
        {
          invariant(collection, 'collection must be defined');
          const { name, colorName, icon } = collection;

          openUnpinModal({
            collectionId: collectionId,
            name,
            colorName,
            icon,
          });
          trackNavAction('unpinCollection', { isSoftPinnedCollection });
        }
        break;
      default:
        console.log('Function not implemented.', action, collectionId);
        break;
    }
  };

  const handleCollectionItemActionClick: ComponentProps<
    typeof NavBar
  >['onCollectionItemActionClick'] = ({
    action,
    collectionId,
    item,
    url,
    text,
  }) => {
    const collection = pinnedCollections.find((x) => x.id === collectionId);
    const isSoftPinnedCollection = Boolean(collection?.softPin);
    const originalItemFromCollection = collection?.listItems.find(
      (x) => x.id === item.id
    );

    invariant(originalItemFromCollection, 'item must be present in collection');

    function reorderCollectionItem(direction: 'up' | 'down') {
      invariant(collection, 'collection must be defined');

      const newPositionInfo = getReorderingInfo({
        items: collection.listItems,
        direction,
        itemId: item.id,
      });

      invariant(newPositionInfo, 'newPositionInfo must be defined');

      const { beforeId, afterId, newLocationIndex } = newPositionInfo;

      reorderCollectionItemMutation({
        collectionId: collection.id,
        itemId: item.id,
        beforeId,
        afterId,
        newLocationIndex,
      });
    }

    switch (action) {
      case 'copyLink':
        {
          if (url) {
            const linkToCopy = new URL(url, window.location.origin).toString();
            copyToClipboard(linkToCopy);
            showSuccessToast(formatMessage(msgs.copiedToClipboardSuccess));
            trackNavAction('linkCopied', { isSoftPinnedCollection });
          }
        }
        break;
      case 'removeItemFromCollection':
        {
          const collectionName = collection?.name ?? '';

          removeFromCollection({
            action: 'remove',
            collectionName,
            collectionId,
            name: text,
            type: originalItemFromCollection.type,
            entityId: item.id,
          });
          trackNavAction('removeFromCollection', { isSoftPinnedCollection });
        }
        break;
      case 'moveItemDown':
        reorderCollectionItem('down');
        trackNavAction('moveCollectionItemDown', { isSoftPinnedCollection });
        break;
      case 'moveItemUp':
        reorderCollectionItem('up');
        trackNavAction('moveCollectionItemUp', { isSoftPinnedCollection });
        break;
      case 'moveToAnotherCollection':
      default:
        console.log('Function not implemented.', action, collectionId, item);
        break;
    }
  };

  useEffect(() => {
    if (!isPending && !isFetchingMoreCollections && isNavPinned) {
      trackNavShow({
        numPinnedCollections: totalNoOfPinnedCollections,
        numSoftPinnedCollections: totalSoftPinnedCollections,
      });
    }
  }, [
    isFetchingMoreCollections,
    isPending,
    isNavPinned,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
  ]);

  return (
    <AnimatePresence>
      {Boolean(isNavOpen) && (
        <motion.div
          role="presentation"
          className={twMerge(
            'fixed z-[5] inline-flex flex-col gap-2',
            isBottomNav ? 'bottom-0 w-full' : 'bottom-6 top-[174px] w-[248px]',
            !browserUtils.isMobile &&
              !isBottomNav &&
              (navTriggerPosition === 'left' ? 'left-6' : 'right-6')
          )}
          onMouseLeave={() => {
            if (!isNavPinned) {
              hideNav();
            }
          }}
          onTouchEnd={() => {
            if (!isNavPinned) {
              hideNav();
            }
          }}
          initial={exitAnimation}
          animate={entryAnimation}
          exit={exitAnimation}
        >
          <NavBar
            hasMoreCollections={hasMoreCollections}
            fetchMoreCollections={fetchMoreCollections}
            isFetchingMoreCollections={isFetchingMoreCollections}
            userDetails={userDetails}
            collections={pinnedCollections}
            importantActivityCount={Number.parseInt(importantActivityCount)}
            permissions={permissions}
            expandedSections={expandedSections}
            onSectionToggle={(sections) => updateExpandedSections(sections)}
            onActionClick={handleActionClick}
            onCollectionHeaderActionClick={handleCollectionHeaderActionClick}
            onCollectionItemActionClick={handleCollectionItemActionClick}
            isLoading={isPending}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function NavContainer(props: NavContainerProps) {
  const isMobileAppV3 = userAuthStore.getState().isMobileAppV3;

  if (isMobileAppV3) {
    return null;
  }

  return <NavContainerImpl {...props} />;
}
