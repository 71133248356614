import { templatesBgGradient } from '@assembly-web/assets';
import {
  logger,
  mapHexCodeToEmoticon,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import {
  TemplateCard,
  TextStyle,
  useAssemblyNavigate,
  useToastStore,
} from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { trackTemplatesAction } from '../../../services/analytics';
import { useCreateWorkflow } from '../../discover/hooks/flowsEditor/useCreateWorkflow';
import {
  type FlowCreationResponse,
  useTemplateMutation,
} from '../queries/useTemplateMutation';
import type { Template } from '../types';
import { mapBgColor } from './TemplateList';

const message = defineMessages({
  templatesFor: {
    id: 'zvGHA9',
    defaultMessage: 'Templates for “{key}”',
  },
  flowCreationSuccess: {
    id: 'vIPz5K',
    defaultMessage: 'Flow created successfully',
  },
  flowCreationError: {
    id: 'hyiT4x',
    defaultMessage: 'Error creating flow. Please try again',
  },
});

export function TemplateLoader() {
  return (
    <div className="h-[112px] w-[350px] animate-pulse cursor-pointer gap-2 rounded-lg border border-primary-3 bg-gray-1 p-4">
      <div className="flex h-full gap-4">
        <div className="h-8 w-8 rounded bg-gray-4"></div>
        <div className="flex h-full w-full flex-col justify-between gap-6">
          <div className="h-5 w-3/4 rounded bg-gray-4"></div>
          <div className="h-3 w-2/4 rounded bg-gray-4"></div>
        </div>
      </div>
    </div>
  );
}

export function CategorizedTemplatesLoader() {
  return (
    <>
      <div className="flex gap-4">
        <div className="h-12 w-12 rounded bg-gray-4"></div>
        <div className="flex h-full w-full flex-col justify-between gap-6">
          <div className="h-4 w-2/4 rounded bg-gray-4"></div>
          <div className="h-2 w-1/4 rounded bg-gray-4"></div>
        </div>
      </div>
      <TemplateLoader />
    </>
  );
}

export function TemplatesBackground({ showBg = true }) {
  return (
    <img
      src={templatesBgGradient}
      alt="logo"
      className={twJoin(
        'absolute w-full opacity-30',
        showBg && 'h-full bg-primary-3'
      )}
    />
  );
}

export function SearchContainer({
  search,
  children,
}: {
  search: string;
  children: React.ReactNode;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="mx-10 flex flex-col gap-8 opacity-90 sm:mx-44">
      <TextStyle variant="xl-medium" className="mt-14">
        {formatMessage(message.templatesFor, { key: search })}
      </TextStyle>
      {children}
    </div>
  );
}

export function TemplateTile({
  templates,
  categoryColorMap,
}: {
  templates: Template[];
  categoryColorMap: Record<
    string,
    {
      color: string;
      icon: ReactNode;
    }
  >;
}) {
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();
  const { showSuccessToast, showErrorToast } = useToastStore();
  const { isTreatmentActive: isFlowCreationDrawerEnabled } = useFeatureSplit(
    SplitNames.FlowCreation
  );
  const createFlowEditorDrawer = useCreateWorkflow();

  const { data: userDetails } = useUserDetails();
  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;
  const onFlowCreationSuccess = (res: unknown) => {
    if (typeof res === 'object' && res !== null) {
      const data = res as FlowCreationResponse;
      navigate(
        `/${workspaceSlugPath}/flows/${data.data.flowId}?flow-created-from-template=true&show-share-sheet=true`
      );
    }
    showSuccessToast(formatMessage(message.flowCreationSuccess));
  };

  const onFlowCreationError = (
    err: unknown,
    payload: {
      templateId: string;
    }
  ) => {
    showErrorToast(formatMessage(message.flowCreationError));
    const errorInfo = err instanceof Error ? err : undefined;
    logger.error('Failed to create flow from one click', payload, errorInfo);
  };

  const { mutate: createOneClickFlow, isPending: isFlowCreationInProgress } =
    useTemplateMutation({
      options: {
        onSuccess: onFlowCreationSuccess,
        onError: onFlowCreationError,
      },
    });
  const navigateToTemplate = (templateId: string) => {
    createFlowEditorDrawer({
      id: templateId,
      type: 'template',
      overrideTreatmentOffCallback() {
        navigate(
          `/${workspaceSlugPath}/template/${templateId}?${new URLSearchParams({ from: 'templateGallery' })}`
        );
      },
    });
    if (isFlowCreationDrawerEnabled) {
      navigate('/a/discover');
    }
  };

  const onUseTemplate = (template: Template) => {
    trackTemplatesAction('useTemplateClicked', {
      templateId: template.templateId,
      category: template.category,
    });
    createOneClickFlow({
      templateId: template.templateId,
      templateName: template.title,
      icon: template.icon.value,
    });
  };

  const onPreview = (template: Template) => {
    navigateToTemplate(template.templateId);
    trackTemplatesAction('previewTemplateClicked', {
      templateId: template.templateId,
      category: template.category,
    });
  };

  return (
    <>
      {templates.map((template, index) => {
        const icon = mapHexCodeToEmoticon(template.icon.value);
        const categoryColor = categoryColorMap[template.category];
        const bgColor = mapBgColor[categoryColor.color];
        return (
          <div className="place-self-center" key={index}>
            <TemplateCard
              key={template.templateId}
              title={template.title}
              icon={icon}
              bgColor={bgColor}
              blockCount={(JSON.parse(template.blocks) as unknown[]).length}
              onUseTemplate={() => onUseTemplate(template)}
              onPreview={() => onPreview(template)}
              isFlowCreationInProgress={isFlowCreationInProgress}
            />
          </div>
        );
      })}
    </>
  );
}
