import { useEffect, useMemo } from 'react';
import { RRule } from 'rrule';

import {
  useGetDueDate,
  useGetFlowBuilderState,
  useGetRemindersTimeZone,
  useSetFlowBuilderState,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import { generateRRuleOptions } from '../utils/distribution';
import { useGetTimeOptions } from './useGetTimeOptions';

export function useReminderSchedule() {
  const { id } = useEditorDataContext();
  const frequencyTimeOptions = useGetTimeOptions();

  const dueDate = useGetDueDate(id);
  const timeZone = useGetRemindersTimeZone(id);
  const endDate = useGetFlowBuilderState(id, 'endDate');
  const dueTime = useGetFlowBuilderState(id, 'remindersDueTime');
  const remindersFrequency = useGetFlowBuilderState(id, 'remindersFrequency');

  const setRRule = useSetFlowBuilderState(id, 'reminderSchedule');

  const rrule = useMemo(() => {
    if (!dueTime || !remindersFrequency || !timeZone) {
      return null;
    }

    const selectedTime =
      frequencyTimeOptions.find((option) => option.id === dueTime)?.id ??
      frequencyTimeOptions[0].id;

    const rruleOptions = generateRRuleOptions(
      dueDate,
      endDate,
      selectedTime,
      remindersFrequency,
      'recurring',
      timeZone
    );

    return new RRule(rruleOptions).toString();
  }, [
    dueDate,
    dueTime,
    endDate,
    timeZone,
    remindersFrequency,
    frequencyTimeOptions,
  ]);

  useEffect(() => {
    setRRule(rrule);
  }, [rrule, setRRule]);

  return rrule;
}
