import {
  type DeadlineUnitOption,
  FlowFrequencyOptions,
} from '@assembly-web/services';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetRepeatFrequency,
  useGetResponseTimeValue,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';

const messages = defineMessages({
  minutes: {
    id: 'VQ5o6f',
    defaultMessage: '{count, plural, one {minute} other {minutes}}',
  },
  hours: {
    id: 'cI0qRH',
    defaultMessage: '{count, plural, one {hour} other {hours}}',
  },
  days: {
    id: 'BEUasI',
    defaultMessage: '{count, plural, one {day} other {days}}',
  },
  weeks: {
    id: 'd7UwRb',
    defaultMessage: '{count, plural, one {week} other {weeks}}',
  },
});

export function useGetDeadlineTimeOptions(): DeadlineUnitOption[] {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const responseTimeValue = useGetResponseTimeValue(id);
  const repeatFrequency = useGetRepeatFrequency(id);

  const hideWeeks =
    repeatFrequency === FlowFrequencyOptions.Daily ||
    repeatFrequency === FlowFrequencyOptions.EveryWeekday;

  return useMemo(() => {
    const baseOptions: DeadlineUnitOption[] = [
      {
        label: formatMessage(messages.minutes, { count: responseTimeValue }),
        id: 'minutes',
      },
      {
        label: formatMessage(messages.hours, { count: responseTimeValue }),
        id: 'hours',
      },
      {
        label: formatMessage(messages.days, { count: responseTimeValue }),
        id: 'days',
      },
    ];

    return hideWeeks
      ? baseOptions
      : [
          ...baseOptions,
          {
            label: formatMessage(messages.weeks, { count: responseTimeValue }),
            id: 'weeks',
          },
        ];
  }, [formatMessage, hideWeeks, responseTimeValue]);
}
