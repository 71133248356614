import { pinnedCollection, sidebarLockIcon } from '@assembly-web/assets';
import type {
  MemberPermissions,
  PinnedCollection,
  SearchIndexResult,
  UserDetails,
} from '@assembly-web/services';
import {
  browserUtils,
  MemberStatus,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  Avatar,
  AvatarSize,
  HorizontalRule,
  IconButton,
  navActionItemStyles,
  PinIcon,
  QuickAction,
  RoutineIcon,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import {
  BellAlertIcon,
  BookOpenIcon,
  ChartBarSquareIcon,
  ChevronDoubleRightIcon,
  GiftIcon,
  PencilSquareIcon,
  PlusIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline';
import { Root } from '@radix-ui/react-accordion';
import type {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { FilePreviewModalContext } from '../../../../contexts/FilePreviewModalContext';
import { useCreateChatWindow } from '../../hooks/chat/useCreateChatWindow';
import { useGetUnReadChatMessages } from '../../hooks/chat/useGetUnReadChatMessages';
import { useIsChatEnabled } from '../../hooks/chat/useIsChatEnabled';
import type { useNavData } from '../../hooks/nav/useNavData';
import { useNavStore } from '../../hooks/nav/useNavStore';
import { useGetPlanFeaturesQuery } from '../../hooks/useGetPlanFeaturesQuery';
import { useRewardsHeaderDetails } from '../../hooks/useRewardsHeaderDetails';
import { isDoraIncludedInWorkspacePlan } from '../../services/dora';
import { isAdminMember } from '../../services/member';
import { CollectionSection } from './CollectionSection';
import {
  ChatsSkeletonLoader,
  CollectionSkeletonLoader,
} from './CollectionSkeletonLoader';
import { FlowSection } from './Flows/FlowSection';
import { useNavTrigger } from './useNavTrigger';

const messages = defineMessages({
  pinLabel: {
    defaultMessage: 'Lock menu open',
    id: 'a2cNSg',
  },
  chatHeaderLabel: {
    defaultMessage: 'Messages',
    id: 'hMzcSq',
  },
  noMessagesLabel: {
    defaultMessage: 'No messages',
    id: 'Nb/g9b',
  },
  pinningKeyboardShortcut: {
    defaultMessage:
      'Lock menu open <metaKey>Placeholder</metaKey><cmd>shift</cmd><cmd>P</cmd>',
    id: 'poyJ7Z',
  },
  closeMenu: {
    defaultMessage: 'Close menu',
    id: 'dg8ZEb',
  },
  notifications: {
    id: 'NAidKb',
    defaultMessage: 'Notifications',
  },
  menuHeader: {
    defaultMessage: 'My menu',
    id: 'oiwqYi',
  },
  createPost: {
    id: 'ToUT+W',
    defaultMessage: 'Create a post',
  },
  createTask: {
    id: 'd8DiOp',
    defaultMessage: 'Create a task',
  },
  createNewFlow: {
    id: 'eOrH+o',
    defaultMessage: 'Create a new flow',
  },
  pinExistingCollection: {
    id: '2ji6Vb',
    defaultMessage: 'Pin a collection',
  },
  createNewCollection: {
    id: 'qkFwbV',
    defaultMessage: 'Create a collection',
  },
  goToImportantActivitySection: {
    defaultMessage: 'Go to the important activity section',
    id: 'QlPbPL',
  },
  goToExploreTemplatesPage: {
    defaultMessage: 'Explore templates',
    id: '1ODiI7',
  },
  goToConnectToolsPage: {
    defaultMessage: 'Connect tools',
    id: 'rby415',
  },
  maxPinnedCollectionsHit: {
    defaultMessage:
      "You've hit your maximum (5) pinned collections. Unpin one to continue.",
    id: 'jzPu9/',
  },
  exploreRewards: {
    defaultMessage: 'Explore rewards',
    id: 'WAbyp/',
  },
  collections: {
    defaultMessage: 'Pinned collections',
    id: '2s8VuS',
  },
  emptyCollectionsMessage: {
    defaultMessage:
      'Items in pinned collections will appear here for easy access. To get started, <linkOne>create a collection</linkOne> or <linkTwo>pin an existing collection</linkTwo>',
    id: 'tIlmT+',
  },
  doraReportingInsightsLabel: {
    defaultMessage: 'AI reporting & insights',
    id: 'In8ise',
  },
});

export type collectionHeaderActions =
  | 'moveCollectionUp'
  | 'moveCollectionDown'
  | 'addItemsToCollection'
  | 'editCollection'
  | 'shareCollection'
  | 'unpinCollection'
  | 'deleteCollection';

export type collectionItemActions =
  | 'copyLink'
  | 'moveItemUp'
  | 'moveItemDown'
  | 'moveToAnotherCollection'
  | 'removeItemFromCollection';

type InfiniteNavDataQueryType = ReturnType<typeof useNavData>;

export type NavBarProps = {
  userDetails: UserDetails;

  collections: PinnedCollection[];

  importantActivityCount: number;

  permissions: Partial<MemberPermissions>;

  expandedSections: string[];

  hasMoreCollections: InfiniteNavDataQueryType['hasNextPage'];

  fetchMoreCollections: InfiniteNavDataQueryType['fetchNextPage'];

  isFetchingMoreCollections: InfiniteNavDataQueryType['isFetchingNextPage'];

  isLoading: InfiniteNavDataQueryType['isLoading'];

  onActionClick(
    action:
      | 'goToImportantActivitySection'
      | 'goToExploreTemplatesPage'
      | 'goToConnectToolsPage'
      | 'createNewCollection'
      | 'createPost'
      | 'createTask'
      | 'createFlow'
      | 'pinExistingCollection'
      | 'exploreRewards'
      | 'openDoraReportingInsights'
  ): void;

  onCollectionHeaderActionClick(
    action: collectionHeaderActions,
    collectionId: string
  ): void;

  onCollectionItemActionClick(args: {
    action: collectionItemActions;
    collectionId: string;
    item: SearchIndexResult;
    text: string;
    url?: string;
  }): void;

  onSectionToggle(expandedSections: string[]): void;
};

function ImportantActivityAction({
  importantActivityCount,
  onClick,
}: ComponentPropsWithoutRef<'button'> & {
  onClick: MouseEventHandler<HTMLButtonElement>;
  importantActivityCount: number;
}) {
  const { formatMessage } = useIntl();
  return (
    <button
      className={twMerge(
        navActionItemStyles,
        'mx-1 inline-flex flex-col items-center justify-center gap-2 self-stretch rounded px-3'
      )}
      aria-label={formatMessage(messages.goToImportantActivitySection)}
      onClick={onClick}
    >
      <div className="flex h-8 flex-col items-start justify-start gap-2 self-stretch rounded-md py-1">
        <div className="inline-flex items-center justify-start gap-2 self-stretch rounded-lg">
          <div className="relative flex items-center justify-start gap-2 pl-1">
            <BellAlertIcon className="h-4 w-4" />
            <TextStyle
              aria-hidden="true"
              variant={
                importantActivityCount > 0 ? 'base-medium' : 'base-regular'
              }
            >
              {formatMessage(messages.notifications)}
            </TextStyle>
          </div>

          {importantActivityCount !== 0 && (
            <div
              className="ml-auto flex h-4 w-4 items-center text-center"
              aria-hidden="true"
            >
              <TextStyle variant="sm-medium" className="text-primary-6">
                {importantActivityCount > 9
                  ? `9+`
                  : `${importantActivityCount}`}
              </TextStyle>
            </div>
          )}
        </div>
      </div>
    </button>
  );
}

function PredefinedActionsSection({
  isDoraReportingInsightsVisible,
  permissions,
  onActionClick,
}: Pick<NavBarProps, 'permissions' | 'onActionClick'> & {
  isDoraReportingInsightsVisible: boolean;
  isOpen: boolean;
}) {
  const { formatMessage } = useIntl();

  const { data: currentUserDetails } = useUserDetails();

  const {
    hasCharitiesSetup,
    hasCultureSetup,
    hasGiftCardsSetup,
    hasSwagsSetup,
  } = useRewardsHeaderDetails();

  const isObserver =
    currentUserDetails?.member.status === MemberStatus.Observer;

  const isRewardsSetup =
    (hasCharitiesSetup ||
      hasCultureSetup ||
      hasGiftCardsSetup ||
      hasSwagsSetup) &&
    !isObserver;

  return (
    <>
      <section className="flex flex-col items-start justify-start gap-2 self-stretch p-1">
        <div className="relative flex flex-col items-start justify-start gap-2 self-stretch">
          <div className="flex w-full flex-col gap-2">
            {isRewardsSetup ? (
              <QuickAction
                icon={<GiftIcon className="h-4 w-4" />}
                text={formatMessage(messages.exploreRewards)}
                onClick={() => onActionClick('exploreRewards')}
              />
            ) : null}
            {permissions.canCreateFlow ? (
              <QuickAction
                icon={<RoutineIcon className="h-4 w-4" />}
                text={formatMessage(messages.createNewFlow)}
                onClick={() => onActionClick('createFlow')}
              />
            ) : null}

            {isDoraReportingInsightsVisible ? (
              <QuickAction
                icon={<ChartBarSquareIcon className="h-4 w-4" />}
                text={formatMessage(messages.doraReportingInsightsLabel)}
                onClick={() => onActionClick('openDoraReportingInsights')}
              />
            ) : null}

            <QuickAction
              onClick={() => onActionClick('goToExploreTemplatesPage')}
              className="md:hidden"
              icon={<BookOpenIcon className="h-4 w-4" aria-hidden="true" />}
              text={formatMessage(messages.goToExploreTemplatesPage)}
            />
            <QuickAction
              onClick={() => onActionClick('goToConnectToolsPage')}
              className="md:hidden"
              icon={<PuzzlePieceIcon className="h-4 w-4" aria-hidden="true" />}
              text={formatMessage(messages.goToConnectToolsPage)}
            />
          </div>
        </div>
      </section>
      {isRewardsSetup ||
      Boolean(permissions.canCreateFlow) ||
      isDoraReportingInsightsVisible ? (
        <HorizontalRule className="w-full py-1" />
      ) : null}
    </>
  );
}

function GeneralSection({
  isBottomNav,
  onActionClick,
  importantActivityCount,
  assemblyName,
}: Pick<NavBarProps, 'importantActivityCount' | 'onActionClick'> & {
  isBottomNav: boolean;
  assemblyName: string;
}) {
  const { formatMessage } = useIntl();
  const { isTreatmentActive: disableNavPinningTreatmentActive } =
    useFeatureSplit(SplitNames.DisableNavPinning);

  const hideNav = useNavStore((state) => state.hideNav);

  const pinNav = useNavStore((state) => state.pinNav);
  const unpinNav = useNavStore((state) => state.unpinNav);
  const isPinned = useNavStore((state) => state.isPinned);
  const navTriggerPosition = useNavStore((state) => state.navTriggerPosition);

  const SidebarButton = () => (
    <button
      aria-label={formatMessage(messages.pinLabel)}
      className={twMerge(
        'flex items-center justify-start gap-2.5 rounded-lg',
        navActionItemStyles
      )}
      onClick={withoutDefaultEventBehavior(() => pinNav())}
    >
      <Tooltip
        textClassName="inline-flex items-center justify-center gap-1 text-center"
        tooltipText={formatMessage(messages.pinningKeyboardShortcut, {
          cmd: (str: ReactNode) => (
            <kbd className="rounded bg-gray-8 px-1">{str}</kbd>
          ),
          metaKey: () => (
            <kbd className="rounded bg-gray-8 px-1 text-center">
              {browserUtils.isMac ? '⌘ cmd' : 'ctrl'}
            </kbd>
          ),
        })}
        align="start"
        side="bottom"
      >
        <img
          src={sidebarLockIcon}
          alt=""
          className={twMerge(navTriggerPosition === 'left' && 'rotate-180')}
        />
      </Tooltip>
    </button>
  );

  const CollapseButton = () => (
    <button
      className={twMerge(
        'flex items-center justify-start gap-2.5 rounded-lg p-2',
        navActionItemStyles
      )}
      onClick={withoutDefaultEventBehavior(() => {
        hideNav();
        unpinNav();
      })}
    >
      <Tooltip
        tooltipText={formatMessage(messages.closeMenu)}
        side="bottom"
        align="start"
      >
        <ChevronDoubleRightIcon
          className={twMerge(
            'relative h-4 w-4',
            navTriggerPosition === 'left' && 'rotate-180',
            isBottomNav && 'rotate-90'
          )}
          strokeWidth={2}
        />
      </Tooltip>
    </button>
  );

  return (
    <>
      <div
        className={twMerge(
          'inline-flex w-full items-center justify-start gap-1 p-2 pb-1',
          isPinned && navTriggerPosition === 'left' && 'justify-between',
          !isPinned && navTriggerPosition === 'right' && 'justify-between',
          isBottomNav && 'justify-between'
        )}
      >
        {!isBottomNav && navTriggerPosition === 'left' && !isPinned && (
          <SidebarButton />
        )}

        {!disableNavPinningTreatmentActive &&
          !isBottomNav &&
          navTriggerPosition === 'right' &&
          Boolean(isPinned) && <CollapseButton />}

        <div
          className={twMerge(
            'relative flex items-center justify-start gap-2',
            !isBottomNav && 'pl-2'
          )}
        >
          <Tooltip tooltipText={assemblyName}>
            <TextStyle
              aria-hidden="true"
              variant="base-medium"
              className="line-clamp-1"
            >
              {assemblyName}
            </TextStyle>
          </Tooltip>
        </div>
        <div className="flex items-center justify-center">
          <IconButton
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              onActionClick('createPost');
            }}
            variation="secondaryEmphasized"
          >
            <PencilSquareIcon className="h-4 w-4 font-bold text-blue-8" />
          </IconButton>
          {!isBottomNav && navTriggerPosition === 'right' && !isPinned && (
            <SidebarButton />
          )}

          {!disableNavPinningTreatmentActive &&
            !isBottomNav &&
            navTriggerPosition === 'left' &&
            Boolean(isPinned) && <CollapseButton />}

          {Boolean(isBottomNav) && <CollapseButton />}
        </div>
      </div>

      <HorizontalRule className="w-full py-1" />

      <ImportantActivityAction
        onClick={() => onActionClick('goToImportantActivitySection')}
        importantActivityCount={importantActivityCount}
      />
    </>
  );
}

function ChatsHeader() {
  const { formatMessage } = useIntl();

  return (
    <div className="inline-grid w-full grid-cols-12 items-center gap-2 px-4 py-1 pr-1 text-left">
      <TextStyle
        aria-hidden="true"
        variant="base-medium"
        className="col-span-8"
      >
        {formatMessage(messages.chatHeaderLabel)}
      </TextStyle>
    </div>
  );
}

function CollectionHeader({
  onActionClick,
}: {
  onActionClick: NavBarProps['onActionClick'];
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="inline-grid w-full grid-cols-12 items-center gap-2 px-4 py-1 pr-1 text-left">
      <TextStyle
        aria-hidden="true"
        variant="base-medium"
        className="col-span-8"
      >
        {formatMessage(messages.collections)}
      </TextStyle>
      <div className="col-span-4 flex justify-end">
        <Tooltip tooltipText={formatMessage(messages.pinExistingCollection)}>
          <IconButton
            variation="tertiaryLite"
            onClick={() => onActionClick('pinExistingCollection')}
            size="small"
          >
            <PinIcon className="h-4 w-4" />
          </IconButton>
        </Tooltip>
        <Tooltip tooltipText={formatMessage(messages.createNewCollection)}>
          <IconButton
            onClick={() => onActionClick('createNewCollection')}
            variation="tertiaryLite"
            size="small"
          >
            <PlusIcon className="h-4 w-4" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

function EmptyCollections({
  onActionClick,
}: {
  onActionClick: NavBarProps['onActionClick'];
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="m-2 grid gap-3 rounded border border-dashed border-gray-4 bg-gray-2 p-3">
      <img alt="" src={pinnedCollection} className="mx-auto" />
      <TextStyle variant="xs-regular">
        {formatMessage(messages.emptyCollectionsMessage, {
          linkOne: (text: ReactNode) => (
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            <span
              className="cursor-pointer text-primary-6 underline"
              onClick={() => onActionClick('createNewCollection')}
            >
              {text}
            </span>
            /* eslint-enable jsx-a11y/click-events-have-key-events */
            /* eslint-enable jsx-a11y/no-static-element-interactions */
          ),
          linkTwo: (text: ReactNode) => (
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            <span
              className="cursor-pointer text-primary-6 underline"
              onClick={() => onActionClick('pinExistingCollection')}
            >
              {text}
            </span>
            /* eslint-enable jsx-a11y/click-events-have-key-events */
            /* eslint-enable jsx-a11y/no-static-element-interactions */
          ),
        })}
      </TextStyle>
    </div>
  );
}

function ChatMessagesList() {
  const { formatMessage } = useIntl();
  const isChatEnabled = useIsChatEnabled();
  const createChatWindow = useCreateChatWindow();
  const { isPending: isUnReadChatMessagesLoading, data: chatData } =
    useGetUnReadChatMessages();

  if (!isChatEnabled) {
    return null;
  }

  return (
    <div>
      <HorizontalRule className="w-full py-1" />
      <ChatsHeader />

      {Boolean(isUnReadChatMessagesLoading) && <ChatsSkeletonLoader />}

      {chatData?.length === 0 && (
        <div className="py-4">
          <TextStyle variant="sm-regular" className="text-center text-gray-8">
            {formatMessage(messages.noMessagesLabel)}
          </TextStyle>
        </div>
      )}

      {Number(chatData?.length) > 0 && (
        <>
          {chatData?.map((chat) => (
            <button
              key={chat.memberId}
              onClick={() => {
                createChatWindow({
                  image: chat.imageUrl,
                  memberId: chat.memberId,
                  lastName: chat.lastName,
                  firstName: chat.firstName,
                });
              }}
              className="flex w-full items-center justify-start gap-2 p-2 hover:bg-gray-2"
            >
              <div className="flex w-full items-center justify-between gap-2 px-2">
                <div className="flex w-full items-center justify-start gap-2">
                  <Avatar
                    name={chat.firstName}
                    size={AvatarSize.Small}
                    image={chat.imageUrl}
                    memberID={chat.memberId}
                  />
                  <div className="flex flex-col items-start justify-start gap-1">
                    <TextStyle variant="sm-medium">
                      {chat.firstName} {chat.lastName}
                    </TextStyle>
                  </div>
                </div>
                <div className="h-5 w-6 rounded-full bg-primary-6">
                  <TextStyle variant="sm-medium" className="text-gray-1">
                    {chat.count}
                  </TextStyle>
                </div>
              </div>
            </button>
          ))}
        </>
      )}
    </div>
  );
}

export function NavBar({
  userDetails,
  expandedSections,
  collections,
  importantActivityCount,
  permissions,
  fetchMoreCollections,
  hasMoreCollections,
  isFetchingMoreCollections,
  isLoading,
  onActionClick,
  onCollectionHeaderActionClick,
  onCollectionItemActionClick,
  onSectionToggle,
}: NavBarProps) {
  const { openModal } = useContext(FilePreviewModalContext);

  const endOfListRef = useRef<HTMLDivElement>(null);
  const { inViewport: hasReachedEndOfNav } = useInViewport(endOfListRef);

  const { isBottomNav } = useNavTrigger();

  const { isTreatmentActive: isShowFlowsInV3NavTreatmentActive } =
    useFeatureSplit(SplitNames.ShowFlowsInV3Nav);

  const { data: planFeatureDetails } = useGetPlanFeaturesQuery();

  const isDoraIncludedInPlan =
    isDoraIncludedInWorkspacePlan(planFeatureDetails);

  const { isTreatmentActive: isDoraReportingInsightsManagersEnabled } =
    useFeatureSplit(SplitNames.DoraReportingInsightsManagers);

  const isAdmin = isAdminMember(userDetails.member);
  const { isManager } = userDetails.member.profile;
  const { name: assemblyName } = userDetails.assembly;

  useEffect(() => {
    if (
      hasReachedEndOfNav &&
      !isFetchingMoreCollections &&
      hasMoreCollections
    ) {
      fetchMoreCollections();
    }
  }, [
    fetchMoreCollections,
    hasMoreCollections,
    hasReachedEndOfNav,
    isFetchingMoreCollections,
  ]);

  const openFilePreviewModal = (props: {
    blockId: string;
    flowId: string;
    fileName: string;
    responseId: string;
  }) =>
    openModal({
      workspaceSlugPath: userDetails.assembly.workspaceSlugPath,
      ...props,
    });

  // TODO: Remove `Root` as this seems to be redundant.
  return (
    <Root
      asChild
      type="multiple"
      value={expandedSections}
      onValueChange={onSectionToggle}
    >
      <nav
        className={twMerge(
          'border border-gray-5 bg-gray-1 text-gray-9',
          'inline-flex flex-col items-center justify-start rounded-md',
          'max-h-[80vh] overflow-hidden md:max-h-[85vh]',
          isBottomNav ? 'w-full' : 'w-[248px]'
        )}
      >
        <GeneralSection
          isBottomNav={isBottomNav}
          importantActivityCount={importantActivityCount}
          onActionClick={onActionClick}
          assemblyName={assemblyName}
        />

        <HorizontalRule className="w-full py-1" />

        <div className="w-full overflow-y-scroll">
          <PredefinedActionsSection
            isDoraReportingInsightsVisible={
              Boolean(isDoraIncludedInPlan) &&
              (isAdmin || (isDoraReportingInsightsManagersEnabled && isManager))
            }
            isOpen={expandedSections.includes('actions')}
            permissions={permissions}
            onActionClick={onActionClick}
          />

          <section>
            <CollectionHeader onActionClick={onActionClick} />
            {isLoading ? (
              <CollectionSkeletonLoader />
            ) : collections.length === 0 ? (
              <EmptyCollections onActionClick={onActionClick} />
            ) : (
              <div className="w-full space-y-[-1px]">
                {collections.map((collection, index) => (
                  <CollectionSection
                    key={collection.id}
                    isFirstItem={index === 0}
                    isLastItem={index === collections.length - 1}
                    isOpen={expandedSections.includes(collection.id)}
                    collection={collection}
                    userDetails={userDetails}
                    openFilePreviewModal={openFilePreviewModal}
                    onHeaderActionClick={onCollectionHeaderActionClick}
                    onItemActionClick={onCollectionItemActionClick}
                  />
                ))}
                {Boolean(hasMoreCollections) &&
                  Boolean(isFetchingMoreCollections) && (
                    <CollectionSkeletonLoader />
                  )}
              </div>
            )}

            <div
              ref={endOfListRef}
              className={twMerge(
                'w-full',
                hasMoreCollections ? 'h-10' : 'hidden'
              )}
            />
          </section>
          {isShowFlowsInV3NavTreatmentActive ? (
            <>
              <HorizontalRule className="w-full py-1" />
              <FlowSection
                isUserStatusObserver={userDetails.member.status === 'observer'}
                workspaceSlugPath={userDetails.assembly.workspaceSlugPath}
                onCreateFlowButtonClick={() => onActionClick('createFlow')}
                canCreateFlow={userDetails.member.permissions.canCreateFlow}
              />
            </>
          ) : null}
          <ChatMessagesList />
        </div>
      </nav>
    </Root>
  );
}
