import * as services from '@assembly-web/services';
import { config, type Icon, useUserDetails } from '@assembly-web/services';
import { useAssemblyNavigate, useToastStore } from '@assembly-web/ui';
import type { ElementRef } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useOpenFlowsPreviewer } from '../../../hooks/useOpenFlowsPreviewer';
import { useMultiDrawerStore } from '../../../stores/useMultiDrawerStore';
import { useUserFeedUpdatedEvents } from '../hooks/domainEvents/useUserFeedUpdatedEvents';
import { useCreateWorkflow } from '../hooks/flowsEditor/useCreateWorkflow';
import { useIsFullScreenLegacyPage } from '../hooks/useIsFullScreenLegacyPage';
import { useMobileMessageBridge } from '../hooks/useMobileMessageBridge';
import { useRewardsHeaderDetails } from '../hooks/useRewardsHeaderDetails';
import { useUpdateUserFeedQuery } from '../hooks/useUpdateUserFeedQuery';
import { trackDoraAction } from '../services/analytics';
import { enterFullScreen, exitFullScreen } from '../services/FullScreenMode';
import { mapV2ToV3RewardsRoute } from '../services/rewardsUtil';
import { useLegacyPathStore } from '../stores/legacyPathStore';

const DISCOVER_HEADER_HEIGHT_WITH_PADDING = 114;
const POP_OUT_DRAWER_BUFFER = 48;

const messages = defineMessages({
  flowSummaryDrawerHeader: {
    defaultMessage: 'Summary of {flowTitle}',
    id: 'LzWsOP',
  },
  flowResponsesDrawerHeader: {
    defaultMessage: 'Flow responses on the {flowTitle} flow',
    id: 'MH0QzG',
  },
});

export type LegacyMessageType =
  | 'NAVIGATE_BACK'
  | 'ROUTE_CHANGE'
  | 'GO_TO_HOME_FEED'
  | 'NAVIGATE_TO_ADMIN'
  | 'GO_TO_TEMPLATES'
  | 'CLOSE_PARTICIPATION_MODAL'
  | 'SHOW_SUCCESS_TOAST'
  | 'SHOW_ERROR_TOAST'
  | 'SHOW_INFO_TOAST'
  | 'CLEAR_QUERY_PARAMS'
  | 'ENTER_FULL_SCREEN'
  | 'EXIT_FULL_SCREEN'
  | 'FORWARD_MOBILE'
  | 'INVALIDATE_USER_DETAILS';

type WindowMessageEvent = MessageEvent<
  | {
      type: LegacyMessageType;
      payload: string;
    }
  | {
      type: 'OPEN_POPOUT_DRAWER';
      payload: { icon: { value: string }; flowId: string; name: string };
    }
  | {
      type: 'OPEN_FLOW_RESPONSES_DRAWER';
      payload: { icon: { value: string }; flowId: string; name: string };
    }
  | {
      type: 'RECOGNITION_POST_SUCCESS';
      payload: { data: services.RecognitionPost };
    }
  | {
      type: 'FLOW_POST_SUCCESS';
      payload: { data: services.FlowPostResponse & { instanceId: string } };
    }
  | { type: 'SELECTED_TEMPLATE'; payload: services.FlowTemplatePayload }
  | { type: 'EDIT_FLOW'; payload: { flowId: string } }
  | { type: 'DUPLICATE_FLOW'; payload: { flowId: string } }
  | {
      type: 'OPEN_PREVIEWER';
      payload: {
        blockId: string;
        fileName: string;
        flowId: string;
        responseId: string;
        workspaceSlug?: string;
        fileType: string;
        memberID: string;
        memberName: string;
        memberImage?: string;
        dateShared: string | number | Date;
        sharedIn: string;
        sharedInIcon: Icon;
      };
    }
>;

export function LegacyRoute() {
  const navigate = useAssemblyNavigate();
  const iframeRef = useRef<ElementRef<'iframe'>>(null);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { isTreatmentActive: isV3RewardsEnabled } = services.useFeatureSplit(
    services.SplitNames.RewardsV3
  );

  const { upsertRecognitionPost, upsertFlowPost } = useUpdateUserFeedQuery();
  const { data: userDetails, refetch } = useUserDetails();
  const slugCodeFromAPI = userDetails?.assembly.workspaceSlug.shortCode ?? '';
  const pathnameArray = pathname.split('/');
  const slugUrlFromPathname = pathnameArray.includes('a')
    ? pathname.split('/')[2]
    : pathname.split('/')[1];
  const slugCodeFromURL =
    slugUrlFromPathname.split('-')[slugUrlFromPathname.split('-').length - 1];
  const isSlugCodeFromURLValid = slugCodeFromAPI === slugCodeFromURL;

  const { iFrameSrc, setIFrameSrc } = useLegacyPathStore();
  const [src, setSrc] = useState('');

  const createFlowEditorDrawer = useCreateWorkflow();
  const openFlowPreviewer = useOpenFlowsPreviewer();

  const { hasGiftCardsSetup, hasCharitiesSetup, isRedeemInfoDataLoading } =
    useRewardsHeaderDetails();

  const constructURL = useCallback(() => {
    const url = new URL(config.domains.legacyApp);
    url.pathname = pathname;
    const updatedURL = removePrefix(url);

    searchParams.forEach((value, key) =>
      updatedURL.searchParams.set(key, value)
    );
    updatedURL.searchParams.set('source', 'embedded');

    const { jwtToken, mobilePlatform, refreshToken } =
      services.userAuthStore.getState();

    if (config.isMobileDevMode) {
      if (jwtToken) {
        updatedURL.searchParams.append('mobileAuthToken', jwtToken);
      }
      if (refreshToken) {
        updatedURL.searchParams.append('mobileRefreshToken', refreshToken);
      }
    }

    if (mobilePlatform) {
      updatedURL.searchParams.append('mobilePlatform', mobilePlatform);
      updatedURL.searchParams.append('isMobileApp', 'true');
      updatedURL.searchParams.append('isMobileAppV3', 'true');
    }

    setSrc(updatedURL.toString());
  }, [pathname, searchParams]);

  useUserFeedUpdatedEvents();

  useEffect(() => {
    if (!isSlugCodeFromURLValid) {
      return navigate('/404?isLoggedIn=true');
    }
  }, [isSlugCodeFromURLValid, navigate]);

  useEffect(() => {
    const recaptchaBadge = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaBadge.length) {
      recaptchaBadge[0].remove();
    }
  }, []);

  useEffect(() => {
    if (
      !isRedeemInfoDataLoading &&
      isV3RewardsEnabled &&
      pathname.includes('/rewards')
    ) {
      const rewardsTab = params['*'];
      let redirectPath;
      const validRewardsTabs = [
        ...Object.values(services.RewardType),
        'my-rewards',
        'gift-cards',
      ];

      // Logic to redirect to the correct rewards tab based on the user's setup (refer getRewardsRedirectionPath function in useRewardsHeaderDetails.tsx)
      if (!validRewardsTabs.includes(rewardsTab as services.RewardType)) {
        redirectPath = `/a/${slugUrlFromPathname}/rewards/${
          hasGiftCardsSetup
            ? services.RewardType.GiftCards
            : hasCharitiesSetup
              ? services.RewardType.Charities
              : services.RewardType.Culture
        }`;
      } else {
        redirectPath = pathname;
      }
      const path = mapV2ToV3RewardsRoute(
        '/a/discover',
        {
          filter: 0,
          tab: 1,
        },
        redirectPath,
        slugUrlFromPathname
      );
      navigate(path);
    }
    // This effect runs only once because this component unmounts (because of redirection) as soon as the redeemaable info is fetched, so we don't need to add other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isV3RewardsEnabled, isRedeemInfoDataLoading]);

  function removePrefix(url: URL) {
    const { pathname } = url;
    const updatedPathname = pathname.replace('/a/', '/');
    return new URL(updatedPathname, url);
  }

  useEffect(() => {
    if (iFrameSrc) {
      const url = new URL(iFrameSrc);
      const updatedURL = removePrefix(url);

      updatedURL.searchParams.set('source', 'embedded');
      // keeping this to ensure the iframe reloads when the user navigates to a different page
      updatedURL.searchParams.set('hash', new Date().toISOString());
      setSrc(updatedURL.toString());
      setIFrameSrc('');
    }
  }, [constructURL, iFrameSrc, setIFrameSrc]);

  /* This is to trigger constructURL only when the component mounts */
  useEffect(() => {
    constructURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showSuccessToast, showErrorToast, showInfoToast } = useToastStore();
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const { formatMessage } = useIntl();

  useEffect(() => {
    function handleMessage(event: WindowMessageEvent) {
      const { data } = event;
      switch (data.type) {
        case 'FLOW_POST_SUCCESS':
          {
            const { data: flowResponse } = data.payload;
            upsertFlowPost(flowResponse);
          }
          break;
        case 'RECOGNITION_POST_SUCCESS':
          {
            const { data: recognitionPost } = data.payload;
            upsertRecognitionPost(recognitionPost);
          }
          break;
        case 'INVALIDATE_USER_DETAILS':
          refetch();
          break;
        case 'FORWARD_MOBILE':
          services.postMessageToMobileApp(data.payload);
          break;
        case 'NAVIGATE_BACK':
        case 'GO_TO_HOME_FEED':
        case 'CLOSE_PARTICIPATION_MODAL': {
          if (services.userAuthStore.getState().closeDrawerOnNavigateBack) {
            services.postMessageToMobileApp({
              action: services.AppAction.CloseModalStatus,
            });
            return;
          }
          navigate(sessionStorage.getItem('discoverPageURL') ?? `/a/discover`, {
            replace: true,
          });
          break;
        }
        case 'GO_TO_TEMPLATES':
          navigate(data.payload, { replace: true });
          break;
        case 'ROUTE_CHANGE':
          {
            const shouldNotNavigateOnRouteChangePathnames = ['/invite'];

            const shouldNavigateOnRouteChange =
              !shouldNotNavigateOnRouteChangePathnames.some((path) =>
                data.payload.includes(path)
              );

            if (shouldNavigateOnRouteChange) {
              if (data.payload !== pathname) {
                navigate(
                  {
                    pathname: `${data.payload}`,
                    search: decodeURIComponent(searchParams.toString()),
                  },
                  { replace: true }
                );
              }
            }
          }
          break;
        case 'NAVIGATE_TO_ADMIN':
          {
            window.location.href = services.buildSafeUrl(
              `${config.domains.adminApp}${data.payload}`
            );
          }
          break;
        case 'SHOW_ERROR_TOAST':
          {
            showErrorToast(data.payload);
          }
          break;
        case 'SHOW_SUCCESS_TOAST':
          {
            showSuccessToast(data.payload);
          }
          break;
        case 'SHOW_INFO_TOAST':
          {
            showInfoToast(data.payload);
          }
          break;
        case 'CLEAR_QUERY_PARAMS':
          {
            searchParams.delete(data.payload);
            navigate(`${pathname}?${searchParams.toString()}`, {
              replace: true,
            });
          }
          break;
        case 'ENTER_FULL_SCREEN':
          enterFullScreen();
          break;
        case 'EXIT_FULL_SCREEN':
          exitFullScreen();
          break;
        case 'OPEN_POPOUT_DRAWER': {
          const flowTitle = `${services.mapHexCodeToEmoticon(data.payload.icon.value)} ${
            data.payload.name
          }`;

          createDrawer({
            data: {
              flowId: data.payload.flowId,
              flowTitle,
            },
            title: formatMessage(messages.flowSummaryDrawerHeader, {
              flowTitle,
            }),
            type: 'flowSummary',
          });

          trackDoraAction('flowSummaryOpened', {
            doraSummaryInput: flowTitle,
          });

          break;
        }
        case 'OPEN_FLOW_RESPONSES_DRAWER': {
          const flowTitle = `${services.mapHexCodeToEmoticon(data.payload.icon.value)} ${
            data.payload.name
          }`;

          createDrawer({
            data: {
              flowId: data.payload.flowId,
              flowTitle,
            },
            title: formatMessage(messages.flowResponsesDrawerHeader, {
              flowTitle,
            }),
            type: 'flow_responses',
          });

          break;
        }
        case 'SELECTED_TEMPLATE': {
          const { templateId } = data.payload;
          createFlowEditorDrawer({ id: templateId, type: 'template' });
          navigate(sessionStorage.getItem('discoverPageURL') ?? `/a/discover`, {
            replace: true,
          });
          break;
        }
        case 'DUPLICATE_FLOW':
        case 'EDIT_FLOW': {
          const { flowId } = data.payload;
          createFlowEditorDrawer({
            id: flowId,
            type: data.type === 'DUPLICATE_FLOW' ? 'duplicate' : 'edit',
          });
          break;
        }
        case 'OPEN_PREVIEWER':
          openFlowPreviewer(data.payload);
          break;
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // NOTE: Both of these dependencies don't change unless the user navigates to a different page,
    // so we don't need to worry about rerunning this effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createDrawer,
    formatMessage,
    navigate,
    params,
    pathname,
    createFlowEditorDrawer,
  ]);

  useMobileMessageBridge((data) => {
    if (data.type === 'triggerSaveDraft' || data.type === 'back') {
      iframeRef.current?.contentWindow?.postMessage(data, '*');
    }
  });

  useEffect(() => {
    function handleInvalidateFlowDetails() {
      iframeRef.current?.contentWindow?.postMessage(
        { type: 'invalidate-flow-details' },
        '*'
      );
    }

    document.addEventListener(
      'invalidate-flow-details',
      handleInvalidateFlowDetails
    );

    return () => {
      document.removeEventListener(
        'invalidate-flow-details',
        handleInvalidateFlowDetails
      );
    };
  }, []);

  useEffect(() => {
    function handler() {
      constructURL();
    }

    document.addEventListener('reload-legacy-embed', handler);

    return () => {
      document.removeEventListener('reload-legacy-embed', handler);
    };
  }, [constructURL]);

  const isFullScreenPage = useIsFullScreenLegacyPage();

  const drawers = useMultiDrawerStore((store) => store.getDrawers());
  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );
  const hasDrawersInDock = drawers.length > 0 || overflowDrawers.length > 0;

  const { isTreatmentActive: multiPopoutDrawerEnabled } =
    services.useFeatureSplit(services.SplitNames.MultiPopoutDrawer);

  const shouldShowDrawerDockBuffer =
    multiPopoutDrawerEnabled && hasDrawersInDock && !isFullScreenPage;

  const getLegacyFrameHeight = () => {
    let nonIframeHeight = 0;

    if (!isFullScreenPage) {
      if (!services.userAuthStore.getState().isMobileAppV3) {
        nonIframeHeight += DISCOVER_HEADER_HEIGHT_WITH_PADDING;
      }

      if (shouldShowDrawerDockBuffer) {
        nonIframeHeight += POP_OUT_DRAWER_BUFFER;
      }
    }

    return `calc(100vh - ${nonIframeHeight}px)`;
  };

  // TODO: Update iframe title based on the workflow we're navigating to
  return (
    <>
      <iframe
        ref={iframeRef}
        key={src}
        src={src}
        title="Discover"
        style={{
          height: getLegacyFrameHeight(),
        }}
        className={twMerge(
          'h-full w-screen border-0 border-t-[1px] border-t-gray-4'
        )}
      />
      {Boolean(shouldShowDrawerDockBuffer) && (
        <div className="sticky bottom-0 z-[8] h-12 border-t-[1px] border-t-gray-4 bg-gray-1" />
      )}
    </>
  );
}
