import type {
  FlowsToAnswerApiResponse,
  FlowsToDoCountApiResponse,
  FlowsToViewApiResponse,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export type ActionBarQueryResponse = {
  flowsToDoCount: FlowsToDoCountApiResponse;
  flowsToAnswerNow: FlowsToAnswerApiResponse;
  flowsToAnswerAnytime: FlowsToAnswerApiResponse;
  flowsToView: FlowsToViewApiResponse['data'];
};

export function getActionBarQuery(
  options?: Partial<UseQueryOptions<ActionBarQueryResponse>>
): UseQueryOptions<ActionBarQueryResponse> {
  return {
    ...options,
    queryKey: ['actionBar'],
    queryFn: async () => {
      const flowsToDoCountResponse = assemblyAPI.get<FlowsToDoCountApiResponse>(
        APIEndpoints.getFlowsToDoCount,
        {
          params: {
            days: 31,
          },
        }
      );

      const flowsToAnswerAnytimeResponse =
        assemblyAPI.post<FlowsToAnswerApiResponse>(
          APIEndpoints.getFlowsToAnswerAnytime,
          {},
          {
            params: {
              limit: 99,
            },
          }
        );

      const flowsToAnswerNowResponse =
        assemblyAPI.post<FlowsToAnswerApiResponse>(
          APIEndpoints.getFlowsToAnswerNow,
          {},
          {
            params: {
              limit: 99,
            },
          }
        );

      const flowsToViewResponse = assemblyAPI.post<FlowsToViewApiResponse>(
        APIEndpoints.getActiveFlows,
        {},
        {
          params: {
            limit: 99,
          },
        }
      );

      const [
        flowsToDoCountData,
        flowsToAnswerNowData,
        flowsToAnswerAnytimeData,
        flowsToView,
      ] = await Promise.all([
        flowsToDoCountResponse,
        flowsToAnswerNowResponse,
        flowsToAnswerAnytimeResponse,
        flowsToViewResponse,
      ]);

      return {
        flowsToDoCount: flowsToDoCountData.data,
        flowsToAnswerNow: flowsToAnswerNowData.data,
        flowsToAnswerAnytime: flowsToAnswerAnytimeData.data,
        flowsToView: flowsToView.data.data,
      };
    },
  };
}
